/*
 * @Author: Ghost 
 * @Email: Ghost@yopmail.com
 * @Date: 2024-03-21 11:12:12 
 * @Last Modified by: Someone
 * @Last Modified time: 2024-03-21 11:20:16
 * @Description: file:///home/jc-21/Desktop/MyVirtualPal/marketplace-frontend/src/pages/BankPayment/BankList.js
 */

import { useEffect, useState } from 'react';
import Header from '../../components/global/header'
import ApiClient from '../../methods/api/apiClient'
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import { useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import loader from '../../methods/loader'

export default function BankList() {
  const user=useSelector(state=>state.user)
    const Navigate=useHistory();
    const [BankList,setBankList]=useState([]);
    const GetBankList=()=>{
        ApiClient.get(`banks`).then(res=>{
            if(res.success){
                setBankList(res.data)
            }
        })
    }
useEffect(()=>{
GetBankList()
},[])

const HandleVerifyBank=(e,id)=>{
  e.preventDefault();loader(true)
  ApiClient.put(`verify/bank-account`,{  "id":id,"customer_id":user.customer_id}).then(res=>{
    if(res.success){
      toast.success(res.message);
      GetBankList()
    }
  })
}
  return (
    <div>
        <Header/>
      <div className='container mt-5 '>
        <div className='d-flex justify-content-between mb-4 flex-md-col'>
        <h3 className='moddecsheader'>Your Bank Accounts</h3>
        <button className='btn btn-primary' onClick={e=>Navigate.push(`/bank/add`)}>Add Account</button>
        </div>
   <div className='banks_list'>   <ul className="list-group ">
    {BankList.map((item,index)=>(<li key={index} className="list-group-item">
      <div className="d-flex justify-content-between align-items-center">
<span><span className='font-weight-bold mr-2'>Account Holder: </span> <span>{item?.account_holder_name}</span></span>
        {/* <span className="btn btn-secondary ml-2">{item.type||""}</span> */}
        {!item.accountVerify?<button className="btn btn-primary ml-2" onClick={e=>HandleVerifyBank(e,item.id)}>Verify</button>:<button className="btn btn-secondary ml-2 disabled">Verified</button>}
      </div>
      <div className="d-flex justify-content-between align-items-center mt-2 ">
       <span> <span className='font-weight-bold mr-2'>Account Number: </span> <span className='bank_numb'> **** **** **** {item?.last4}</span></span>
        <p  className="text-info font-weight-bold">{item.bank_name}</p>
      </div>
    </li>))}


  </ul></div>
  {BankList.length==0?<div className='text-center text-danger'>
    <img src='/assets/img/not-found.jpg' className='not_found_img img-fluid'/>
{/* <span>No Data</span> */}
  </div>:null}
      </div>
    </div>
  )
}
