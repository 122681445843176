import { useState } from "react";
import "./style.scss";
import ApiClient from "../../methods/api/apiClient";
import { useSelector } from "react-redux";
import environment from "../../environment";
import "react-phone-input-2/lib/style.css";
import { Editor } from "@tinymce/tinymce-react";

const Html = ({
  handleSubmit,
  setForm,
  form,
  getError,
  uploadImage,
  submitted,
  setProtofolioError,
  images,
  setimages,
  ProtofolioError,
}) => {
  const user = useSelector((state) => state.user);
  const [Resume, setResume] = useState(
    user.resumeDocument ? user.resumeDocument : ""
  );

  const [DoumentUploadLoading, setDocumentUploadLoading] = useState(false);
  const [documents, setdocuments] = useState([]);

  const imageResult = (e) => {
    if (e.target.files.length > 0) {
      setDocumentUploadLoading(true);
      const files = e.target.files;
      const newdata = new FormData();
      let newarray = images;
      let imgarrays = [];
      let i = 0;
      let original = [];
      for (let items of files) {
        imgarrays.push(items);
      }
      for (let item of imgarrays) {
        let file = files.item(i);
        ApiClient.multiImageUpload("single/documents?modelName=document", {
          file: file,
        }).then((res) => {
          if (res.success) {
            const path = res?.data?.imagePath;
            setResume(path);
            setForm({ ...form, resumeDocument: path });
            setDocumentUploadLoading(false);
          } else {
            setDocumentUploadLoading(false);
            // toast.error({ message: "Some error Occurred ! Try Again!" });
          }
        });

        i++;
      }
      setProtofolioError(false);
      setdocuments(newarray);
      setimages(newarray);
    } else {
      // toast.error({ message: "Please Upload the Documents" });
    }
  };
  //  For Deleting the Document
  const HanldDocumentDelete = (e) => {
    setResume("");
    setForm({ ...form, resumeDocument: "" });
  };


  const handleDrop = (e) => {
    e.preventDefault();
    const files = e.dataTransfer.files;
    imageResult({ target: { files } });
  };

  // Prevent default behavior for drag-over events
  const handleDragOver = (e) => {
    e.preventDefault();
  };
  return (
    <>
      <>
        <div className="pprofile1  p-0">

          <form className="form-row" onSubmit={handleSubmit}>
            <div className="main_profile_page resume_pagesw " >
              <div className="form-row mx-auto
            ">
                {/* new */}

                {user.role &&
                  user.role.id == environment.VirtualId ? (
                  <>
                    {" "}

                    <div className="col-md-12 ">
                      <div className="form-group">
                        <label htmlFor="exampleFormControlTextarea1">
                          Resume Description

                        </label>
                        <Editor
                          apiKey={environment.tinyKey}
                          value={form.resume}
                          onEditorChange={(newValue, editor) => {
                            setForm({
                              ...form,
                              resume: newValue,
                            });
                          }}
                          init={{
                            height: "200px",
                            selector: "textarea",
                            plugins: "table code colorpicker textcolor",
                            toolbar:
                              "table code bold italic alignleft aligncenter alignright alignjustify | outdent indent ",
                            toolbar_mode: "floating",
                          }}
                        />
                      </div>
                    </div>
                    <div className="col-md-12">
                      <label className="" >Resume Document</label>
                      <div
                        className={`profile_btn_portfolio d-${Resume == "" ? "" : "none"
                          } `}

                        onDrop={handleDrop}
                        onDragOver={handleDragOver}
                      >
                        <label className="add_portfolio edit ml-3">
                          <input
                            id="bannerImage"
                            type="file"
                            className="d-none"
                            // accept="image/*"
                            onChange={(e) => imageResult(e)}
                          />
                          <span className="add_portfolio">
                            <i className="material-icons add_port">add</i>
                          </span>
                        </label>
                      </div>
                    </div>
                    <div>

                      <div className="imagesRow gap-3 d-flex flex-wrap">
                        {DoumentUploadLoading == true ? (
                          <div className="text-success">
                            Uploading...{" "}
                            <i className="fa fa-spinner fa-spin"></i>
                          </div>
                        ) : Resume != "" ? (
                          <div>
                            <p className="text-capitalize position-relative ">
                              <img
                                style={{ cursor: "pointer" }}
                                width={40}
                                className="document_image"
                                src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcR5kekkj6iYr_ZSKfB7GRWEJ4q-7_2sruMm5Hbc2haxduVzPhmc1kS-7OKKJDI1FjTEVAg&usqp=CAU"
                                onClick={(e) =>
                                  window.open(
                                    `${environment.api}images/document/${Resume}`
                                  )
                                }
                              />
                              <i
                                className="fa fa-times-circle close_resume "
                                style={{ cursor: "pointer" }}
                                onClick={(e) => HanldDocumentDelete(e)}
                              ></i>
                            </p>
                          </div>
                        ) : null}
                      </div>
                    </div>
                    {ProtofolioError ? (
                      <div className="text-danger text-center mt-3 ml-5">
                        Please enter Resume or Upload Document{" "}
                      </div>
                    ) : null}
                  </>
                ) : null}

                <div className="col-md-12 text-right mt-3">
                  <button
                    className="btn btn-secondary discard mr-2"
                    data-dismiss="modal"
                  >
                    Back
                  </button>
                  <button type="submit" className="btn btn-primary edit ">
                    Update
                  </button>
                </div>
              </div>
            </div>
          </form>
        </div>
      </>
    </>
  );
};

export default Html;
