import { useState, useEffect } from "react";
import { useStripe, useElements, CardElement } from "@stripe/react-stripe-js";
import { ToastsStore } from "react-toasts";
import ApiClient from "../../methods/api/apiClient";
import loader from "../../methods/loader";
import { useDispatch, useSelector } from "react-redux";
import { featureType } from "../../models/type.model";
import { useHistory, useParams } from "react-router-dom";
import Header from "../../components/global/header";
import { login_success } from "../../actions/user";
import { toast } from "react-toastify";

const AddEditCards = () => {
  const dispatch = useDispatch();
  const [features, setFeatures] = useState([
    { name: "Option 1️⃣", id: 1 },
    { name: "Option 2️⃣", id: 2 },
  ]);

  const defaultvalue = () => {
    let keys = { ...featureType };
    Object.keys(featureType).map((itm) => {
      if (itm !== "permissions") keys[itm] = "";
    });
    keys.status = "active";
    return keys;
  };
  const { id } = useParams();
  const [form, setform] = useState(featureType);
  const history = useHistory();
  const [submitted, setSubmitted] = useState(false);
  const user = useSelector((state) => state.user);
  const formValidation = [
    { key: "status", required: true },
    { key: "country", required: true },
  ];

  const stripe = useStripe();
  const elements = useElements();

  const handleSubmit = async (e) => {
    e.preventDefault();
    setSubmitted(true);

    let method = "post";
    let url = "card";
    let value = {
      ...form,
    };
    if (value.id) {
      method = "put";
      url = "";
    } else {
      delete value.id;
    }

    if (stripe && elements) {
      // Use elements.getElement to get a reference to the mounted CardElement.
      const cardElement = elements.getElement(CardElement);

      // Use the stripe.createToken() method to create a token containing card details.
      const { token, error } = await stripe.createToken(cardElement);
      if (!token) { toast.error('Please Enter valid details'); return }
      loader(true);
      ApiClient.allApi(url, { token: token }, method).then((res) => {
        if (res.success) {
          ToastsStore.success(res.message);
          ApiClient.get(`profile`, { id: user.id }).then((res) => {
            if (res.success) {
              dispatch(login_success(res.data));
            }
          });
          history.push("/savedcard");
        }
        loader(false);
      });

      if (error) {
        console.error(error);
        loader(false);
        ToastsStore.error("Error processing card information");
        return;
      }

      value.stripe_token = token.id; // Add the Stripe token to your payload
    }


  };

  useEffect(() => {
    if (id) {
      loader(true);
      ApiClient.get("cards", { id }).then((res) => {
        if (res.success) {
          let value = res.data;
          let payload = featureType;

          Object.keys(payload).map((itm) => {
            payload[itm] = value[itm];
          });
          if (value.permissions) {
            payload.permissions = { ...value.permissions[0] };
          }
          if (value.category) {
            payload.category = value.category._id;
          }
          setform({
            ...payload,
          });
        }
        loader(false);
      });
    } else {
      setform(defaultvalue());
    }
  }, [id]);

  return (
    <>
      <Header />

      <div className="container mt-5">
        <div className="bank_account_card width_adjust">
          <form onSubmit={handleSubmit}>
            <div className="">
              <h2 className="mb-4 text-center moddecsheader">
                {form && form.id ? "Edit" : "Add"} Credit/Debit Card
              </h2>
              <div className="form-row">
                <div className="col-md-12 mb-3">
                  <label>
                    Card Number<span className="star">*</span>
                  </label>
                  <CardElement
                    options={{
                      style: {
                        base: {
                          fontSize: '16px',
                          color: '#424770',
                          '::placeholder': {
                            color: '#aab7c4',
                          },
                        },
                        invalid: {
                          color: '#9e2146',
                        },
                      },
                    }}
                  />
                </div>
                {/* <div className="col-md-6 mb-3">
                  <label>
                    Expire Month<span className="star">*</span>
                  </label>
                  <input
                    type="text"
                    minLength={2}
                    maxLength={2}
                    className="form-control"
                    value={form.exp_month}
                    onChange={(e) =>
                      setform({
                        ...form,
                        exp_month: methodModel.isNumber(e),
                      })
                    }
                    required
                  />
                </div>
                <div className="col-md-6 mb-3">
                  <label>
                    Expire Year<span className="star">*</span>
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    minLength={4}
                    maxLength={4}
                    value={form.exp_year}
                    onChange={(e) =>
                      setform({
                        ...form,
                        exp_year: methodModel.isNumber(e),
                      })
                    }
                    required
                  />
                </div>
                <div className="col-md-6 mb-3">
                  <label>
                    CVC<span className="star">*</span>
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    minLength={3}
                    maxLength={3}
                    value={form.cvc}
                    onChange={(e) =>
                      setform({
                        ...form,
                        cvc: methodModel.isNumber(e),
                      })
                    }
                    required
                  />
                </div> */}
              </div>
              <div className="card p-3">
                <p className="cardp">The Platform is safe we never save and share your Personal Details anywhere. All CC transaction have 3% platform fee.</p>
              </div>
              <div className="text-right mt-4 mb-2">
                <button
                  type="button"
                  className="btn btn-secondary  mr-2"
                  onClick={(e) => {
                    history.goBack();
                  }}
                >
                  Back
                </button>
                <button type="submit" className="btn btn-primary">
                  Save
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </>
  );
};

export default AddEditCards;
