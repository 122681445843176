// TimeOffForm.js
/*
 * @Author: Ghost
 * @Email: Ghost@yopmail.com
 * @Date: 2024-02-13 11:30:23
 * @Last Modified by: Someone
 * Email: anilgodara4239@gmail.com
 * @Last Modified time: 2024-02-13 11:32:23
 * */
import { useEffect, useState } from "react";
import Header from "../../components/global/header";
import ApiClient from "../../methods/api/apiClient";
import { useDispatch, useSelector } from "react-redux";
import { login_success } from "../../actions/user";
import { toast } from "react-toastify";

const TimeOffForm = () => {
  const user = useSelector((state) => state.user);
  const dispatch = useDispatch();
  const [timeOffPolicies, setTimeOffPolicies] = useState([
    { name: "Time Off Policy", assignedTo: "Virtual Assistant ", isPaid: null },
    { name: "Sick Time Policy", assignedTo: "Virtual Assistant", isPaid: null },
    { name: "Holiday Policy", assignedTo: "Virtual Assistant", isPaid: null },
  ]);
  //  This is for Policy Change When user Change the Policy Type then its would
  const handlePolicyChange = (index, value) => {
    setTimeOffPolicies((prevPolicies) =>
      prevPolicies.map((policy, i) =>
        i === index ? { ...policy, isPaid: value } : policy
      )
    );
  };
  const HandleSubmit = (e) => {
    e.preventDefault();
    const payload = { id: user.id, policy: timeOffPolicies };
    ApiClient.put(`edit/profile`, payload).then((res) => {
      if (res.success) {
        const newdata = { ...user, policy: timeOffPolicies };
        dispatch(login_success(newdata));
        toast.success("Policies updated Successfully");
      }
    });
  };

  useEffect(() => {
    if (user && user.policy && user.policy.length > 0) {
      setTimeOffPolicies(user.policy);
    }
  }, []);
  return (
    <>
      <Header />
      <div className="container mt-5">
        <div className="row">
          <div className="col-lg-12">
            <h2 className="mb-4"> Policies</h2>
            <div className=" policies-page">
              <div className="table-responsive">
                {" "}
                <table className="table  ">
                  <thead>
                    <tr>
                      <th>Policy Type</th>
                      <th>Assigned To</th>
                      <th className="text-center">Paid</th>
                      <th className="text-center">Unpaid</th>
                    </tr>
                  </thead>
                  <tbody>
                    {timeOffPolicies.map((policy, index) => (
                      <tr key={index}>
                        <td>{policy.name}</td>
                        <td>{policy.assignedTo}</td>
                        <td>
                          <input
                            type="radio"
                            name={`paid_${index}`}
                            checked={policy.isPaid == true}
                            onChange={() => handlePolicyChange(index, true)}
                          />
                        </td>
                        <td>
                          <input
                            type="radio"
                            name={`unpaid_${index}`}
                            checked={policy.isPaid == false}
                            onChange={() => handlePolicyChange(index, false)}
                          />
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
            <div className="d-flex justify-content-end mt-4">
              <button
                onClick={(e) => HandleSubmit(e)}
                className="btn btn-primary"
              >
                Save
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default TimeOffForm;
