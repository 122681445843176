const initialState = {
    time:""
  };
  
  const TimeTrackingReducer = (state = initialState, action) => {
    if(action.type=="Start") {
        // let newSeconds = state.seconds;
        // let newMinutes = state.minutes;
        // let newHours = state.hours;
        // newSeconds += 1;
  
        // if (newSeconds === 60) {
        //   newSeconds = 0;
        //   newMinutes += 1;
        //   if (newMinutes === 60) {
        //     newMinutes = 0;
        //     newHours += 1;
        //   }
        // }
  
        // Return a new state object
        return {
          ...state,
          time: action.data, 
        };
  

    }
    else if(action.type=="Stop"){
        return initialState;
    }
    else{ 
        return state;
    }
  };
  
  export default TimeTrackingReducer;
  