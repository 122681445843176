import { io } from "socket.io-client";
import environment from "../../../environment";
// const SocketURL="http://portal.jcsoftwaresolution.in:9005/"
const SocketURL=environment.Socketurl
// const SocketURL="https://virtualchat.jcsoftwaresolution.in/"
const ConnectSocket=io(SocketURL);
// const ConnectSocket=io('https://virtualchat.jcsoftwaresolution.in/');

ConnectSocket.connect();

export {ConnectSocket,SocketURL};