import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import Header from "../../components/global/header";
import Footer from "../Home/Footer";


export default function Referalinfromation() {
  const Navigate=useHistory()
  return (
<>
<Header/>
      <div className="referal_main ">
      <div className="container d-flex justify-content-center align-items-center h-100">
        <div>
            <h4 className="referal_heading">My VirtualPal Recruiter Program</h4>
          <p className="refral_desc">
          Transform Lives and Build Wealth with My Virtual Pal's Recruiter 
          </p>
            <button className=" btn btn-primary referal_btn" onClick={e => Navigate.push(`/signup?role=recruiter`)}>
            Become a Partner
          </button>
        </div>
      </div>
      </div>
      <div className="container ref_pro_container mb-md-5">
        <h5 className="ref_pro">Empower Virtual Careers—Earn Unlimited Recurring Income!</h5>
        <p className="ref_pro_desc">
        Become a part of a game-changing journey! As a recruiter, you’ll
         connect talented Virtual Assistants with employers worldwide. 
         Every match means a steady flow of income for you. No ceilings, 
         no limits—just the thrill of building a global network and
          watching your earnings grow as your recruits thrive. Think 
          big! Imagine the possibilities when your connections create
           a ripple effect of opportunities.
        </p>
        <span className="call-action">Call to Action:</span>
        <p className="ref_pro_desc mt-0"> Join the Recruiter Revolution Today and Start Earning Tomorrow!
        </p>
      </div>
   
    <div>
    <Footer/>
      </div>
    </>

  );
}
