import "./style.scss";
import methodModel from "../../methods/methods";
import { useHistory } from "react-router-dom";
import Header from '../../components/global/header';
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom/cjs/react-router-dom.min";


const Html = ({
  tab,
  edit,
  ChangeStatus,
  statusChange,
  pageChange,
  deleteItem,
  filters,
  loaging,
  data,
  isAllow,
  searchHandle,
  searchChange,
  categoriesdata,
  search,
  CatType,
  totalBlog,
  total = { total },
}) => {
  const user=useSelector(state=>state.user);
  const Navigate = useHistory();
  const {type}=useParams();
  const TypeFinder=()=>{
    if(type=="virtual"){
      return "Virtual Assistant"
    }
    else{
      return "Employer"
    }
  }
  return (
   <>
   <Header />
   <div className="container mb-5">
    

<div className='row'>
  <div className="col-md-12 text-center mt-5">
    <img src={methodModel.userImg(user.image)} className="referImg" />
    <h5 className="font-weight-bold mt-3">Refer {TypeFinder()} </h5>
    <button className="btn btn-primary mt-3" onClick={e=>Navigate.push(`/refer/${type}`)}>Share</button>
  </div>
</div>
</div> 
   </>
    
  );
};

export default Html;
