/*
 * @Author: Ghost 
 * @Email: Ghost@yopmail.com
 * @Date: 2024-03-21 11:08:28 
 * @Last Modified by:   Ghost 
 * @Last Modified time: 2024-03-21 11:08:28
 * @Description: file:///home/jc-21/Desktop/MyVirtualPal/marketplace-frontend/src/pages/AboutUS/About.js
 */

import Header from "../../components/global/header";

export default function About() {
  return (
    <>
    <Header/>
    <div className="mb-5">
      <div className="abouthead d-flex ">
        <button className="about_back">Back</button>
        <div className="m-auto">
          <span className="about_span">Our Platform Management</span>
        </div>
      </div>
      <div className="container-fluid p-5">
        <div className="row">
          <div className="col-md-6 ">
            <img
              src="/assets/img/about1.png"
              alt="logo"
              className="about1_img img-fluid"
            />
          </div>
          <div className="col-md-6 pl-md-5 ">
            <h3 className="about_heading">
              Enhance your <br></br>
              Business Outcomes
            </h3>
            <div className="about_bar"></div>
            <p className="about_desc">
              There are many variations of passages of Lorem Ipsum available,
              but the majority have suffered alteration in some form, by
              injected humour, or randomised words which don't look even
              slightly believable. If you are going to use a passage of Lorem
              Ipsum, you need to be sure there isn't anything embarrassing
              hidden in the middle of text. All the Lorem Ipsum generators on
              the Internet tend to repeat predefined chunks as necessary, makine
              from repetition, injected humour, or non-characteristic words etc.
            </p>
            <button className="btn btn-primary about_btn Subscribe ">
              Get in touch
            </button>
          </div>
        </div>
        <div className="row about_row_two">
          <div className="col-md-6">
            <h3 className="about_heading mt-0 mb-3">
              Optimise your business <br></br>
              growth operating model
            </h3>
            <div className="about_bar"></div>
            <p className="about_desc about_desctwo">
              There are many variations of passages of Lorem Ipsum available,
              but the majority have suffered alteration in some form, by
              injected humour, or randomised words which don't look even from
              repetition, injected humour, or non-characteristic words etc.
            </p>
            <p className="about_desc about_desctwo mt-4">
              There are many variations of passages of Lorem Ipsum available,
              but the majority have suffered alteration in some form, by the
              Internet tend to repeat predefined chunks as necessary, makine
              from repetition, injected humour, or non-characteristic words etc.
            </p>
          </div>
          <div className="col-md-6 ">
            <img
              src="/assets/img/about2.png"
              alt="logo"
              className=" img-fluid"
            />
          </div>
        </div>
      </div>
      <div className="about_hero_row">
        <div className="abouthero_parent">
          <div className="container-fluid">
            <div className="about_hero_div">
              <div className="about_hero_child pb-5">
                <h3 className="about_heading abouth_two">
                  Select the right Agency <br></br>
                  and Tech partners
                </h3>
                <div className="about_bar"></div>
                <p className="about_desc">
                  There are many variations of passages of Lorem Ipsum
                  available, but the majority have suffered alteration in some
                  form, by from repetition, injected humour, or
                  non-characteristic words etc.
                  <p className="mt-md-4">
                    {" "}
                    There are many variations of passages of Lorem Ipsum
                    available, hidden in the middle of text. All the Lorem Ipsum
                    generators on the Internet tend to repeat predefined chunks
                    as necessary, makine from repetition, injected humour, or
                    non-characteristic words etc.
                  </p>
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="container-fluid p-5 mt-md-5">
        <div className="row">
          <div className="col-md-6">
            <h3 className="about_heading">
              How can you choose <br></br>
              the right agency partners.
            </h3>
            <div className="about_bar"></div>
            <p className="about_desc">
            There are many variations of passages of Lorem Ipsum available,
              but the majority have suffered alteration in some form, by
              injected humour, or randomised words which don't look even
              slightly believable. If you are going to use a passage of Lorem
              the Internet tend to repeat predefined chunks as necessary, makine
              from repetition, injected humour, or non-characteristic words etc.
            </p>
            <p className="about_desc">
              <b>BetterRFP</b> connects, accelerates, and democratizes the RFP
              process like never before.
            </p>
            <button className="btn btn-primary about_btn Subscribe ">
              Get in touch
            </button>
          </div>
          <div className="col-md-6">
            <img
              src="/assets/img/fly wheel 2.png"
              alt="logo"
              className=" img-fluid partners_img"
            />
          </div>
        </div>
      </div>

      <div className="hero_2 mt-md-5">
        <div className="container-fluid d-flex justify-content-center align-items-center h-100">
          <div className="hero2_child text-center">
            <h5 className="hero2_header">
              Learn more about selecting the right partnerships
            </h5>
            <p className="hero2_desc mb-2">Drive business outcomes</p>
            <button className="btn btn-primary about_btn Subscribe mt-0 ">
              Get in touch
            </button>
          </div>
        </div>
      </div>
    </div>
      <div className="footer m-0 row mt-4 pb-5">
        <div className="text6 col-md-4 pl-5">
          <img
            loading="lazy"
            width={"100%"}
            height={"100%"}
            className="myvirtualpalLogo151Icon1"
            alt=""
            src="/assets/img/myvirtualpallogo15-11@2x.png"
          />
          <div className="inTheFastPacedContainer">
            <p className="mb-0">
              In the fast-paced world of modern technology,
            </p>
            <p className="">
              many leadership executives and organizations understand that
              building.
            </p>
          </div>
          <div className="about mt-3">
            <div className="socialIcon mb-3">
              <div className="twitter">
                <div className="iconBorder">
                  <div className="twitter1"></div>
                </div>
              </div>
              <div className="github">
                <div className="iconBorder">
                  <div className="twitter1"></div>
                </div>
                
              </div>
              <div className="dribbble">
                <div className="iconBorder2">
                  <div className="dribbble1"></div>
                </div>
              </div>
              <div className="fb">
                <div className="iconBorder">
                  <div className="fb1"></div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="text6 col-md-2">
          <div className="widgetTitle1">
            <div className="quickLinks">Quick Links</div>
          </div>
          <div className="text5">
            <div className="learnersLeadershipPartnersContainer">
              {/* <p className="leadership">Career</p> */}
              <a className="leadership" href="https://virtualpal.jcsoftwaresolution.in/about">About Us</a>
              <p>   <a className="leadership text-white" href="https://virtualpal.jcsoftwaresolution.in/content/term-conditions" >Term and Conditions</a></p>
              <p><a className="leadership text-white" href="https://virtualpal.jcsoftwaresolution.in/content/privacy-policy">Privacy Poilcy</a></p>
              <p className="leadership">Career</p>
            </div>
          </div>
        </div>
        <div className="text6 col-md-2">
          <div className="widgetTitle1">
            <div className="quickLinks">Community</div>
          </div>
          <div className="text4">
            <div className="learnersLeadershipPartnersContainer">
            <p className="leadership">  <Link
                                  className="leadership text-white"
                                  to="/mission"
                              >
                                  Mission
                              </Link></p>
              <p className="leadership">Leadership</p>
              <p className="leadership">Partners</p>
              <p className="leadership">Developers</p>
            </div>
          </div>
        </div>
        <div className="text6 col-md-4">
          <div className="widgetTitle1">
            {/* <div className="jhonSmith">Newsletter</div> */}
          </div>
          <div className="text3">
            <div className="signUpAnd">
              Sign up and receive the latest tips via email.
            </div>
          </div>
          <div className="inputField">
            <div className="border">
              <img
                loading="lazy"
                className="mailIcon mr-2"
                width={"100%"}
                height={"100%"}
                alt=""
                src="/assets/img/mail.svg"
              />
              <div className="enterYourMail">
                <input className="enterEmail" placeholder="Enter your email" />
              </div>
            </div>
          </div>
          {/* <div className="subscribeButton mt-3">
            <div className="btnBg">
              <div className="subscribeNow">Subscribe Now</div>
              <img
                loading="lazy"
                width={"100%"}
                height={"100%"}
                className="paperPlane1Icon ml-2"
                alt=""
                src="/assets/img/paperplane-1.svg"
              />
            </div>
          </div> */}
          {/* {!user.loggedIn?<p className='mt-4 ml-4 text-info' style={{cursor:"pointer"}} onClick={e=>history.push("/signup?role=recruiter")} >SignUp as a Recruiter</p>:null} */}
        </div>
      </div>
      <div className="footerBottom">
        <div className="footerBottomChild" />
        <div className="allRightsReserved">© 2024 All Rights Reserved</div>
      </div>
    </>
  );
}
