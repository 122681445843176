/*
 * @Author: Ghost 
 * @Email: mailto:ghost@yopmail.com
 * @Date: 2024-02-13 11:30:23 
 * @Last Modified by: Someone
 * Email: mailto:anilgodara4239@gmail.com
 * @Last Modified time: 2024-08-12 19:52:02
   */
import { useEffect, useState } from 'react'
import Header from '../../components/global/header'
import ApiClient from '../../methods/api/apiClient'
import loader from '../../methods/loader';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import environment from '../../environment';
import "react-datepicker/dist/react-datepicker.css";
import { DatePicker } from 'antd';
const { RangePicker } = DatePicker;
import axios from 'axios';
import moment from 'moment';
import methodModel from '../../methods/methods';
import dayjs from 'dayjs';

export default function Report() {
    const user = useSelector(state => state.user);
    // Get today's date
    const today = new Date();
    // Get tomorrow's date
    const tomorrow = new Date();
    tomorrow.setDate(today.getDate() - 1);

    const [filters, setfilters] = useState({ name: "", start_date: moment(tomorrow).format('YYYY-MM-DD'), end_date: moment(new Date()).format("YYYY-MM-DD"), employerId: user.id, contractId: "", virtualId: "", search: "" })
    const Navigate = useHistory();
    const [VAList, setVAList] = useState([]);
    console.log(VAList, '==VAList');

    const [Data, setData] = useState([]);
    const GetReports = (p = {}) => {
        const NewFilters = { ...filters, ...p, employerId: user.id }
        loader(true);
        ApiClient.get(`employer/list`, NewFilters).then(res => {
            if (res.success) {
                setData(res?.data);
            }
            loader(false);
        })
    }


    const GetVALIST = () => {
        loader(true);
        const userid = user.role && user.role.id == environment.EmployerId ? user.id : user.addedBy
        ApiClient.get(`virtualListing?employerId=${userid}`).then(async (res) => {
            if (res.success) {
                const newdata = res.data;
                const updateddata = []
                await newdata.map((item, index) => {
                    //  We Need to make the per Virtual Assistant id unique so we need to do this
                    updateddata.push({ id: item?.virtualDetail?._id, fullName: item?.contractName, contract_Id: item.id, mainid: item?.virtualDetail?._id + `12${index}`, VAName: item?.virtualDetail?.fullName })
                }
                );
                setVAList(updateddata)
            }
            loader(false)
        })
    }
    useEffect(() => {
        GetVALIST()
        GetReports()
    }, [])
    const [SelectedDateRange, setSelectedDateRange] = useState([dayjs(tomorrow), dayjs(today)])

    const handleDateChange = (date, dateStrings) => {
        setSelectedDateRange(date);
        let dates = dateStrings;
        setfilters({ ...filters, start_date: dates[0] || "", end_date: dates[1] || "" })
        GetReports({ start_date: dates[0] || "", end_date: dates[1] || "" })
        // You can perform additional filtering or other actions based on the selected date range
    };

    const DownloadEmployerReport = async (e) => {
        e.preventDefault();
        const token = await localStorage.getItem("token");
        const req = await axios({
            method: "get",
            url: `${environment.api}exportEmployerReport?` + `employerId=${user.id}&start_date=${filters?.start_date}&end_date=${filters?.end_date}&virtualId=${filters?.virtualId ? filters?.virtualId : ""}&contractId=${filters?.contractId}&search=${filters?.search}`,
            responseType: "blob",
            body: { token: token }
        });
        var blob = new Blob([req.data], {
            type: req.headers["content-type"],
        });
        const link = document.createElement("a");
        link.href = window.URL.createObjectURL(blob);
        link.download = `WorkReports.xlsx`;
        link.click();


    }

    const DownloadPdf = async (e) => {
        e.preventDefault();
        loader(true)
        const token = await localStorage.getItem("token");
        const req = await axios({
            method: "get",
            url: `${environment.api}epmloyer-report/pdf?start_date=${filters?.start_date}&end_date=${filters?.end_date}&employerId=${user?.id ? user?.id : ""}&virtualId=${filters?.virtualId ? filters?.virtualId : ""}&contractId=${filters?.contractId}&search=${filters?.search}`,
            responseType: "blob",
            body: { token: token },
        });
        var blob = new Blob([req.data], {
            type: req.headers["content-type"],
        });
        const blobUrl = URL.createObjectURL(blob);
        setTimeout(() => {
            fetch(blobUrl)
                .then((res) => res.json())
                .then(async (jsonData) => {
                    const pdfUrl = jsonData.path;
                    if (pdfUrl) {
                        await fetch(`${environment.api}${pdfUrl}`)
                            .then((res) => res.blob())
                            .then((pdfBlob) => {
                                const a = document.createElement("a");
                                a.href = URL.createObjectURL(
                                    new Blob([pdfBlob], { type: "application/pdf" })
                                );
                                a.download = "EmployerReports.pdf";
                                a.click();
                                loader(false);
                            })
                    } else {
                        loader(false);
                    }
                })
        }, 3000);
        loader(false)
    };

    // Search functions
    const searchHandle = (e) => {
        e.preventDefault()
        GetReports({ search: filters?.search })
    }
    const clear = () => {
        setfilters({ ...filters, search: "" })
        GetReports({ search: "" })
    }
    const hanldeSearch = (data) => {
        setfilters({ ...filters, search: data })
        if (!data) {
            GetReports()
        }
    }

    return (
        <>
            <Header />
            <div className='container mt-5 '>
                <div className='flow_flex  mb-4'>
                    <h3 className='mb-md-0'> Reports</h3>
                    <div className='d-flex align-items-center gap-2' >
                        <div className=''>
                            {/* <label className='mt-2 ml-1'>Search </label> */}
                            <form className='headerSearch serach_btns ' onSubmit={searchHandle}>
                                <input type="text" placeholder="Search..." name='search' value={filters?.search} onChange={e => hanldeSearch(e.target.value)} className="Searchbar"></input>
                                <i className="fa fa-search" onClick={searchHandle} aria-hidden="true"></i>
                                {filters?.search != "" ? <i className="fa fa-times" onClick={clear} aria-hidden="true"></i> : ""}
                            </form>
                        </div>
                        <button className='btn btn-primary ml-2' style={{ float: "right" }} onClick={e => DownloadEmployerReport(e)} >Export</button>
                        <button className='btn btn-primary' style={{ float: "right" }} onClick={e => DownloadPdf(e)} >Pdf Export</button>
                    </div>
                </div>


                <div className='row'>

                    <div className='col-md-3 mb-md-0 mb-2'>
                        <label>Date range</label>
                        <div className='new_range'>
                            <RangePicker
                                className="dateRangePicker"
                                placeholder={["Start Date", "End Date"]}
                                format="YYYY-MM-DD"
                                onChange={handleDateChange}
                                // onBlur={e=>setblur(true)} 
                                value={SelectedDateRange}
                            />
                        </div>
                    </div>
                    <div className='col-md-5 d-flex align-items-center'>
                        <div><label>VA  </label>
                            <div className="dropdown va_dropdown">
                                <button className="btn btn-secondary dropdown-toggle employer_filt" type="button" data-bs-toggle="dropdown" aria-expanded="false">
                                    <i className="fa fa-sliders mr-1" aria-hidden="true"></i> {filters.virtualId == "" ? "Filters" : filters.name}
                                </button>
                                <ul className="dropdown-menu">
                                    {VAList.map((item) => (<li><a className="dropdown-item" onClick={e => { setfilters(prev => ({ ...filters, name: item?.VAName, virtualId: item?.id, contractId: item?.contract_Id })); GetReports({ name: item?.VAName, virtualId: item?.id, contractId: item?.contract_Id }) }}>{item?.VAName}({item?.fullName.slice(0, 5)}..)</a></li>))}
                                </ul>
                            </div></div>
                        <div className='mt-4 ml-3 clear_bss'>
                            {filters.virtualId != "" || filters.start_date || filters.end_date || filters?.search ? <button className='btn btn-primary' onClick={() => { setfilters({ virtualId: "", end_date: "", start_date: "", contractId: "", name: "", search: "", contractId: "" }); setSelectedDateRange(null); GetReports({ virtualId: "", end_date: "", start_date: "", contractId: "", name: "", search: "", contractId: "" }) }}>Clear</button> : null}
                        </div>
                    </div>

                    {/* <div className=''>
                        <label>Start Date </label>
                        <input type='date' value={filters.start_date}  
                        onChange={e=>{console.log(e.target.value,"This is the data"); setfilters({...filters,start_date:e.target.value}); GetReports({start_date:e.target.value})}} 
                        required className='form-control' />
                    </div>
                    <div className=''>
                        <label>End Date </label>
                            <input type='date' value={filters.end_date} onChange={e => { setfilters({ ...filters, end_date: e.target.value }); GetReports({ end_date: e.target.value }) }} required className='form-control' />
                    </div> */}

                </div>
                <div className="mt-4 main-table table-bordered pb-4">

                    <div className='cmc_table reports_responsive'>

                        <div className='row cms firstcms table-text'>
                            <div className='col-3 border-color'>
                                <label>Contract Name</label>
                            </div>
                            <div className='col-3 border-color'>
                                <label>Virtual Assistant Name</label>
                            </div>

                            <div className='col-3 border-color'>
                                <label>Total Hours</label>
                            </div>
                            <div className='col-3 border-color'>
                                <label>Total Amount</label>
                            </div>


                        </div>

                        {Data.map((item, index) => (
                            <div className='row cms'>
                                <div className='col-3'>
                                    <p className='pms npms' style={{ cursor: "pointer" }} onClick={e => { item?.projectsDetail ? Navigate.push(`/offer/${item?.contractId}`) : null }}>{item?.projectsDetail?.name || item?.contractName}</p>
                                </div>
                                <div className='col-3'>
                                    <p className='pms npms' style={{ cursor: "pointer" }} onClick={e => { item?.projectsDetail ? Navigate.push(`/offer/${item?.contractId}`) : null }}>{item?.virtualDetail?.fullName} </p>
                                </div>

                                <div className='col-3'>
                                    <p className='pms gms'>{item?.totalDurationOfTime_hour < 10 ? "0" + item?.totalDurationOfTime_hour : item?.totalDurationOfTime_hour || "0"}:{item?.totalDurationOfTime_min < 10 ? "0" + item?.totalDurationOfTime_min : item?.totalDurationOfTime_min} hrs </p>
                                </div>
                                <div className='col-3'>
                                    <p className='pms gms'>{parseFloat(item?.TotalEmployerHourlyRate || 0).toFixed(2)} USD</p>
                                </div>
                                {item?.billingDetail?.map((itm) => (
                                    <>
                                        <div className='col-3 '>
                                            <p className={`pms p-2   ${index % 2 == 0 ? "backit" : "backitagin"} `}>{moment(itm?.startTime?.split('T')[0]).format(' dddd DD,MMM,YYYY')}   </p>
                                        </div>
                                        <div className='col-3 ' >

                                        </div>
                                        <div className='col-3 ' >
                                            <p className='pms '>{itm?.totalDurationOfTime_hour < 10 ? "0" + itm?.totalDurationOfTime_hour : itm?.totalDurationOfTime_hour || "0"}:{itm?.totalDurationOfTime_min < 10 ? "0" + itm?.totalDurationOfTime_min : itm?.totalDurationOfTime_min || "0"} hrs</p>
                                        </div>
                                        <div className='col-3 ' >

                                        </div>

                                    </>
                                ))}
                            </div>
                        ))}

                    </div>
                    {Data.length == 0 ? <div className='text-danger text-center'>No Active contract to view the Reports.</div> : null}

                </div>
            </div>
        </>
    )
}