/*
 * @Author: Ghost
 * @Email: Ghost@yopmail.com
 * @Date: 2024-02-13 11:30:23
 * @Last Modified by: Someone
 * Email: anilgodara4239@gmail.com
 * @Last Modified time: 2024-03-08 11:32:41
 * */
import { useState, useEffect } from "react";
import ApiClient from "../../methods/api/apiClient";
import loader from "../../methods/loader";
import "./home.scss";
import { useSelector } from "react-redux";
import methodModel from "../../methods/methods";
import Header from "../../components/global/header";
import {
  useHistory,
  useLocation,
} from "react-router-dom/cjs/react-router-dom.min";
import Multiselect from "multiselect-react-dropdown";
import "react-range-slider-input/dist/style.css";
import RangeSlider from 'react-range-slider-input'

const Virtual = () => {
  const PlatformData = useSelector((state) => state.PlatformfeeReducer);
  const [SearchData, setSearchData] = useState("");
  const [CountrySearch, setCountrySearch] = useState("");
  const [Options, setOptions] = useState([]);
  const [Skills, setSkills] = useState([]);
  const [CategoryData, setCategoryData] = useState([]);
  const history = useHistory();
  const routes = useLocation();
  const pathname = routes.pathname;
  const [AdvanceSearchData, setAdvanceSearchData] = useState({ allwords: "", anywords: "", nonewords: "", exactwords: "" });
  const user = useSelector((state) => state.user);
  const [filters, setfilters] = useState({
    search: "",
    skills: "",
    hourlyRateStart: "",
    hourlyRateEnd: "",
    country: [],
    experience: [],
    employerId: user.id,
  });
  const [Virtuals, setVirtuals] = useState([]);
  const [UserData, setUserdata] = useState({});
  //  page=1&count=2
  const GetVirtualListing = (p = {}) => {
    const NewFilters = {
      ...filters,
      ...p,
      country: p.country ? p.country.toString() : filters.country.toString(),
      experience: p.experience
        ? p.experience.toString()
        : filters.experience.toString(),
    };
    loader(true);
    ApiClient.get(`virtual/assistants`, NewFilters).then((result) => {
      if (result.success) {
        setVirtuals(result.data);
      }
      loader(false);
    });
  };

  useEffect(() => {
    if (!user.loggedIn) {
      history.push("/login");
    }
    GetVirtualListing();
  }, []);
  const HandleSearchFilter = (value) => {
    setfilters({ ...filters, search: "" });
    setSearchData("");
    GetVirtualListing({ search: "" });
  };
  const HandleSearchSubmit = (e) => {
    e.preventDefault();
    if (SearchData == "") {
    } else {
      setfilters({ ...filters, search: SearchData });
      GetVirtualListing({ search: SearchData });
    }
  };
  // This is a Filter Method for Skills
  const HandleSkills = (e) => {
    const { checked, value } = e.target;
    const skillarray = Skills;
    if (checked) {
      skillarray.push(value);
      setSkills(skillarray);
      GetVirtualListing({ skills: skillarray.toString() });
    } else {
      setSkills(skillarray.filter((item) => item != value));
      GetVirtualListing({
        skills: skillarray.filter((item) => item != value).toString(),
      });
    }
  };

  const GetCategoryData = () => {
    ApiClient.get(`skill/types/frontend`).then((res) => {
      if (res.success) {
        setCategoryData(res.data);
        const newdata = res.data;
        const array = [];
        newdata.map((type) => {
          type.skilltype &&
            type.skilltype.map((item) => {
              array.push({ cat: type.name, key: item.name, value: item._id });
            });
        });
        setOptions(array);
      }
    });
  };
  useEffect(() => {
    GetCategoryData();
  }, []);

  const HandleMultipleSelect = (e) => {
    // setfilters({ ...filters, skills: e.map((item) => item.value).toString() })
    // GetVirtualListing({ skills: e.map((item) => item.value).toString() })

    setSkills(e.target.value);
    setfilters({ ...filters, skills: e.target.value });

    try {
      clearTimeout(TimeoutSearch);
    } catch {
      console.log("error");
    }
    const TimeoutSearch = setTimeout(() => {
      GetVirtualListing();
    }, 1000);
  };

  //  For Getting the Regions Listing With Virtual Assistance count
  const [RegionsData, setRegionsData] = useState([]);
  const GetRegions = (p = {}) => {
    loader(true);
    ApiClient.get(`countries`, p).then((res) => {
      if (res.success) {
        setRegionsData(res.data);
      }
      loader(false);
    });
  };
  const HandleCountrySearch = (e) => {
    e.preventDefault();
    GetRegions({ countryName: CountrySearch });
  };
  useEffect(() => {
    GetRegions();
  }, []);

  // ################## For handling the Regions ##################
  const HandleRegionsFilter = (e) => {
    const { checked, value } = e.target;
    const array = filters.country;
    if (checked) {
      array.push(value);
      setfilters({ ...filters, country: array });
      GetVirtualListing({ country: array });
    } else {
      const newarray = [];
      array.filter((item) => {
        if (item != value) {
          newarray.push(item);
        }
      });
      setfilters({ ...filters, country: newarray });
      GetVirtualListing({ country: newarray });
    }
  };

  const Pushuser = (url) => {
    history.push(`${url}`);
  };

  const HandleExperienceFilter = (e) => {
    const { checked, value } = e.target;
    const array = filters.experience;
    if (checked) {
      array.push(value);
      setfilters({ ...filters, experience: array });
      GetVirtualListing({ experience: array });
    } else {
      const newarray = [];
      array.filter((item) => {
        if (item != value) {
          newarray.push(item);
        }
      });
      setfilters({ ...filters, experience: newarray });
      GetVirtualListing({ experience: newarray });
    }
  };

  const HandleShortlist = (e, id) => {
    // loader(true);
    e.preventDefault();
    const data = Virtuals;
    data.map((item, index) => {
      if (item.id == id) {
        data[index]["isShortListed"] = true;
      }
    });
    setVirtuals([...data]);
    const payload = { virtualAssisstantId: id, type: "virtualAssisstant" };
    ApiClient.post(`add_sortlist`, payload).then((res) => {
      if (res.success) {
        // GetVirtualListing();
      }
      // loader(false);
    });
  };

  const HandleRemoveShortlist = (e, id) => {
    // loader(true);
    e.preventDefault();
    const data = Virtuals;
    data.map((item, index) => {
      if (item.id == id) {
        data[index]["isShortListed"] = false;
      }
    });
    setVirtuals([...data]);
    const payload = { virtualAssisstantId: id };
    ApiClient.delete(`sortlist`, payload).then((res) => {
      if (res.success) {
        // GetVirtualListing();
      }
      // loader(false);
    });
  };

  const HandleRemoveCountry = (data) => {
    const existsData = filters.country;
    const newexistsdata = existsData.filter((item) => item != data);
    setfilters({ ...filters, country: newexistsdata });
    GetVirtualListing({ country: newexistsdata });
  };

  const HandleRemoveExperience = (data) => {
    const existsdata = filters.experience;
    const newdataexists = existsdata.filter((item) => item != data);
    setfilters({ ...filters, experience: newdataexists });
    GetVirtualListing({ experience: newdataexists });
  };

  const AdvanceSearchChanger = (name, value) => {
    setAdvanceSearchData({ ...AdvanceSearchData, [name]: value })
  }

  const HandleAdvanceSearchFilter = (e) => {
    e.preventDefault();
  }
  return (
    <>
      <Header />

      <div className="vertual_container  mb-5">
        <div className="container ">
          <div className="row">
            <div className="col-lg-3 col-md-4  bggray mt-5">
              <div className="sticky sticky-d">
                <h4 className="mt-4">Filter By</h4>
                {/* <RangeSlider
                  min={5}
                  step={1}
                  max={100}
                  // value={[1,50]}
                  onInput={e => { setfilters({ ...filters, hourlyRateStart: e[0], hourlyRateEnd: e[1] }); GetVirtualListing({ hourlyRateStart: e[0], hourlyRateEnd: e[1] }) }}
                />
                <p>${filters.hourlyRateStart} ${filters.hourlyRateEnd}</p> */}

                <div className="accordion mt-4" id="accordionExample">
                  {/* <div className="accordion-item">
                    <h2 className="accordion-header">
                      <a
                        className="accordion-button collapsed"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#collapseOne"
                        aria-expanded="false"
                        aria-controls="collapseOne"
                      >
                        <h4 className=" mb-0 headign_Cat">Category</h4>
                      </a>
                    </h2>
                    <div
                      id="collapseOne"
                      className="accordion-collapse collapse"
                      data-bs-parent="#accordionExample"
                    >
                      <div className="accordion-body">
                        <Multiselect
                          displayValue="key"
                          groupBy="cat"
                          className='cat_drop_multi'
                          // onKeyPressFn={function noRefCheck(){}}
                          onRemove={e => HandleMultipleSelect(e)}
                          // onSearch={function noRefCheck(){}}
                          onSelect={e => HandleMultipleSelect(e)}
                          selectedValues={Skills}
                          options={Options}
                          showCheckbox
                        />
                        <input
                          value={Skills}
                          onChange={(e) => HandleMultipleSelect(e)}
                          className="form-control"
                          placeholder="Enter Category here"
                        />
                      </div>
                    </div>
                  </div> */}

                  <hr className="mt-3" />

                  <div className="accordion-item pt-3">
                    <h2 className="accordion-header">
                      <a
                        className="accordion-button collapsed"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#collapseTwo"
                        aria-expanded="false"
                        aria-controls="collapseTwo"
                      >
                        <h4 className=" mb-0 headign_Cat">
                          Virtual Assistant Location
                        </h4>
                      </a>
                    </h2>
                    <div
                      id="collapseTwo"
                      className="accordion-collapse collapse"
                      data-bs-parent="#accordionExample"
                    >
                      <div className="accordion-body">
                        <div className="input-group mb-1">
                          <div className="input-group-prepend">
                            <div className="input-group-text">

                              <i
                                title="Search Location"
                                className="fas fa-search"
                                onClick={(e) => HandleCountrySearch(e)}
                              ></i>
                            </div>
                          </div>
                          <input
                            value={CountrySearch}
                            type="text"
                            placeholder="Select client locations"
                            onKeyPress={(e) =>
                              e.key == "Enter" ? HandleCountrySearch(e) : null
                            }
                            onChange={(e) =>
                              e.target.value == ""
                                ? GetRegions({ search: "" }) && setCountrySearch("")
                                : setCountrySearch(e.target.value)
                            }
                            className="form-control "
                            aria-label="Text input with checkbox"
                          />
                        </div>
                        <div className="mt-3">
                          {/* <p className='search_data'>Regions</p> */}

                          {RegionsData?.map((item, indedx) => {
                            return item.country != "" ? (
                              <div key={item.country} className="input-group">
                                <div className="row w-100">
                                  <div className="col-2">
                                    {" "}
                                    <input
                                      value={item.country}
                                      checked={filters.country.includes(
                                        item.country
                                      )}
                                      onChange={(e) => HandleRegionsFilter(e)}
                                      type="checkbox"
                                      className="mr-2"
                                    />
                                  </div>
                                  <div className="col-10">
                                    {" "}
                                    <label className="mb-1">
                                      {item.country} ({item.sum})
                                    </label>
                                  </div>
                                </div>
                              </div>
                            ) : null;
                          })}
                          {RegionsData.length == 0 ? (
                            <div className="text-danger">No Data Found</div>
                          ) : null}
                        </div>
                      </div>
                    </div>
                  </div>

                  <hr className="mt-3" />
                  <div className="accordion-item pt-3">
                    <h2 className="accordion-header">
                      <a
                        className="accordion-button collapsed"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#collapseThree"
                        aria-expanded="false"
                        aria-controls="collapseThree"
                      >
                        <h4 className=" mb-0 headign_Cat">Hourly Rate</h4>
                      </a>
                    </h2>
                    <div
                      id="collapseThree"
                      className="accordion-collapse collapse"
                      data-bs-parent="#accordionExample"
                    >
                      <div className="accordion-body">
                        <div className="d-flex align-items-center">
                          <div className="input-group mb-0">
                            <div className="input-group-prepend">
                              <span
                                className="input-group-text"
                                id="basic-addon1"
                              >
                                USD
                              </span>
                            </div>
                            <input
                              type="number"
                              className="form-control"
                              value={filters.hourlyRateStart}
                              placeholder="Min"
                              onChange={(e) =>
                                setfilters({
                                  ...filters,
                                  hourlyRateStart: e.target.value,
                                })
                              }
                              aria-label="Username"
                              aria-describedby="basic-addon1"
                            />
                          </div>
                          <span className="ml-1 mr-1">-</span>
                          <div className="input-group mb-0">
                            <div className="input-group-prepend">
                              <span
                                className="input-group-text"
                                id="basic-addon1"
                              >
                                USD
                              </span>
                            </div>
                            <input
                              type="number"
                              className="form-control"
                              value={filters.hourlyRateEnd}
                              min={filters.hourlyRateStart}
                              placeholder="Max"
                              onChange={(e) =>
                                setfilters({
                                  ...filters,
                                  hourlyRateEnd: e.target.value,
                                })
                              }
                              aria-label="Username"
                              aria-describedby="basic-addon1"
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <hr className="mt-3" />

                  <div className="accordion-item pt-3">
                    <h2 className="accordion-header">
                      <a
                        className="accordion-button collapsed"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#collapseFour"
                        aria-expanded="false"
                        aria-controls="collapseFour"
                      >
                        <h4 className=" mb-0 headign_Cat">Experience</h4>
                      </a>
                    </h2>
                    <div
                      id="collapseFour"
                      className="accordion-collapse collapse"
                      data-bs-parent="#accordionExample"
                    >
                      <div className="accordion-body">
                        <div className="mt-2">
                          <div className="input-group">
                            <div className="row w-100">
                              <div className="col-3">
                                {" "}
                                <input
                                  value="entryLevel"
                                  checked={filters.experience.includes(
                                    "entryLevel"
                                  )}
                                  onChange={(e) => HandleExperienceFilter(e)}
                                  type="checkbox"
                                  className="mr-2"
                                />
                              </div>
                              <div className="col-9">
                                {" "}
                                <label className="mb-1">EntryLevel</label>
                              </div>
                            </div>
                          </div>
                          <div className="input-group">
                            <div className="row w-100">
                              <div className="col-3">
                                <input
                                  onChange={(e) => HandleExperienceFilter(e)}
                                  value="intermediate"
                                  checked={filters.experience.includes(
                                    "intermediate"
                                  )}
                                  type="checkbox"
                                  className="mr-2"
                                />
                              </div>
                              <div className="col-9">
                                <label className="mb-1">Intermediate</label>
                              </div>
                            </div>
                          </div>
                          <div className="input-group">
                            <div className="row w-100">
                              <div className="col-3">
                                <input
                                  onChange={(e) => HandleExperienceFilter(e)}
                                  value="advance"
                                  checked={filters.experience.includes("advance")}
                                  type="checkbox"
                                  className="mr-2"
                                />
                              </div>
                              <div className="col-9">
                                <label className="mb-1">Advance</label>
                              </div>
                            </div>
                          </div>
                          <div className="input-group">
                            <div className="row w-100">
                              <div className="col-3">
                                <input
                                  onChange={(e) => HandleExperienceFilter(e)}
                                  value="expert"
                                  checked={filters.experience.includes("expert")}
                                  type="checkbox"
                                  className="mr-2"
                                />
                              </div>

                              <div className="col-9">
                                <label className="mb-1">Expert</label>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                {/* <span className='mt-2 mr-1' style={{fontSize:"20px"}}>$</span>
          <input type="number" placeholder='Min'  onChange={e=>setfilters({...filters,hourlyRateStart:e.target.value})}   className="form-control col-md-3 ml-2"  /> <span className='m-2'>-</span>
           <span className='mt-2' style={{fontSize:"20px"}}>$</span>
          <input type="number" placeholder='Max'  onChange={e=>setfilters({...filters,hourlyRateEnd:e.target.value})}   className="form-control col-md-3 ml-2" aria-label="Text input with checkbox" /><i style={{fontSize:"20px"}} onClick={e=>GetVirtualListing()} className='fa fa-search m-2'></i> */}
                {filters.experience.length > 0 ||
                  filters.skills.length > 0 ||
                  filters.country.length > 0 ||
                  CountrySearch.length > 0 ||
                  filters?.hourlyRateStart ||
                  filters?.hourlyRateEnd ?
                  <div className="text-right btn_virtual mt-4">

                    <button
                      className="btn btn-secondary mt-2 mr-2"
                      onClick={(e) => {
                        setfilters({
                          skills: [],
                          hourlyRateEnd: "",
                          hourlyRateStart: "",
                          search: "",
                          country: [],
                          experience: [],
                        });
                        GetVirtualListing({
                          skills: [],
                          hourlyRateEnd: "",
                          hourlyRateStart: "",
                          search: "",
                          country: [],
                          experience: [],
                        });
                        GetRegions({
                          countryName: ""
                        })
                        setSkills("");
                        setCountrySearch("")
                      }}
                    >
                      Clear
                    </button>
                    <button
                      onClick={(e) => GetVirtualListing()}
                      className="btn btn-primary mt-2"
                    >
                      Search
                    </button>
                  </div>
                  : null}
              </div>
            </div>

            <div className="col-lg-9 col-md-8 mt-5">
              <div className="main_shadow">
                <form
                  className="p-4 d-flex sticky_search_area"
                  style={{ float: "left" }}
                  onSubmit={(e) => HandleSearchSubmit(e)}
                >
                  <div className="input-group  search_virtual_list">
                    <input
                      type="search"
                      placeholder="Search"
                      onChange={(e) =>
                        e.target.value == ""
                          ? HandleSearchFilter(e.target.value)
                          : setSearchData(e.target.value)
                      }
                      id="form1"
                      className="form-control d-flex-inline "
                    />
                    <div className="  btnIconAppend">
                      <button
                        type="submit"
                        className="btn btn-primary   pl-3 pr-3"
                      >
                        <i className="fas fa-search"></i>
                      </button>
                    </div>
                  </div>
                  {/* <button
                  type="button"
                  className="btn btn-secondary advance_btn"
                  data-toggle="modal"
                  data-target="#exampleModal"
                >
                  Advance Search
                </button> */}

                  {/* <button className='ml-3 btn btn-outline-primary rounded d-flex align-items-center'><span  className="material-icons mr-2">save</span> Save Search</button> */}
                </form>
                <div
                  class="modal fade"
                  id="exampleModal"
                  tabindex="-1"
                  aria-labelledby="exampleModalLabel"
                  aria-hidden="true"
                >
                  <div class="modal-dialog">
                    <div class="modal-content">
                      <div class="modal-header">
                        <h5 class="modal-title" id="exampleModalLabel">
                          Advance Search
                        </h5>
                        <button
                          type="button"
                          class="close"
                          data-dismiss="modal"
                          aria-label="Close"
                        >
                          <span aria-hidden="true">&times;</span>
                        </button>
                      </div>
                      <div class="modal-body">
                        <div className="row">

                          <div className="col-12 mb-3">
                            <label className="label">All of these words</label>
                            <input className="form-control" value={AdvanceSearchData.allwords} onChange={e => AdvanceSearchChanger("allwords", e.target.value)} />
                          </div>

                          <div className="col-12 mb-3">
                            <label className="label">Any of these words</label>
                            <input className="form-control" value={AdvanceSearchData.anywords} onChange={e => AdvanceSearchChanger("anywords", e.target.value)} />
                          </div>
                          <div className="col-12 mb-3">
                            <label className="label">None of these words</label>
                            <input className="form-control" value={AdvanceSearchData?.nonewords} onChange={e => AdvanceSearchChanger("nonewords", e.target.value)} />
                          </div>
                          <div className="col-12 mb-3">
                            <label className="label">The exact pharse</label>
                            <input className="form-control" value={AdvanceSearchData?.exactwords} onChange={e => AdvanceSearchChanger("exactwords", e.target.value)} />
                          </div>
                        </div>
                      </div>
                      <div class="modal-footer">
                        <button
                          type="button"
                          class="btn btn-secondary"
                          data-dismiss="modal"
                        >
                          Cancel
                        </button>
                        <button type="button" class="btn btn-primary" onClick={e => HandleAdvanceSearchFilter(e)}>
                          Search
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="p-4">
                  {filters.experience.length > 0 ||
                    filters.skills.length > 0 ||
                    filters.country.length > 0 ? (
                    <p className="text-primary">Advanced Search</p>
                  ) : null}
                  <div className="d-flex flex-wrap align-items-center gap-2 flex-wrap">
                    {filters.country.map((item, index) => (
                      <span
                        key={item}
                        className="filter_badges filter_bad  d-flex align-items-center"
                      >
                        {item}{" "}
                        <span
                          className="material-icons ml-3"
                          style={{ cursor: "pointer" }}
                          onClick={(e) => HandleRemoveCountry(item)}
                        >
                          close
                        </span>
                      </span>
                    ))}
                    {filters.experience &&
                      filters.experience.map((item, index) => (
                        <span className="filter_badges filter_bad d-flex align-items-center ">
                          {item}{" "}
                          <span
                            className="material-icons ml-3"
                            style={{ cursor: "pointer" }}
                            onClick={(e) => HandleRemoveExperience(item)}
                          >
                            close
                          </span>
                        </span>
                      ))}
                    {/* {Skills.map((item, index) => ( */}

                    {filters.skills.length > 0 ? (
                      <span className="filter_badges filter_bad gap-3 flex-wrap d-flex align-items-center ">
                        {filters.skills}{" "}
                        <span
                          className="material-icons ml-3"
                          style={{ cursor: "pointer" }}
                          onClick={(e) => {
                            setfilters({ ...filters, skills: "" });
                            setSkills("");
                          }}
                        >
                          close
                        </span>
                      </span>
                    ) : null}
                    {/* ))} */}
                    {filters.experience.length > 0 ||
                      filters.skills.length > 0 ||
                      filters.country.length > 0 ? (
                      <a
                        className="text-primary"
                        onClick={(e) => {
                          setfilters({
                            skills: "",
                            hourlyRateEnd: "",
                            hourlyRateStart: "",
                            search: "",
                            country: [],
                            experience: [],
                          });
                          GetVirtualListing({
                            skills: "",
                            hourlyRateEnd: "",
                            hourlyRateStart: "",
                            search: "",
                            country: [],
                            experience: [],
                          });
                          setSkills("");
                        }}
                      >
                        Clear Filter
                      </a>
                    ) : null}
                  </div>
                </div>
                {Virtuals.map((item, index) => (
                  <div className="main_rowit">
                    <div className="row p-4 ">
                      <div className="col-lg-7 mb-3">
                        <div
                          className="d-flex gap-3 align-items-center"
                          onClick={(e) => Pushuser(`/virtualdetails/${item.id}`)}
                        >
                          {" "}
                          <div className="p-img">
                            <img
                              src={methodModel.userImg(item?.image)}
                              style={{
                                width: "60px",
                                height: "60px",
                                borderRadius: "50%",
                              }}
                              class="img-fluid"
                            />
                          </div>
                          <div>
                            {" "}
                            <p className="v_name"> {item.fullName}</p>
                            <p className="v_desc">
                              {/* {item.skillType
                              ? item.skillType.name
                              : "Virtual Assistant"} */}
                              Virtual Assistant
                            </p>
                          </div>
                        </div>
                      </div>
                      <div className="col-lg-5 mb-3">
                        <div className="d-flex align-items-center gap-3 flex-wrap justify-content-end invite-div">
                          {" "}
                          {user?.isVerifiedDocument == "pending" ? null : <div className="heart_div ">
                            <a
                              className="fav_icon_revealeant bg-white"
                              title={
                                item.isShortListed ? "UnShortlist" : "Shortlist"
                              }
                              onClick={(e) => {
                                item.isShortListed
                                  ? HandleRemoveShortlist(e, item.id)
                                  : HandleShortlist(e, item.id);
                              }}
                            >
                              <span className={`material-icons  `}>
                                {item.isShortListed
                                  ? "favorite"
                                  : "favorite_border"}
                              </span>
                            </a>
                          </div>}
                          {/* <div>
                  <button className="btn btn-secondary message_btn">
                    Message
                  </button>
                </div> */}
                          <div>
                            <button
                              onClick={(e) =>
                                Pushuser(`/virtualdetails/${item.id}`)
                              }
                              className="btn btn-primary invite_btn"
                            >
                              Invite to job
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="second_flex px-4 pb-4">
                      <span className="hoursof">
                        {" "}
                        HourlyRate:{" "}
                        {methodModel.CalculatePercentAmount(
                          item.hourlyRate,
                          PlatformData.platformFee
                        ) + item.hourlyRate}{" "}
                        USD
                      </span>
                      <span className="successof">
                        {" "}
                        <img src="/assets/img/checked.png" /> {parseFloat(item?.jobSuccess || 0).toFixed(0)}% Job Success
                      </span>
                      <span className="earnedof">{parseFloat(item?.virtualTotalEarned || 0).toFixed(2)} USD Earned</span>
                      <span className="availableof">
                        <img src="/assets/img/flash .png" />{" "}
                        <span className="available_content text-capitalize">{item?.experience}</span>
                      </span>
                    </div>
                    <div className="third_flex  px-4 pb-4">
                      {/* <span>
                      {" "}
                      {item.skills &&
                        item.skills.map((item, index) =>
                          index > 10 ? null : (
                            <span key={item.label}>{item.label}</span>
                          )
                        )}
                    </span> */}

                      {" "}
                      {item?.hardSkill_detail &&
                        item?.hardSkill_detail.map((item, index) =>
                          // index > 10 ? null : (
                          <span key={item.name} className="text-capitalize">{item.name}</span>
                          // )
                        )}

                      {/* {item?.skills.length > 10 ? <span>+10</span> : null} */}
                    </div>
                    <div className="px-4 pb-4">
                      <p className="v_details">
                        {item.description}{" "}
                        {/* <button className="see_it">See More</button> */}
                      </p>
                    </div>
                  </div>
                ))}

                <div className="row m-0 w-100 align-items-center mb-5">
                  {Virtuals.length < 1 ? (
                    <div className="text-center mt-3 text-danger">
                      <p>No Records Found</p>
                    </div>
                  ) : null}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Virtual;
