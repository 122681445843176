import Header from "../../components/global/header";
import { useEffect, useState } from "react";
import loader from "../../methods/loader";
import ApiClient from "../../methods/api/apiClient";
import methodModel from "../../methods/methods";
import moment from "moment";
import { useSelector } from "react-redux";
import { useHistory, useParams } from "react-router-dom/cjs/react-router-dom.min";
import "./style.scss";
import { Formik } from "formik";
import environment from "../../environment";
import axios from "axios";
import { ConnectSocket, SocketURL } from "../../components/global/SocketNetwork/ConnectSocket";

export default function JobApplyDetails() {
  const { id } = useParams();
  const searchparams = new URLSearchParams(window.location.search)
  const UserIdSearch = searchparams.get('userid')
  const user = useSelector(state => state.user);
  const Navigate = useHistory();
  const [ViewProjectData, setViewProjectData] = useState({});
  const [FreelancerDetails, setFreelancerDetails] = useState({});
  const [EmployerDetails, setEmployerDetails] = useState({});
  const [ProjectDetails, setProjectDetails] = useState({});
  const [total, setTotal] = useState(0);
  const [UserDetails, setUserDetails] = useState({ userid: UserIdSearch, useremail: "" })
  //   Shortlisted Data
  const [Data, setData] = useState([]);

  const GetData = () => {
    loader(true);
    ApiClient.get(`sortlist/list?type=project`).then((res) => {
      if (res.success) {
        setData(res.data);
      }
      loader(false);
    });
  };

  const GetDetails = () => {
    loader(true);
    ApiClient.get(`applyJob`, { id: id }).then(res => {
      //   if(res.success){
      const data = res.data;
      setFreelancerDetails(data?.addedBy);
      setUserDetails({ ...UserDetails, useremail: data.addedBy && data.addedBy.email })
      setProjectDetails(data?.projectId);
      setEmployerDetails(data?.employerId);
      setViewProjectData(data);
      //   }
      loader(false);
    })

  }

  useEffect(() => {
    if (id != undefined) {
      GetDetails();
    }
  }, [id])

  useEffect(() => {
    GetData();
  }, []);

  //   Removing the Shortlisted 
  const HandleRemoveFromShortlist = (e, _id) => {
    e.preventDefault();
    loader(true);
    ApiClient.delete(`sortlist?projectId=${_id}`).then((res) => {
      if (res.success) {
        GetData();
      }
      loader(false);
    });
  };



  useEffect(() => {
    GetData();
  }, []);



  const GetPostedTime = (date) => {
    const data = new Date(date).getTime();
    const result = moment.utc(new Date(date)).local().startOf("date").fromNow();
    if (result == "an hour ago") {
      return "1hr ago";
    } else {
      return result;
    }
  };

  const HandleAcceptRejectJob = (e, status) => {
    e.preventDefault();
    loader(true);
    const payload = {
      "id": id,
      "status": status
    }
    ApiClient.put(`accept/reject/job`, payload).then(res => {
      if (res.success) {
        GetDetails();

      }
      loader(false)
    })
  }

  const HandleMessageClick = (e) => {
    e.preventDefault();
    loader(true);
    const IdWithRole = user.role && user.role.id == environment.VirtualId ? EmployerDetails.id : FreelancerDetails.id
    const payload = {
      "addedBy": FreelancerDetails.id,
      "friendId": EmployerDetails.id,
      "projectId": ProjectDetails.id
    }
    ApiClient.post("addUserFriend", payload).then(res => {
      axios.post(SocketURL + "chat/user/join-group", { "chat_by": user.id, "chat_with": IdWithRole, project_id: ProjectDetails.id }).then(newres => {
        const data = newres.data && newres.data.data;
        //  If User is Applying for Job
        const SendMessagePayload = {
          "room_id": data.room_id,
          "sender": FreelancerDetails.id,
          "type": "TEXT",
          "message_type": "applyjob",
          "content": ViewProjectData.coverLetter,
          "project_id": ProjectDetails.id,
          "user_id": IdWithRole,
          "login_user_id": user.id,
          "inviteId": id
        }
        ConnectSocket.emit(`new-message`, SendMessagePayload);
      })
      if (res.success) {
        const idneedtoredirect = user.role && user.role.id == environment.VirtualId ? EmployerDetails.id : FreelancerDetails.id
        Navigate.push(`/chat?userid=${idneedtoredirect + ProjectDetails.id}`)
      }
      loader(false);
    })
  }
  const Platformdata = useSelector(state => state.PlatformfeeReducer)
  const HourlyRateCustomizable = (hourlyrate) => {
    if (user.role && user.role.id == environment.EmployerId) {
      return methodModel.CalculatePercentAmount(hourlyrate, Platformdata.platformFee) + hourlyrate
    }
    else {
      return hourlyrate;
    }
  }

  return (
    <>
      <Header />
      <div className="container vertual_container mt-4 mb-5">
        <div className="row">
          <div className="col-md-9 mb-md-0 mb-3">
            <div className="maindiv_boder">
              <div className="d-flex gap-2 align-items-center p-3 headarrow"><i title="Back" onClick={() => Navigate.goBack()} class="fa fa-angle-left" aria-hidden="true"></i>
                <h3 className=" mb-0">Proposal Details</h3>
              </div>
              <div className="row">
                <div className="col-12 col-sm-12 col-md-12  col-lg-8">
                  <h3 className="head">{ProjectDetails?.name || ProjectDetails?.title}</h3>
                  <div className="pl-3">
                    <div className="badges_project">
                      <span className="mt-2 ">{ViewProjectData?.project_skill_type && ViewProjectData?.project_skill_type.name}</span>
                    </div>
                    <p className="mt-3" dangerouslySetInnerHTML={{ __html: ProjectDetails?.description }}></p>
                  </div>
                </div>

                <div className="col-12 col-sm-12 col-md-12  col-lg-4">
                  <div className="sidediv">
                    <h3 className="smalhead"><i className="fa fa-user mr-1" aria-hidden="true"></i>  intermediate</h3>
                    <p className="ml-4">Experience level</p>



                    <h3 className="smalhead"><i className="fa fa-address-book mr-1" aria-hidden="true"></i> {ProjectDetails.hourlyRate} USD </h3>
                    <p className="ml-4">Hourly Rate</p>



                    <h3 className="smalhead"><i className="fa fa-address-book mr-1" aria-hidden="true"></i> Less than 40 hrs/rate</h3>
                    <p className="ml-4">Hourly</p>



                    <h3 className="smalhead"><i className="fa fa-calendar mr-1" aria-hidden="true"></i> 1 to 3 month</h3>
                    <p className="ml-4">Project length</p>
                  </div>
                </div>
              </div>
              <hr />


              <h3 className="head">Skills Expertise</h3>
              <div className="badges_project pl-3 mb-2">
                <div className="d-flex">
                  {ProjectDetails.skills && ProjectDetails.skills.map((item, index) => (<span className="mt-2">{item.name}</span>))}
                  {/* <span className="mt-2">Devlopment</span>*/}
                  {/* <span className="mt-2">Devlopment</span>*/}
                </div>

              </div>
              <hr />
              {/* { ViewProjectData.status=="accepted"? */}
              <>
                <h3 className="head">Your Proposed terms</h3>
                <div className="pl-3">
                  <h6>Hourly Rate</h6>
                  <p>Total amount the client will see on your proposal</p>
                  <p>{methodModel.CalculatePercentAmount(ViewProjectData.hourlyRate, Platformdata.platformFee) + ViewProjectData.hourlyRate} USD/hr</p>
                </div>
                <hr />





                <div className="pl-3">
                  <h6>You'll {user.role && user.role.id == environment.EmployerId ? "Pay" : "Recieve"}</h6>
                  {/* <p>Total amount the client will see on your proposal</p> */}
                  <p>{HourlyRateCustomizable(ViewProjectData.hourlyRate)} USD /hr</p>
                </div>

                {/* <hr/> */}
              </>
              {/* :null} */}


              {/* <div className="pl-3">
<h6>Rate increase</h6>
<p>Total amount the clint will see on your proposal</p>
<p>$15.00/hr</p>
</div> */}
              {/* <div className=" d-flex p-3">
            <button className="btn btn-primary mr-3">Change Terms</button>
            <button className="btn btn-outline ">Window Proposal</button>
          </div>         */}
            </div>
          </div>

          <div className="col-md-3">
            {user.role && user.role.id == environment.EmployerId && ViewProjectData.status == "pending" ? <> <button className="btn btn-primary w-100 mb-3" onClick={e => HandleMessageClick(e)}>Message</button>
              <button className="btn btn-outline-primary w-100 mb-4" onClick={e => HandleAcceptRejectJob(e, "rejected")} >Decline</button> </> :
              //  Reason Here for Accept and Reject 
              ViewProjectData.status == "rejected" ? <p className="btn btn-danger">Rejected</p> : <button className="btn btn-primary mb-3" onClick={e => HandleMessageClick(e)} >Message</button>
              // :<button disabled  className={`btn mb-3 ${ViewProjectData.status=="accepted"?"btn-success":ViewProjectData.status=="pending"?"btn-warning":"btn-danger"}`}>{ViewProjectData.status}</button>
            }
            {/* <p><span className="">freelancer:</span> <span className="freelancer">akanksha p</span>,
           <br/>jc softwaresolution.com,<br/> mohali tower, Mohali</p> */}

            {user.role && user.role.id == environment.VirtualId ? <> <h3 className="heeding">About the client</h3>
              <p>Payment method verified</p>

              {/* <h3 className="heeding">Location</h3> */}
              <p>{EmployerDetails?.address}</p></> : <> <h3 className="heeding">About the Virtual Assistant</h3>
              <p>{FreelancerDetails?.fullName}</p>

              <h3 className="heeding">Location</h3>
              <p>{FreelancerDetails?.address}</p></>}
            {/* <h3 className="heeding">History</h3> */}
            {/* <p>20 to 50 proposal</p> */}
            {/* <p>1 interview</p> */}
            {/* <p>2 job post</p> */}
          </div>

          <div className="col-md-9 mt-5">
            {/* {ViewProjectData.status=="accepted"? */}
            <div className="maindiv_boder pt-3">

              <><div className="pl-3">
                <h6 className="heddingcl mb-4">Cover Letter</h6>
                <p>{ViewProjectData.coverLetter}</p>
              </div>
                {/* <div className=" d-flex p-3">
            <button className="btn btn-primary mr-3">Change Terms</button>
            <button className="btn btn-outline ">Window Proposal</button>
          </div> */}
              </>
              <><div className="pl-3 mb-3">
                <h6 className="heddingcl mb-4">Attachments</h6>
                {ViewProjectData.document && ViewProjectData.document.map((item, index) => <>
                  <img className="mr-3"
                    style={{ cursor: "pointer" }}
                    width={40}
                    src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcR5kekkj6iYr_ZSKfB7GRWEJ4q-7_2sruMm5Hbc2haxduVzPhmc1kS-7OKKJDI1FjTEVAg&usqp=CAU"
                    onClick={(e) =>
                      window.open(
                        `${environment.api}images/document/${item}`
                      )
                    }
                  />
                </>)}
              </div>
                {/* <div className=" d-flex p-3">
            <button className="btn btn-primary mr-3">Change Terms</button>
            <button className="btn btn-outline ">Window Proposal</button>
          </div> */}
              </>
            </div>
            {/* :null}  */}
          </div>
        </div>

      </div>

      <button type="button" className="btn btn-primary d-none" id="openreason1" data-toggle="modal" data-target="#openreasonmodal">
        Launch demo modal
      </button>
      <div className="modal fade" id="openreasonmodal" tabIndex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
        <div className="modal-dialog" role="document">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="exampleModalLabel">Rejection Reason</h5>
              <button type="button" id="closereasonmodal" className="close" data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <Formik
              enableReinitialize
              initialValues={{
                reasonofRejection: ""
              }}
              onSubmit={(values, { resetForm }) => {
                loader(true);
                const payload = { "id": id, "status": "rejected", reason: values.reasonofRejection };
                ApiClient.put("accept/reject/invite", payload).then(res => {
                  if (res.success) {
                    GetDetails()
                    document.getElementById("closereasonmodal").click();

                    resetForm();
                  }
                  loader(false)
                })
              }}
            >
              {({ handleBlur, values, handleChange, handleReset, handleSubmit }) => (<form onSubmit={handleSubmit}>
                <div className="modal-body">
                  <label>Reason<span className="text-danger">*</span></label>
                  <textarea value={values.reasonofRejection} required name="reasonofRejection" onChange={handleChange} className="form-control"></textarea>
                  <div className="modal-footer">
                    <button type="button" className="btn btn-secondary" data-dismiss="modal">Close</button>
                    <button type="submit" className="btn btn-primary">Submit</button>
                  </div>
                </div>
              </form>
              )}
            </Formik>
          </div>
        </div>
      </div>


    </>
  );
}
