import { useEffect, useState } from "react";
import {
  useHistory,
  useParams,
} from "react-router-dom/cjs/react-router-dom.min";
import Header from "../../components/global/header";
import environment from "../../environment";
import ApiClient from "../../methods/api/apiClient";
import { toast } from "react-toastify";
import "react-phone-input-2/lib/style.css";

import PhoneInput from "react-phone-number-input";

export default function AddEmployerHelper() {
  const { roleid } = useParams();
  const searchparams = new URLSearchParams(window.location.search);
  const GetEditId = searchparams.get(`editid`);
  const [Submitted, setSubmitted] = useState(false);
  const Naviagte = useHistory();
  const [data, setdata] = useState({
    firstName: "",
    lastName: "",
    role: roleid,
    email: "",
    primary_contact: "",
    secondary_contact: "",
  });

  const HandleChange = (e) => {
    const { name, value } = e.target;
    setdata((data) => ({ ...data, [name]: value }));
  };

  useEffect(() => {
    if (GetEditId) {
      ApiClient.get(`staff?id=${GetEditId}`).then((res) => {
        if (res.success) {
          const data = res.data;
          setdata({
            firstName: data.firstName,
            lastName: data.lastName,
            role: roleid,
            email: data.email,
            primary_contact: data.primary_contact,
            secondary_contact: data.secondary_contact,
          });
        }
      });
    }
  }, [GetEditId]);

  //  For Getting the Role Title Which is Employer want to Addd
  const HandleRoleTitle = () => {
    if (roleid == environment.DirectManagerId) {
      return "Direct Manager";
    } else if (roleid == environment.AccountManagerId) {
      return "Accounting contact";
    } else {
    }
  };

  const HandleSubmit = (e) => {
    e.preventDefault();
    setSubmitted(true);
    if (data.primary_contact == "") return;
    let method = "post";
    let url = "add/user";
    const payload = {
      id: GetEditId,
      firstName: data.firstName,
      lastName: data.lastName,
      role: roleid,
      email: data.email.trim(),
      primary_contact: data.primary_contact,
      secondary_contact: data.secondary_contact,
    };
    if (GetEditId) {
      method = "put";
      url = "staff";
    } else {
      delete payload.id;
    }
   
    ApiClient.allApi(url, payload, method).then((res) => {
      if (res.success) {
        Naviagte.push("/mystaff");
        toast.success(
          `${HandleRoleTitle()} ${
            GetEditId ? "updated" : "created"
          } Successfully`
        );
        setdata({
          firstName: "",
          lastName: "",
          role: roleid,
          email: "",
          secondary_contact: "",
          primary_contact: "",
        });
      }
    });
  };

  const DirectManagerTerm = [
    "Direct Manager Can check The Virtual Assistant Clock In Time",
    "Direct Manager Can check The Virtual Assistant Clock Out Time",
  ];
  const AccountManagerTerm = [
    "Accounting contact Can Check all the Accounts History",
  ];
  return (
    <>
      <Header />
      <div className="container mt-5">
        {" "}
        <div className="bank_account_card add_account_width mb-5" style={{ borderRadius: "3px" }}>
          <h2 className="mb-3 text-center mt-2 mb-5">Add {HandleRoleTitle()}</h2>
          <form onSubmit={HandleSubmit}>
            <div className="row">
              <div className="col-md-6 mb-3">
                <label>
                  First Name <span className="text-danger">*</span>
                </label>
                <input
                  type="text"
                  name="firstName"
                  value={data.firstName}
                  onChange={HandleChange}
                  className="form-control"
                  required
                />
              </div>
              <div className="col-md-6 mb-3">
                <label>
                  Last Name <span className="text-danger">*</span>
                </label>
                <input
                  type="text"
                  name="lastName"
                  value={data.lastName}
                  onChange={HandleChange}
                  className="form-control"
                  required
                />
              </div>
              <div className="col-md-6 mb-3">
                <label>
                  Email <span className="text-danger">*</span>
                </label>
                <input
                  type="email"
                  name="email"
                  value={data.email}
                  onChange={HandleChange}
                  className="form-control"
                  required
                />
              </div>
              <div className="col-md-6 mb-3">
                <label>
                  Primary Contact Number <span className="text-danger">*</span>
                </label>
                <PhoneInput
                  country={"us"}
                  value={data.primary_contact}
                  className="form-control roundbox"
                  onChange={(e) => setdata({ ...data, primary_contact: e })}
                  required
                />
              </div>
              <div className="col-md-6 mb-3">
                <label>Secondary Contact Number </label>

                <PhoneInput
                  country={"us"}
                  value={data.secondary_contact}
                  className="form-control roundbox"
                  onChange={(e) => setdata({ ...data, secondary_contact: e })}
                />
              </div>
              <div className="col-md-12 mt-3">
                <label>Permissions</label>
                {roleid == environment.DirectManagerId
                  ? DirectManagerTerm.map((item) => (
                      <p className="p-1">
                        <i className="fa fa-check text-success"></i> {item}
                      </p>
                    ))
                  : AccountManagerTerm.map((itm) => (
                      <p className="p-1">
                        <i className="fa fa-check text-success"></i>
                        {itm}
                      </p>
                    ))}
              </div>
              <div className="col-md-12 mt-2 mb-2">
                <div style={{ float: "right" }} className="mr-3">
                  <button
                    className="btn btn-secondary mr-2"
                    onClick={(e) => Naviagte.goBack()}
                    type="button"
                  >
                    Back
                  </button>
                  <button className="btn btn-primary" type="submit">
                    Save
                  </button>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
    </>
  );
}
