/*
 * @Author: Ghost 
 * @Email: Ghost@yopmail.com
 * @Date: 2024-03-21 11:08:48 
 * @Last Modified by: Someone
 * @Last Modified time: 2024-03-21 11:12:03
 * @Description: file:///home/jc-21/Desktop/MyVirtualPal/marketplace-frontend/src/pages/BankPayment/AddBankAccount.js
 */
import { useState } from 'react';
import { useSelector } from 'react-redux';
import GooglePlaceAutoComplete from '../../components/common/GooglePlaceAutoComplete';
import addressModel from '../../models/address.model';
import moment from 'moment';
import ApiClient from '../../methods/api/apiClient'
import environment from '../../environment';
import loader from '../../methods/loader';
import { toast } from 'react-toastify';
import Header from '../../components/global/header';
import './Bank.scss'
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
const BankAccountAddForm = () => {

    const user=useSelector(state=>state.user);
    const Navigate=useHistory() 
    const [Loading,setLoading]=useState(false);
  const [SelectedTab, setSelectedTab] = useState('individual')
  const [formData, setFormData] = useState({
    email: user.email,
    accountholder_name: '',
    routing_number: '',
    account_number: '',
    first_name: user.firstName,
    last_name:user.lastName,
    mobile: user.mobileNo,
    ssn_number: '',
    address: {
      line1: user.address,
      city: user.city,
      state: user.state,
      postal_code: user.pincode,
    },
    dob: {
      day: '',
      month: '',
      year: '',
    },
    company_name: user.company_name,
    frontdoc: '',
    backdoc: '',
    user_id: user.id,
  });
const [Images,setImages]=useState({frontImage:"",backImage:"",isVerified:false})
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };


  const handleAddressChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      address: {
        ...prevData.address,
        [name]: value,
      },
    }));
  };

  //  THIS WILL BE GIVE HERE TO ARRANGE THE DATE ACCORDING TO API NEED 
  const handleDOBChange = (e) => { 
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      dob: {
        ...prevData.dob,
        month:moment(value).format("MM"),day:moment(value).format("DD"),year:moment(value).format("YYYY")
      },
    }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (formData.confirm_routing_number != formData?.routing_number) { toast.error('Routing Number and Confirm Confirm Number should be same'); return }
    if(formData.confirm_account_number!=formData?.account_number){toast.error('Account Number and Confirm Account Number should be same'); return}
    if(!formData.frontdoc||!formData.backdoc){toast.error('You need to Verify your Document'); return}
loader(true);
    if (SelectedTab == 'individual'){
      delete formData['ein_number']
    }else{
      delete formData['ssn_number']
    }
ApiClient.post(`bank`,formData).then(res=>{
  if(res.success){
    Navigate.push('/banks')
    toast.success(res.message)
  }
  loader(false);
}) 
  };


  // FOR HANDLING THE ADDRESS THROUGH GOOGLE EXTRACTING ALL THE THINGS 
  const addressResult = async (e) => {
    let address = {}
    if (e.place) {
      address = addressModel.getAddress(e.place)
    } else {

    }
    setFormData({
      ...formData,address:{
        line1: e.value, 
      city: address.city || '',
      state: address.state || '',
      postal_code: address.zipcode || '',
      }
    })
 
  }


  const ImageHandler=(e,keyName,url)=>{
    const file=e.target.files[0];

    loader(true)
    ApiClient.postFormData(url,{file:file}).then(res=>{ 
      if(res.success){
setImages({...Images,[keyName]:res.imagePath,isVerified:false})
      }
      loader(false)
    })
    
  }

  // Here is the Common FUnction thats Handle the Verification of the images 
  const HandleImageVeri=(type="front",KeyName)=>{
    const payload={
      image:type=='front'?Images.frontImage:Images.backImage,
      path:type=='front'?'/frontdoc/'+Images.frontImage:'/backdoc/'+Images.backImage
    } 
    ApiClient.post(`verify/images`,payload).then(res=>{
      if(res.success){
setFormData(prev=>({...prev,[KeyName]:res.data?.id}))
if(formData.frontdoc!=""){
  setImages({...Images,isVerified:true})
}
      }
      setLoading(false)
    })
  }
  const HandlerVerify=(e)=>{
    e.preventDefault();
    setLoading(true)
    HandleImageVeri('front','frontdoc');
    HandleImageVeri('back','backdoc')
  }

  // Function to format SSN number
  const formatSSN = (nwewvalue) => {
    let value = nwewvalue.toString();
    // Implement your SSN formatting logic here
    // For example, add dashes every 3 characters
    return value.replace(/(\d{3})(\d{2})(\d{4})/, '$1-$2-$3');
  };

  const FormatEIN=(newvalue)=>{
    let value = newvalue.toString(); 
    return value.replace(/(\d{2})(\d{7})/, '$1-$2');
  };
  
  return (
    <>
      <Header/>
    <div className="container my-4">
      <div className='bank_account_card add_account_width'>
      <div className='d-flex align-items-center justify-content-between mb-md-4 mb-3 d_flexbx'>
      <h4 className="mt-2 text-center moddecsheader">Add Bank Account</h4>
     <div> {user.role&&user.role.id==environment.EmployerId?<>
      <div className='d-flex gap-2 '>
      <button onClick={e=>setSelectedTab('individual')}  className={`btn btn-${SelectedTab=='individual'?'primary':'secondary'}`}>Individual</button>
              <button className={`btn btn-${SelectedTab == 'company' ? 'primary' : 'secondary'}`} onClick={e=>setSelectedTab('company')} >Company</button>
            </div>
      </>:null}</div>
      </div>
      <form onSubmit={handleSubmit} className='row' >
        <div className="mb-3 col-md-6">
          <label htmlFor="email" className="form-label">
            Email
          </label>
          <input
            type="email"
            className="form-control"
            id="email"
            name="email"
            placeholder="Enter email"
            value={formData.email}
            onChange={handleChange}
            required
          />
        </div>

        <div className="mb-3 col-md-6">
          <label htmlFor="accountholder_name" className="form-label">
            Account Holder Name
          </label>
          <input
            type="text"
            className="form-control"
            id="accountholder_name"
            name="accountholder_name"
            placeholder="Enter account holder name"
            value={formData.accountholder_name}
            onChange={handleChange}
          required
          />
        </div>

        <div className="mb-3 col-md-6">
          <label htmlFor="routing_number" className="form-label">
            Routing Number
          </label>
          <input
            type="text"
            min={9}
            max={9}
            className="form-control"
            id="routing_number"
            name="routing_number"
            placeholder="Enter routing number"
            value={formData.routing_number}
            onChange={handleChange}
            required
          />
        </div>
            <div className="mb-3 col-md-6">
              <label htmlFor="routing_number" className="form-label">
             Confirm   Routing Number
              </label>
              <input
                type="text"
                min={9}
                max={9}
                className="form-control"
                id="confirm_routing_number"
                name="confirm_routing_number"
                placeholder="Confirm  routing number"
                value={formData.confirm_routing_number}
                onChange={handleChange}
                required
              />
            </div>

        <div className="mb-3 col-md-6">
          <label htmlFor="account_number" className="form-label">
            Account Number
          </label>
          <input
            type="number"
            className="form-control"
            id="account_number"
            name="account_number"
            placeholder="Enter account number"
            value={formData.account_number}
            onChange={handleChange}
            required
          />
        </div>
            <div className="mb-3 col-md-6">
              <label htmlFor="account_number" className="form-label">
              Confirm Account Number
              </label>
              <input
                type="number"
                className="form-control"
                id="account_number"
                name="confirm_account_number"
                placeholder="Confirm account number"
                value={formData.confirm_account_number}
                onChange={handleChange}
                required
              />
            </div>
        <div className="mb-3 col-md-6">
          <label htmlFor="first_name" className="form-label">
            First Name
          </label>
          <input
            type="text"
            className="form-control"
            id="first_name"
            name="first_name"
            placeholder="Enter first name"
            value={formData.first_name}
            onChange={handleChange}
            required
          />
        </div>

        <div className="mb-3 col-md-6">
          <label htmlFor="last_name" className="form-label">
            Last Name
          </label>
          <input
            type="text"
            className="form-control"
            id="last_name"
            name="last_name"
            placeholder="Enter last name"
            value={formData.last_name}
            onChange={handleChange}
            required
          />
        </div>

        <div className="mb-3 col-md-6">
          <label htmlFor="mobile" className="form-label">
            Mobile
          </label>
          <input
            type="text"
            className="form-control"
            id="mobile"
            name="mobile"
            placeholder="Enter mobile number"
            value={formData.mobile}
            onChange={handleChange}
            required
          />
        </div>

     {SelectedTab=='individual'? <div className="mb-3 col-md-6">
          <label htmlFor="ssn_number" className="form-label">
            SSN Number
          </label>
          <input
            type="text"
            className="form-control"
            id="ssn_number"
            name="ssn_number"
            max={9}
            maxLength={9}
            placeholder="Enter SSN number"
                value={formatSSN(formData.ssn_number)}
            onChange={handleChange}
                onInput={formatSSN}
            required
          />
        </div> 
        
               : <div className="mb-3 col-md-6">
                <label htmlFor="ein_number" className="form-label">
                  EIN Number
                </label>
                <input
                  type="text"
                  className="form-control"
                  id="ein_number"
                  name="ein_number"
                  maxLength={9}
                  placeholder="Enter EIN number"
                  value={FormatEIN(formData?.ein_number||'')}
                  onChange={handleChange}
                  required
                />
              </div> 
        }
        <div className="mb-3 col-md-6">
          <label htmlFor="address_line1" className="form-label">
            Address Line 1
          </label>
          <GooglePlaceAutoComplete
          
          placeholder="Select Address"
          id="Google"
          result={e=>addressResult(e)}
          value={formData.address.line1}
          /> 
        </div> 
        
        <div className="mb-3 col-md-6">
          <label htmlFor="state" className="form-label">
            State
          </label>
          <input
            type="text"
            className="form-control"
            id="state"
            name="state"
            placeholder="Enter State"
            value={formData.address.state}
            onChange={handleAddressChange}
            required
          />
        </div> 
        <div className="mb-3 col-md-6">
          <label htmlFor="city" className="form-label">
            City
          </label>
          <input
            type="text"
            className="form-control"
            id="city"
            name="city"
            placeholder="Enter City Name"
            value={formData.address.city}
            onChange={handleAddressChange}
            required
          />
        </div> 
        <div className="mb-3 col-md-6">
          <label htmlFor="postal_code" className="form-label">
            Postal Code
          </label>
          <input
            type="text"
            className="form-control"
            id="postal_code"
            name="postal_code"
            placeholder="Enter Postal Code"
            value={formData.address.postal_code}
            onChange={handleAddressChange}
            required
          />
        </div> 
        <div className="mb-3 col-md-6">
          <label htmlFor="dob_day" className="form-label">
            Date of Birth 
          </label>
          <input
            type="date"
            className="form-control"
            id="dob_day"
            name="month"
            placeholder="Enter day"
            value={moment(`${formData.dob.month}/${formData.dob.day}/${formData.dob.year}`).format("YYYY-MM-DD")}
            onChange={handleDOBChange}
            required
          />
        </div> 
        <div className="mb-3 col-md-6">
          <label htmlFor="company_name" className="form-label">
            Company Name
          </label>
          <input
            type="text"
            className="form-control"
            id="company_name"
            name="company_name"
            placeholder="Enter company name"
            value={formData.company_name}
            onChange={handleChange}
          />
        </div>

        <div className="mb-3 col-md-6">
          <label htmlFor="frontdoc" className="form-label">
            Front Document
          </label>
          {/* <label htmlFor='frontDoc'> */}
            
          {Images.frontImage!=""?<div className='front-doc'> <img width={100} height={100} src={`${environment.api}frontdoc/${Images.frontImage}`}/> <i className='fa fa-trash text-danger' onClick={e=>setImages({...Images,frontImage:""})} ></i> </div>:<input
            type="file"
            className="form-control "
            id="frontDoc"
            name="frontdoc"
            placeholder="Enter front document ID" 
            onChange={e=>ImageHandler(e,'frontImage','upload/front/image')}
          />}
          {/* </label> */}
        </div>

        <div className="mb-3 col-md-6">
          <label htmlFor="backdoc" className="form-label">
            Back Document
          </label>
          {Images.backImage!=""?<div className='front-doc'> <img width={100} height={100} src={`${environment.api}backdoc/${Images.backImage}`}/> <i className='fa fa-trash text-danger' onClick={e=>setImages({...Images,backImage:""})} ></i> </div>:<input
            type="file"
        className="form-control"
            id="backdoc"
            name="backdoc"
            placeholder="Enter back document ID"
            onChange={e=>ImageHandler(e,'backImage','upload/back/image')}
          />}
        </div>
        {Images.backImage!=""&&Images.frontImage!=""?<div className='col-md-12'>
{Loading? <div className="lds-hourglass"></div>:<button className='btn btn-primary' disabled={formData.backdoc!=""&&formData.frontdoc!=""} onClick={e=>HandlerVerify(e)}>{formData.backdoc!=""&&formData.frontdoc!=""?"Verified":"Verify Images"} </button>}
        </div>:null}
     
<div className='col-md-12  mt-md-4 mb-2 d-flex justify-content-end gap-2 mt-3'>
  <button className='btn btn-secondary' type='button' onClick={e=>Navigate.goBack()}>Back</button>
        <button type="submit" className="btn btn-primary ">
          Add Account
        </button>
        </div>
      </form>
      </div>
    </div>
  </>
  );
};

export default BankAccountAddForm;
