
import moment from "moment"
import environment from "../environment"

const isTranslatePage = () => {
    let value = false
    let url = window.location.href
    if (url.includes('translation')) value = true
    return value
}

const generatekeysArr = (arr, key = 'typeofresult') => {
    let keys = {}
    if (!arr) return { keys, arr: [] }
    arr.map(itm => {
        if (keys[itm[key]]) {
            keys[itm[key]].push(itm)
        } else {
            keys[itm[key]] = [itm]
        }
    })
    return {
        keys, arr: Object.keys(keys).map(itm => {
            return { key: itm, value: keys[itm] }
        })
    }
}

const userImg = (img, type = "") => {
    let value = type == "" ? '/assets/img/person.jpg' : type == "category" ? "/assets/img/unsplashgxgtqg5ul2g@2x.png" : "https://png.pngtree.com/element_our/20190601/ourmid/pngtree-file-upload-icon-image_1344393.jpg"
    if (img) value = environment.api + 'images/users/' + img
    return value
}

const noImage = (img) => {
    let value = '/assets/img/noimag.jpg'
    if (img) value = environment.api + 'images/users/' + img
    return value
}

const noImg = (img, modal = 'blogs') => {
    let value = '/assets/img/placeholder.png'
    if (img) value = environment.api + 'img/' + img
    return value
}

const getPrams = (p) => {
    const params = new URLSearchParams(window.location.search)
    return params.get(p)
}


const isNumber = (e) => {
    let key = e.target;
    let maxlength = key.maxLength ? key.maxLength : 1;

    let max = Number(key.max ? key.max : key.value);
    if (Number(key.value) > max) key.value = max;

    // let min = key.min;
    // if (min && Number(key.value)<Number(min)) key.value = min;

    if (key.value.length > maxlength) key.value = key.value.slice(0, maxlength);
    key.value = key.value.replace(/[^0-9.]/g, '').replace(/(\..*?)\..*/g, '$1');

    return key.value
}

const InputNumber = ($event) => {
    let keyCode = ($event.keyCode ? $event.keyCode : $event.which);
    if ((keyCode < 48 || keyCode > 57)) { // 46 is dot
        $event.preventDefault();
    }
}

const isRatio = (e) => {
    let key = e.target;
    let maxlength = key.maxLength ? key.maxLength : 1;

    let max = Number(key.max ? key.max : key.value);
    if (Number(key.value) > max) key.value = max;

    // let min = key.min;
    // if (min && Number(key.value)<Number(min)) key.value = min;

    if (key.value.length > maxlength) key.value = key.value.slice(0, maxlength);
    key.value = key.value.replace(/[^0-9.>]/g, '').replace(/(\..*?)\..*/g, '$1');

    return key.value
}

const find = (arr, value, key = 'key') => {
    let ext = arr?.find(itm => itm[key] == value)
    return ext
}


/* ###################### Form Methods #########################  */

// get Single field error
const getError = (key, fvalue, formValidation) => {
    let ext = find(formValidation, key)
    let res = matchError(ext, fvalue)
    return res
}

const emailRequiredFor = (role) => {
    let value = false
    if (role == 'Clinic Admin' || role == 'Counsellor' || role == 'Owner' || role == 'admin') value = true
    return value
}


const validateUsername = (val) => {
    return /^(?=[a-zA-Z0-9._-]{8,20}$)(?!.*[_.-]{2})[^_.-].*[^_.-]$/.test(val);
}

const dialMatch = (val) => {
    let value = false
    value = val.match(/^(?=.*[0-9])(?=.*[+])[0-9+]{2,5}$/)
    return value
}
const emailvalidation = (val) => {
    if (/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(val)) {
        return true
    }
    return false
}
// match errors for fields
const matchError = (ext, fValue) => {

    let invalid = false
    let kValue = fValue[ext.key]
    let value = { minLength: false, maxLength: false, confirmMatch: false, required: false }
    if (ext.required) {
        if (!kValue || (!kValue.length && typeof kValue != 'object')) {
            invalid = true
        }
    }
    if (ext.minLength && kValue) {
        if (kValue.length < ext.minLength) value.minLength = true
    }
    if (ext.maxLength && kValue) {
        if (kValue.length > ext.maxLength) value.maxLength = true
    }
    if (ext.dialCode && kValue) {
        if (dialMatch(kValue)) {
            kValue.indexOf("+");
            if (kValue.indexOf("+") != 0) {
                value.dialCode = true
            }

        } else {
            value.dialCode = true
        }
    }

    if (ext.username && kValue) {
        if (!validateUsername(kValue)) value.username = true
    }

    if (ext.confirmMatch && kValue) {
        if (fValue[ext.confirmMatch[0]] != fValue[ext.confirmMatch[1]]) value.confirmMatch = true
    }

    let vArr = Object.keys(value)
    vArr.map(itm => {
        if (value[itm]) invalid = true
    })

    let res = { invalid: invalid, err: value }
    return res
}

// get form error (All Fields)
const getFormError = (formValidation, fvalue) => {
    let invalid = false
    formValidation.map(ext => {
        if (matchError(ext, fvalue).invalid) {
            invalid = true
        }
    })

    return invalid
}

/* ###################### Form Methods end #########################  */

const route = (route) => {
    localStorage.setItem('route', route)
    let el = document.getElementById('routerDiv')
    if (el) el.click()
}
//####################### For handling the email error ###############
const handleerror = (e) => {
    if (e.includes(".")) {
        const newtext = e.split(".");
        const textlength = newtext[1].length;
        if (textlength == 1) {
            return true;
        } else {
            // return false;
        }
    }
}

const handleerrorcahnge = (e) => {
    if (e == "") {
        return false;
    }
    else {
        return handleerror(e);
    }
}

//  For getting the Number Range 
const GetNumberRange = (data) => {
    if (data < 50) {
        if (data < 5) {
            return `Less than 5`
        }
        const quatioent = data / 5;
        const main = Math.trunc(quatioent);
        const startNumber = main * 5;
        const EndNumber = startNumber + 5;
        return `${startNumber} to ${EndNumber} Porposal`
    } else {
        return "More than 50 "
    }
}

//  For Calculating the AMount of Platform Fee According to Virtual Assistant Hourly Rate
const CalculatePercentAmount = (dollar, platformfee) => {
    let result = 0;
    const platformAmount = parseFloat(platformfee);
    const clientAmount = parseFloat(dollar);
    if (clientAmount <= 10) {
        return 2

    }

    if (clientAmount && clientAmount !== 0) {
        result = (platformAmount / 100 * clientAmount).toFixed(2);
    } else {
        result = 0.00;
    }

    return parseFloat(result);
};


//  For Converting seconds into Hours and Minutes 
const SecondsToHourConvertor = (d) => {
    d = Number(d);
    var h = Math.floor(d / 3600);
    var m = Math.floor(d % 3600 / 60);
    var s = Math.floor(d % 3600 % 60);

    var hDisplay = h > 0 ? h + (h == 1 ? " hr, " : " hrs, ") : "00hr:";
    var mDisplay = m > 0 ? m + (m == 1 ? " mn, " : " mn, ") : "00m";
    var sDisplay = s > 0 ? s + (s == 1 ? " s" : " s") : "";
    // return hDisplay +""+ mDisplay +""+ sDisplay; 
    return hDisplay + "" + mDisplay
}

//  This Function is made When the Time ZOne is different from ANother User Timezone so for fixing that we need that 
function convertTimezone(inputTime, sourceTimezone, targetTimezone) {
    // Create a dummy date with a fixed date and the input time in the source time zone
    const dummyDate = new Date(`2000-01-01T${inputTime}:00.000${sourceTimezone}`);

    // Get the UTC time of the dummy date
    const utcTime = new Date(dummyDate.toISOString());

    // Set the target time zone for the UTC time
    const targetDate = new Date(utcTime.toLocaleString('en-US', { timeZone: targetTimezone }));

    // Format the target time without the date
    const convertedTime = targetDate.toISOString().substr(11, 5);

    return convertedTime;
}

function HoursCalculatorFromNow(inputDate) {
    const inputDateTime = new Date(inputDate);

    const currentDateTime = new Date();

    const timeDifference = currentDateTime - inputDateTime;

    const hoursDifference = timeDifference / (1000 * 60 * 60);

    return hoursDifference;

}


const GetDayName = (dateInfo) => {
    const dateObject = new Date(dateInfo.year, dateInfo.month - 1, dateInfo.day); // Month is zero-based

    // Getting the day of the week (0 = Sunday, 1 = Monday, ..., 6 = Saturday)
    const dayOfWeek = dateObject.getDay();

    // Converting the numeric day of the week to a string
    const daysOfWeek = ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"];
    const dayName = daysOfWeek[dayOfWeek];

    return `${dayName}, ${moment(dateObject).format("MMM DD,YYYY")}`;

}


const formatNumbersinPremium = (number) => {
    if (number >= 1000000) {
        return (number / 1000000).toFixed(1) + 'M';
    } else if (number >= 1000) {
        return (number / 1000).toFixed(1) + 'K';
    } else {
        return Math.round(number / 10) * 10;
    }

}
function generateStarRating(rating) {
    if (typeof rating !== 'number' || rating > 5) {
        console.error('Invalid rating. Please provide a number between 0 and 5.');
        return null;
    }

    const fullStars = Math.floor(rating || 0);
    const halfStar = rating ? rating % 1 !== 0 : 0;
    const emptyStars = 5 - fullStars - (halfStar ? 1 : 0);

    const starElements = [];

    for (let i = 0; i < fullStars; i++) {
        starElements.push(<i key={i} className="fas fa-star text-warning"></i>);
    }

    if (halfStar) {
        starElements.push(<i key="half" className="fas fa-star-half-alt text-warning"></i>);
    }

    for (let i = 0; i < emptyStars; i++) {
        starElements.push(<i key={`empty-${i}`} className="far fa-star text-warning"></i>);
    }

    return <div>{starElements}</div>;
}


function getDayNameByDate(dateString) {
    // Parse the date string to obtain a Date object
    const dateArray = dateString.split('-');
    const day = parseInt(dateArray[0], 10);
    const month = parseInt(dateArray[1], 10) - 1; // Months are zero-based in JavaScript
    const year = parseInt(dateArray[2], 10);

    const dateObject = new Date(year, month, day);

    // Define an array of day names
    const dayNames = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];

    // Get the day index from the date object and return the corresponding day name
    const dayIndex = dateObject.getDay();
    const dayName = dayNames[dayIndex];

    return dayName;
}



function getCurrentWeekDates() {
    // Get the current date
    let currentDate = new Date();

    // Get the current day of the week (0 = Sunday, 1 = Monday, ..., 6 = Saturday)
    let currentDayOfWeek = currentDate.getDay();

    // Calculate the difference between the current day and the start of the week (considering Sunday as the first day)
    let difference = currentDayOfWeek - 0; // 0 for Sunday

    // Calculate the start date of the current week by subtracting the difference from the current date
    let startDate = new Date(currentDate);
    startDate.setDate(currentDate.getDate() - difference);

    // Calculate the end date of the current week by adding the remaining days until Saturday
    let endDate = new Date(currentDate);
    endDate.setDate(currentDate.getDate() + (6 - difference));

    // Format the dates if needed


    return { startDate, endDate };
}


function isDateDifferenceGreaterThanThreeMonths(inputDate) {
    // Parse the input date
    const providedDate = new Date(inputDate);

    // Get the current date
    const currentDate = new Date();

    // Calculate the difference in milliseconds
    const dateDifference = currentDate - providedDate;

    // Convert milliseconds to months (assuming 30 days per month for simplicity)
    const monthsDifference = dateDifference / (1000 * 60 * 60 * 24 * 30);

    // Check if the difference is greater than 3 months
    return monthsDifference > 3;
}


// Function to scan a message for personal contact information
function MessageScanner(message) {
    const emailRegex = /[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}/;
    const phoneRegex = /\b\d{3}[-.]?\d{3}[-.]?\d{4}\b/;
    if (emailRegex.test(message) || phoneRegex.test(message)) {
        return true; // Personal contact information detected
    }
    return false; // No personal contact information detected
}

const GetHourMinutesDates = (start_date, end_Date) => {
    var today = new Date(start_date);
    var Christmas = new Date(end_Date);
    var diffMs = (Christmas - today); // milliseconds between now & Christmas
    var diffDays = Math.floor(diffMs / 86400000); // days
    var diffHrs = Math.floor((diffMs % 86400000) / 3600000); // hours
    var diffMins = Math.round(((diffMs % 86400000) % 3600000) / 60000); // minutes
    if (diffHrs < 10) {
        diffHrs = "0" + diffHrs
    }
    if (diffMins < 10) {
        diffMins = "0" + diffMins
    }
    return diffHrs + ":" + diffMins + " hrs"
}



const ConvertTime = (p = '', type = 'time') => {
    if (type == 'time') {
        let timeString = p.split('T')[1];
        let dateData = moment.utc(timeString, 'HH:mm:ss.SSSZ').format('h:mm A');;
        if (dateData == 'Invalid date') {
            return '--'
        } else {
            return dateData
        }
    } else {
        return moment.utc(p, 'DD-MM-YYYYTHH:mm:ss.SSSZ').format('DD/MM/YYYY h:mm A');
    }
}

const convertDate = (p = '', format = "MM/DD/YYYY") => {
    let date = moment.utc(p, 'YYYY-MM-DDThh:mm:SSSZ').format(format);
    if (date == 'Invalid date') {
        return '--'
    } else {
        return date
    }
}


// function formatToTimezone(dateString, timeZone) {
//     const date = new Date(dateString); // Create a Date object from the UTC string

//     // Use Intl.DateTimeFormat to format the date to the desired timezone
//     const options = {
//         timeZone: timeZone,
//         year: 'numeric',
//         month: '2-digit',
//         day: '2-digit',
//         hour: '2-digit',
//         minute: '2-digit',
//         second: '2-digit',
//     };

//     const formatter = new Intl.DateTimeFormat([], options);
//     return formatter.format(date);
// }


function formatToTimezone(dateString, timeZone) {
    // Create a DateTime object in UTC
    const utcDate = DateTime.fromISO(dateString, { zone: 'utc' });

    // Convert to the desired timezone
    const localDate = utcDate.setZone(timeZone);

    // Format the date
    return localDate.toFormat('yyyy-MM-dd HH:mm:ss');
}
const GetTimeZoneValue = () => {
    let formatter = new Intl.DateTimeFormat('en-US', { timeZoneName: 'long' });
    let parts = formatter?.formatToParts(new Date());

    let timeZone = parts?.find(part => part?.type === 'timeZoneName').value;
    return Intl.DateTimeFormat().resolvedOptions().timeZone
}
const methodModel = { GetTimeZoneValue, convertDate, ConvertTime, MessageScanner, GetHourMinutesDates, isDateDifferenceGreaterThanThreeMonths, getCurrentWeekDates, getDayNameByDate, generateStarRating, formatNumbersinPremium, GetDayName, HoursCalculatorFromNow, convertTimezone, SecondsToHourConvertor, CalculatePercentAmount, GetNumberRange, InputNumber, handleerrorcahnge, userImg, route, noImage, isNumber, isRatio, find, getError, getFormError, getPrams, emailRequiredFor, emailvalidation, noImg, isTranslatePage, generatekeysArr }
export default methodModel