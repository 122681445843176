import { Link } from "react-router-dom";
export default function Footer() {
  return (
    <footer className="footer">
      <div className="container-fluid px-md-5 px-2">
        <div className="footer-top">
          <div className="  row ">
            <div className="col-lg-4 ">
              <div className="text6">
                <div className="flex-col">
                  <img
                    loading="lazy"
                    width={"100%"}
                    height={"100%"}
                    className="myvirtualpalLogo151Icon1"
                    alt=""
                    src="/assets/img/myvirtualpallogo15-11@2x.png"
                  />
                  <div className="inTheFastPacedContainer">
                    <p className="mb-0">
                      In the fast-paced world of modern technology,
                    </p>
                    <p className="">
                      many leadership executives and organizations understand
                      that building.
                    </p>
                  </div>
                </div>
                <div className="about mt-3">
                  <div className="socialIcon mb-3">
                    <div className="twitter">
                      <div className="iconBorder">
                        <div className="twitter1"></div>
                      </div>
                    </div>
                    {/* <div className="dribbble">
                              <div className="iconBorder2">
                                  <div className="dribbble1"></div>
                              </div>
                          </div> */}
                    <a
                      href="https://www.facebook.com/profile.php?id=61550775106972"
                      target="_new"
                    >
                      <div className="fb mr-2">
                        <div className="iconBorder">
                          <div className="fb1"></div>
                        </div>
                      </div>
                    </a>
                    <a
                      href="https://www.instagram.com/myvirtualpalinc?igsh=OGQ5ZDc2ODk2ZA%3D%3D&utm_source=qr"
                      target="_new"
                    >
                      <div className="fb">
                        <div className="iconBorder">
                          <i class="fa-brands fa-instagram instap"></i>
                        </div>
                      </div>
                    </a>
                  </div>
                </div>
              </div>
            </div>

            <div className="col-lg-8">
              <div className="row">
                <div className="text6 col-md-4">
                  <div className="widgetTitle1">
                    <div className="quickLinks">Quick Links</div>
                  </div>
                  <div className="text5">
                    <div className="learnersLeadershipPartnersContainer">
                      {/* <p className="leadership">Career</p> */}

                      <Link
                        className="leadership text-white"
                        to="/content/about-us"
                      >
                        About Us
                      </Link>

                      <p className="m-0">
                        {" "}
                        <Link
                          className="leadership text-white"
                          to="/content/term-conditions"
                        >
                          Term and Conditions
                        </Link>
                      </p>
                      <p className="m-0">
                        <Link
                          className="leadership text-white"
                          to="/content/privacy-policy"
                        >
                          Privacy Poilcy
                        </Link>
                      </p>
                      <Link to="/contact-us">
                      <p className="leadership text-white">Contact US</p>
                      </Link>
                    </div>
                  </div>
                </div>
                <div className="text6 col-md-4">
                  <div className="widgetTitle1">
                    <div className="quickLinks">Community</div>
                  </div>
                  <div className="text4">
                    <div className="learnersLeadershipPartnersContainer">
                      <p className="leadership">
                        {" "}
                        <Link className="leadership text-white" to="/mission">
                          Mission
                        </Link>
                      </p>
                      <p className="leadership">Leadership</p>
                      <p className="leadership">Partners</p>
                      <p className="leadership">Developers</p>
                    </div>
                  </div>
                </div>
                <div className="text6 col-md-4">
                  <div className="widgetTitle1"></div>
                  <div className="text3">
                    <div className="signUpAnd">
                      Sign up and receive the latest tips via email.
                    </div>
                  </div>
                  <div className="inputField">
                    <div className="border">
                      <img
                        loading="lazy"
                        className="mailIcon mr-2"
                        width={"100%"}
                        height={"100%"}
                        alt=""
                        src="/assets/img/mail.svg"
                      />
                      <div className="enterYourMail">
                        <input
                          className="enterEmail"
                          placeholder="Enter your email"
                        />
                      </div>
                    </div>
                  </div>
                  {/* <div className="subscribeButton mt-3">
                      <div className="btnBg">
                          <div className="subscribeNow">Subscribe Now</div>
                          <img
                              loading="lazy"
                              width={"100%"}
                              height={"100%"}
                              className="paperPlane1Icon ml-2"
                              alt=""
                              src="/assets/img/paperplane-1.svg"
                          />
                      </div>
                  </div> */}
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="footerBottom">
          <div className="footerBottomChild" />
          <div className="allRightsReserved">© 2024 All Rights Reserved</div>
        </div>
      </div>
    </footer>
  );
}
