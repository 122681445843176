import { useEffect, useState } from "react";
import { Select } from "antd";
import loader from "../../../methods/loader";
import ApiClient from "../../../methods/api/apiClient";

const Html = ({
  form,
  handleSubmit,
  setform, 
  
}) => {
  const [DegreeData,setDegreeData]=useState([]);
  const [StudyAreaData,setStudyAreaData]=useState([]);

  const GetDegreeData=(p={})=>{
    loader(true);
    let array=[]
    ApiClient.get(`degrees`,p).then(res=>{
      if(res.success){
  if(res.success){
    const data=res.data;
    data.map((item,index)=>{
      array.push({value:item.name,label:item.name})
    })
    setDegreeData(array);
    loader(false)
  }
      }
    })
  }

  const GetStudyAreaData=()=>{
    loader(true);
    let array=[]
    ApiClient.get(`areaofstudy`).then(res=>{
      if(res.success){
        const data=res.data;
        data.map((item,index)=>{
          array.push({value:item.name,label:item.name})
        })
   setStudyAreaData(array);
      }
    })
  }
  useEffect(()=>{
  GetDegreeData();
  GetStudyAreaData();
  },[])
  return (
    <>
      <>
        <form onSubmit={handleSubmit}>
          <div className="">
            {/* <h3 className="ViewUser mb-2">
              {form && form.id ? "Edit" : "Add"} Education
            </h3> */}
            <div className="form-row">
              <div className="col-md-6 mb-3">
                <label>
                Institution/school<span className="star">*</span>
                </label>
                <input
                  type="text"
                  className="form-control"
                  value={form.school}
                  onChange={(e) =>
                    setform({ ...form, school: e.target.value })
                  }
                  required
                />
              </div>

         

              <div className="col-md-6 mb-3">
                <label>
                StudyArea
                </label>
                <br/>
                <Select 
                showSearch
                allowClear 
                value={form.studyArea}
                onSelect={(e) =>{ setform({ ...form, studyArea: e});}}
                filterOption={(input, option) =>
                  option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0
                }
                options={StudyAreaData}
                
                className="marginleft"
                required
                />
             
              </div>

              <div className="col-md-6 mb-3">
                <label>
                  Start Date<span className="star">*</span>
                </label>
                <input
                  type="date"
                  className="form-control"
                  value={form.startDate}
                  onChange={(e) =>
                    setform({ ...form, startDate: e.target.value })
                  }
                  required
                />
              </div>
         
          
                <div className="col-md-6 mb-3">
                  <label>
                    End Date<span className="star">*</span>
                  </label>
                  <input
                    type="date"
                    className="form-control"
                    value={form.endDate}
                    min={form.startDate}
                    onChange={(e) =>
                      setform({ ...form, endDate: e.target.value })
                    }
                    required
                  />
                </div>
              
                <div className="col-md-12 mb-3">
                <label>
                  Degree
                </label>
              <br/>

                <Select 
                showSearch
                allowClear 
                value={form.degree}
                onSelect={(e) =>{ setform({ ...form, degree: e});}}
                filterOption={(input, option) =>
                  option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0
                }
                
                options={DegreeData}
                
                className="marginleft"
                />
     
              </div>  
              <div className="col-md-12 mb-3">
                <label>
                  Description
                </label>
                <textarea
                  className="form-control"
                  value={form.description}
                  onChange={(e) =>
                    setform({ ...form, description: e.target.value })
                  }
                  // required
                />
              </div> 
            </div>

            <div className="text-right mt-3">
              <button
                type="button"
                id="closeeducationmodal"
                className="btn btn-secondary discard mr-2" 
                data-dismiss="modal" aria-label="Close"
              >
                Back
              </button>
              <button type="submit" className="btn btn-primary">
                Save
              </button>
            </div>
          </div>
        </form>
      </>
    </>
  );
};

export default Html;
