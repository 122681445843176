import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import ApiClient from "../../methods/api/apiClient";
import { useSelector } from "react-redux";
import "react-phone-input-2/lib/style.css";
import loader from "../../methods/loader";
import moment from "moment";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import environment from "../../environment";

const Invite = () => {
  const [ShowFilters, setShowFilters] = useState(false);
  const [MinDollar, setMinDollar] = useState("");
  const [MaxDollar, setMaxDollar] = useState("");
  const user = useSelector((state) => state.user);
  const Navigate = useHistory();
  const [SearchData, setSearchData] = useState("");
  const [Preview, setPreview] = useState(false);
  const [filters, setfilters] = useState({
    page: 1,
    status: "pending",
    count: 50,
    search: "",
    employerId:
      user.role && user.role.id == environment.VirtualId ? "" : user.id,
    freelancerId:
      user.role && user.role.id == environment.VirtualId ? user.id : "",
    status: "",
    projectId: "",
    skills: [],
    budget: "",
    hourlyratestart: "",
    hourlyrateEnd: ""
  });
  const [total, settotal] = useState(0);
  const [View, setView] = useState(false);
  const [Edit, setEdit] = useState(false);
  const [Editid, setEditid] = useState("");
  const [SelectedSkills, setSelectedSkills] = useState([]);
  const [SubmittedError, setSubmittedError] = useState(false);

  const [ProjectsData, setProjectsData] = useState([]);
  const GetAllProjects = (p = {}) => {
    const newfilters = {
      ...filters,
      ...p,
      status: "pending",
      skills: p.skills
        ? p.skills.map((item) => item.id).toString()
        : filters.skills.map((item) => item.id).toString(),
    };
    loader(true);
    ApiClient.get(`invites`, newfilters).then((res) => {
      if (res.success) {
        setProjectsData(res.data);
        settotal(res.total);
      }
      loader(false);
    });
  };
  useEffect(() => {
    GetAllProjects();
  }, []);

  const HandleProjectDelete = (id) => {
    if (window.confirm("Do you want to Delete this?")) {
      loader(true);
      ApiClient.delete(`invites?id=${id}`).then((result) => {
        if (result.success) {
          GetAllProjects();
        }
        loader(false);
      });
    }
  };
  const pageChange = (e) => {
    setfilters({ ...filters, page: e });
    GetAllProjects({ page: e });
  };
  const HandleSearchSubmit = (e) => {
    e.preventDefault();
    if (SearchData == "") {
    } else {
      setfilters({ ...filters, search: SearchData });
      GetAllProjects({ search: SearchData });
    }
  };

  const HandleEmptySearch = (e) => {
    e.preventDefault();
    setSearchData("");
    setfilters({ ...filters, search: "" });
    GetAllProjects({ search: "" });
  };

  const HandleAcceptRejectInvite = (e, id, status) => {
    e.preventDefault();
    loader(true);
    const payload = { id: id, status: status };
    ApiClient.put(`accept/reject/invite`, payload).then((res) => {
      if (res.success) {
        GetAllProjects();
        toast.success(res.message);
      }
    });
  };

  const [ProjectList, setProjectList] = useState([]);
  const ProjectListing = () => {
    loader(true);
    ApiClient.get(`projects?userId=${user.id}`).then((res) => {
      if (res.success) {
        setProjectList(res.data);
      }
      loader(false);
    });
  };
  useEffect(() => {
    ProjectListing();
  }, []);

  const [SkillsData, setSkillsData] = useState([]);
  const GetAllSkills = () => {
    loader(true);
    const StrictArray = [];
    ApiClient.get(`skills?status=active`).then((res) => {
      if (res.success) {
        const data = res.data;
        data.map((item, index) => {
          StrictArray.push({ name: item.name, id: item.id });
        });
        setSkillsData(StrictArray);
      }
      loader(false);
    });
  };

  useEffect(() => {
    GetAllSkills();
  }, []);

  const Employerid = environment.EmployerId;
  const VirtualRoleId = environment.VirtualId;



  const [ViewData, setViewData] = useState({});


  const GetPostedTime = (date) => {
    const result = moment(date).fromNow()
    // const data = new Date(date).getMilliseconds();
    // const result = moment.utc(new Date(date)).local().startOf("date").fromNow();
    if (result == "an hour ago") {
      return "1hr ago";
    } else {
      return result;
    }
  };
  return (
    <>
      <h4 className="p-3 h3font">Pending Invitation ({ProjectsData.length})</h4>
      {/* <Header /> */}
      {ProjectsData.map((item) => (
        <div key={item.id} className="p-3 border-top d-flex align-items-center new-wrap">
          <div className="request mr-5">
            <p className="mb-0">Received {moment(item.createdAt).format("MM/DD/YYYY")}</p>
            <small>{GetPostedTime(item.createdAt)}</small>
          </div>
          <p className="bold_msg text-primary" style={{ cursor: "pointer" }} onClick={e => Navigate.push(`/proposaldetail/${item.id}`)}>{item.project_details && item.project_details.name || item.project_details.title}</p>
        </div>
      ))}

    </>
  );
};

export default Invite;
