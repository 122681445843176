import { useEffect, useState } from 'react';
import Header from '../../components/global/header'
import loader from '../../methods/loader';
import ApiClient from '../../methods/api/apiClient';
import dayjs from 'dayjs';
import { DatePicker } from 'antd';
const { RangePicker } = DatePicker;
export default function RecruiterDashboard() {
  const [Data, setData] = useState({});
  const [filters, setfilters] = useState({});
  const [employerdata, setemployerData] = useState({})
  //  FILTER DATE STATES AND FUNCTION
  const [SelectedDateRange, setSelectedDateRange] = useState(['', ''])
  const handleDateChange = (date, dateStrings) => {
    setSelectedDateRange(date);
    let dates = dateStrings;
    FilterHandler({ start_date: dates[0] || "", end_date: dates[1] || "" })
  };
  // FILTER DATE FUNCTION ENDED 
  const GetDashboardData = (p = {}) => {
    const filter = { ...filters, ...p }
    loader(true)
    ApiClient.get(`referal/count`, filter).then(res => {
      if (res.success) {
        setData(res);
      }
      loader(false)
    })
  }


  const Getemployerdata = (p = {}) => {
    const filter = { ...filters, ...p }
    ApiClient.get("virtual-referal/count", filter).then((res) => {
      setemployerData(res)
    })
  }
  useEffect(() => {
    GetDashboardData()
    Getemployerdata()
  }, [])
  const FilterHandler = (p = {}) => {
    setfilters({ ...filters, ...p });
    GetDashboardData(p)
    Getemployerdata(p)
  }
  return (
    <div>
      <Header />
      <div className='container mt-4'>
        <div className='row'>
          <div className='col-md-12'>
            <div className='mb-3'>
              <h2> Dashboard</h2>
            </div>
            <div className='col-lg-6 mb-3 p-0 col-md-5'>
              <label className='mt-2'>Date Filters </label>
              <RangePicker
                className="dateRangePicker"
                placeholder={["Start Date", "End Date"]}
                format="YYYY-MM-DD"
                onChange={handleDateChange}
                // onBlur={e=>setblur(true)} 
                value={SelectedDateRange}
              />
            </div>
          </div>
<div className="col-md-12">
  <h3 className='referrals'>Referrals to Virtual Assistants
  </h3>
</div>
          <div className="col-sm-6 col-md-4 col-lg-3 mb-3">
            <div className="Sales_row card">
              <p>Total Invited</p>
              <div className=""><h2>{Data?.invitedCount}</h2>
                <p className="mb-0">

                </p></div></div></div>
          <div className="col-sm-6 col-md-4 col-lg-3 mb-3">
            <div className="Sales_row card">
              <p>Registered Virtual Assistant</p>
              <div className=""><h2>{Data.registeredUser}</h2>
                <p className="mb-0">
                </p></div></div></div>

          <div className="col-sm-6 col-md-4 col-lg-3 mb-3">
            <div className="Sales_row card">
              <p>Onboard Virtual Assistant</p>
              <div className=""><h2>{Data.onboardUsers}</h2>
                <p className="mb-0">

                </p></div></div></div>


          <div className="col-sm-6 col-md-4 col-lg-3 mb-3">
            <div className="Sales_row card">
              <p>Total Commission</p>
              <div className=""><h2>{Data?.commissionAmount ? Data?.commissionAmount : 0}USD </h2>
                <p className="mb-0">
                </p></div></div></div>
        </div>
      </div>

      <div className='container mt-4'>
        <div className='row'>
<div className='col-md-12'>
  <h3 className='referrals'>Referrals to Employers
</h3>
</div>
          <div className="col-sm-6 col-md-4 col-lg-3 mb-3">
            <div className="Sales_row card">
              <p>Total Invited</p>
              <div className=""><h2>{employerdata?.invitedCount}</h2>
                <p className="mb-0">

                </p></div></div></div>
          <div className="col-sm-6 col-md-4 col-lg-3 mb-3">
            <div className="Sales_row card">
              <p>Registered Employer</p>
              <div className=""><h2>{employerdata.registeredUser}</h2>
                <p className="mb-0">
                </p></div></div></div>

          <div className="col-sm-6 col-md-4 col-lg-3 mb-3">
            <div className="Sales_row card">
              <p>Onboard Employer</p>
              <div className=""><h2>{employerdata.onboardUsers}</h2>
                <p className="mb-0">

                </p></div></div></div>


          <div className="col-sm-6 col-md-4 col-lg-3 mb-3">
            <div className="Sales_row card">
              <p>Total Commission</p>
              <div className=""><h2>{employerdata?.commissionAmount ? employerdata?.commissionAmount : 0} USD </h2>
                <p className="mb-0">
                </p></div></div></div>
        </div>
      </div>



    </div>
  )
}
