/*********** Reduceres defined here *********/
/*
 * @Author: Ghost 
 * @Email: Ghost@yopmail.com
 * @Date: 2024-02-13 11:30:23 
 * @Last Modified by: Someone
 * Email: anilgodara4239@gmail.com
 * @Last Modified time: 2024-02-13 11:31:46
 * */
import { persistCombineReducers } from 'redux-persist';
import storage from 'redux-persist/es/storage'; // default: localStorage if web, AsyncStorage if react-native
import { connectRouter } from 'connected-react-router';

// reducers
import user from './modules/user';
import loader from './modules/loader';
import search from './modules/search';
import MessageCount from './modules/MessageCount'
import TimeTrackingReducer from './modules/TimeTrackingReducer'
import { createBrowserHistory } from 'history';
import  PlatformfeeReducer from './modules/Platformfee';

/*********** History function **************/
export const history = createBrowserHistory();

const userPersistConfig = {
    key: 'admin-app',
    storage: storage,
    blacklist: ['router', 'loader'],
};

export default persistCombineReducers(userPersistConfig, {
    router: connectRouter(history),
    loader,
    user,
    search,
    MessageCount,
    TimeTrackingReducer,
    PlatformfeeReducer
    
});
