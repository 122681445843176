/*
 * @Author: Ghost 
 * @Email: Ghost@yopmail.com
 * @Date: 2024-02-13 11:30:23 
 * @Last Modified by: Someone
 * Email: anilgodara4239@gmail.com
 * @Last Modified time: 2024-02-20 12:36:54
 * */
import { useEffect, useState } from 'react'
import Header from '../../components/global/header'
import ApiClient from '../../methods/api/apiClient'
import loader from '../../methods/loader';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import "react-datepicker/dist/react-datepicker.css";
import { DatePicker } from 'antd';
import axios from 'axios';
import environment from '../../environment';
const { RangePicker } = DatePicker;
export default function RecruiterReport() {
    const user = useSelector(state => state.user);
    const [filters, setfilters] = useState({ name: "", start_date: "", end_date: "", employerId: "", contractId: "", recruiterId: user.id, virtualId: "" });
    const Navigate = useHistory();
    const [VAList, setVAList] = useState([]);
    const [Data, setData] = useState([]);
    const GetReports = (p = {}) => {
        const NewFilters = { ...filters, ...p }
        loader(true);
        ApiClient.get(`recruiterReport`, NewFilters).then(res => {
            if (res.success) {
                setData(res?.data);
            }
            loader(false);
        })
    }


    const GetVALIST = () => {
        loader(true);
        ApiClient.get(`referalList?roleId=${environment.VirtualId}`).then(async (res) => {
            if (res.success) {
                const newdata = res.data;
                setVAList(newdata)
            }
            loader(false)
        })
    }
    useEffect(() => {
        GetVALIST()
        GetReports()
    }, [])


    const [SelectedDateRange, setSelectedDateRange] = useState(null)

    const handleDateChange = (date, dateStrings) => {
        setSelectedDateRange(date);
        let dates = dateStrings;
        setfilters({ ...filters, start_date: dates[0] || "", end_date: dates[1] || "" })
        GetReports({ start_date: dates[0] || "", end_date: dates[1] || "" })
        // You can perform additional filtering or other actions based on the selected date range
    };

    const [EmployerList, setEmployerList] = useState([]);
    const DownloadVirtualReport = async (e) => {
        e.preventDefault();
        const token = await localStorage.getItem("token");
        const req = await axios({
            method: "get",
            url: `${environment.api}exportRecruiterReport?recruiterId=${user.id}`,
            responseType: "blob",
            body: { token: token }
        });
        var blob = new Blob([req.data], {
            type: req.headers["content-type"],
        });
        const link = document.createElement("a");
        link.href = window.URL.createObjectURL(blob);
        link.download = `Reports.xlsx`;
        link.click();


    }

    const GetEmployers = () => {
        loader(true);
        ApiClient.get(`referalList?roleId=${environment.EmployerId}`).then(async (res) => {
            if (res.success) {
                const newdata = res.data;
                setEmployerList(newdata)
            }
            loader(false)
        })
    }
    useEffect(() => {
        GetEmployers()
    }, [])
    return (
        <>
            <Header />
            <div className='container mt-5 '>
                <div className='d-flex '>
                    <div className=''>
                        <h3> Reports</h3>
                    </div>
                    <br />
                </div>
                <div className='d-flex gap-3  '>
                    <div>
                        <label>Date Filters </label>
                        <RangePicker
                            className="dateRangePicker"
                            placeholder={["Start Date", "End Date"]}
                            format="MM-DD-YYYY"
                            onChange={handleDateChange}
                            // onBlur={e=>setblur(true)} 
                            value={SelectedDateRange}
                        />
                    </div>
                    <div className=''>
                        <label>VA  </label>
                        <div className="dropdown">
                            <button className="btn btn-secondary dropdown-toggle" type="button" data-bs-toggle="dropdown" aria-expanded="false">
                                {filters.virtualId == "" ? "Select VA" : VAList.find((item) => item._id == filters.virtualId).fullName}
                            </button>
                            <ul className="dropdown-menu">
                                {VAList.map((item) => (<li><a className="dropdown-item" onClick={e => { setfilters(prev => ({ ...filters, virtualId: item?._id })); GetReports({ virtualId: item?._id }) }}>{item?.fullName}</a></li>))}

                            </ul>
                        </div>
                    </div>
                    <div className=''>
                        <label>Employer </label>
                        <div className="dropdown">
                            <button className="btn btn-secondary dropdown-toggle" type="button" data-bs-toggle="dropdown" aria-expanded="false">
                                {filters.employerId == "" ? "Select Employer" : EmployerList.find((item) => item._id == filters.employerId).fullName}
                            </button>
                            <ul className="dropdown-menu">
                                {EmployerList.map((item) => (<li><a className="dropdown-item" onClick={e => { setfilters(prev => ({ ...filters, employerId: item?._id })); GetReports({ employerId: item?._id }) }}>{item?.fullName}</a></li>))}

                            </ul>
                        </div>
                    </div>

                    <div className='mt-4'>
                        {filters.employerId != "" || filters.virtualId != "" || filters.start_date || filters.end_date ? <button className='btn btn-primary' onClick={() => { setfilters({ employerId: "", end_date: "", start_date: "", contractId: "", name: "", virtualId: "" }); setSelectedDateRange(null); GetReports({ employerId: "", end_date: "", start_date: "", contractId: "", name: "", virtualId: "" }) }}>Clear</button> : null}
                    </div>
                </div>
                <button className='btn btn-primary' style={{ float: "right", marginTop: "-47px" }} onClick={e => DownloadVirtualReport(e)}>Export</button>

                <div className="table-responsive table_section">
                    <table className='table table-striped'>
                        <thead className='table_head'>
                            <tr className='heading_row'>
                                <th scope="col" className='table_data'>Contract Name</th>
                                <th className='table_data'>Recruiter Name</th>
                                <th scope="col" className='table_data'>Employer Name</th>
                                <th scope="col" className='table_data'>Employer Amount</th>
                                <th className='table_data'>Employer Commission</th>
                                <th scope="col" className='table_data'>VA Name</th>
                                <th scope="col" className='table_data'>VA Amount</th>
                                <th className='table_data'>VA Commission</th>
                                <th className='table_data'>Total Commission</th>
                                <th>Total Profit</th>
                                <th>Net Profit</th>
                            </tr>
                        </thead>
                        <tbody>
                            {Data.map((item, index) => (<tr className='data_row'>
                                <td className='text-capitalize'>{item?.projectsDetail?.title}</td>
                                <td className='text-capitalize'>{item?.recruiterDetail[0].fullName}</td>
                                <td className='text-capitalize'>{item?.employerDetail[0]?.fullName}</td>
                                <td>{parseFloat(item?.total_emp_amount).toFixed(2)} USD</td>
                                <td className=''>{parseFloat(item?.employerReferalCommission).toFixed(2)} USD</td>
                                <td className='text-capitalize'>{item?.virtualDetail[0].fullName}</td>
                                <td>{parseFloat(item?.total_VA_amount).toFixed(2)} USD</td>
                                <td className=''>{parseFloat(item?.virtualReferalCommission).toFixed(2)} USD</td>
                                <td>{parseFloat(item?.totalCommission).toFixed(2)} USD</td>
                                <td>{parseFloat(item?.grossProfit).toFixed(2)} USD</td>
                                <td>{parseFloat(item?.netprofit).toFixed(2)} USD</td>

                            </tr>))}
                        </tbody>
                    </table>
                    {Data.length == 0 ? <div className='text-danger text-center'>No Active contract to view the Reports.</div> : null}

                </div>

            </div>
        </>
    )
}