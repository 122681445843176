import { useEffect, useState } from "react";
import { useParams } from "react-router-dom/cjs/react-router-dom.min";
import loader from "../../methods/loader";
import ApiClient from "../../methods/api/apiClient";
import parse from "html-react-parser";
import Header from "../../components/global/header";

export default function Missions() {
  //   const {slug}=useParams();
  // const [Data,setData]=useState({})
  //   const GetData=()=>{
  //       loader(true);
  //       ApiClient.get(`content?slug=${slug}`).then(res=>{
  //           if(res.success){
  //               setData(res.data);
  //           }
  //           loader(false)
  //       })
  //   }
  //   useEffect(()=>{
  // GetData()
  //   },[])
  return (
    <div>
      <Header />

      <div>
        {/* <h2 className="text-capitalize mt-3 text-center mt-5 mb-4 missohead">Our Mission</h2> */}
        <div className="container  ">
          <h2 className="text-capitalize mt-5 missohead">Our Mission</h2>
          <p className="missionp">
            To efficiently and effectively connect highly skilled virtual
            assistants with companies in the United States, providing innovative
            solutions that optimize productivity, improve operational
            efficiency, and foster business growth. We are committed to being
            the bridge that facilitates collaboration between remote talent and
            organizations, driving the evolution of work towards a more
            flexible, inclusive, and globally connected model.
          </p>

          <h2 className="text-capitalize mt-5 missohead">Our Vision</h2>
          <p className="missionp">
          We
            envision ourselves as leaders in transforming the labor landscape,
            recognized as the primary facilitator of connections between highly
            competent virtual assistants and companies in the United States. We
            seek to significantly expand our platform, creating a dynamic
            ecosystem where remote collaboration is the norm and where the
            quality of virtual work is indisputable. We aspire to be agents of
            change in the way work is performed, promoting widespread adoption
            of flexible and remote work models that foster efficiency,
            inclusion, and growth for both companies and professionals.
          </p>
          <h2 className="text-capitalize mt-5 missohead">Values</h2>
          <div className="row missiondiv mt-5 pt-4">
     
            <div className="col-md-6">
              <div>
                <img src="/assets/img/exc.jpg" className="misimg" />
              </div>
            </div>
            <div className="col-md-6">
              <p className="missionheader mb-0">Excellence </p>
              <p className="missiondesc m-0">
                {" "}
                We are committed to providing services of the highest quality,
                constantly striving for excellence in everything we do, from
                talent selection to delivering solutions to our clients.
              </p>
            </div>
          </div>

          <div className="row missiondiv rowreverse">
            <div className="col-md-6">
              <p className="missionheader mb-0">Transparency</p>
              <p className="missiondesc m-0">
                {" "}
                We act with integrity and transparency in all our operations,
                establishing relationships based on mutual trust with our
                virtual assistants and clients.
              </p>
            </div>
            <div className="col-md-6 text-md-end">
              <div>
                <img src="/assets/img/trans.jpg" className="misimg" />
              </div>
            </div>
          </div>

          <div className="row missiondiv">
            <div className="col-md-6">
              <div>
                <img src="/assets/img/colb.jpeg" className="misimg" />
              </div>
            </div>
            <div className="col-md-6">
              <p className="missionheader mb-0">Collaboration</p>
              <p className="missiondesc m-0">
                {" "}
                We believe in the power of collaboration and foster an
                environment where teamwork and cooperation are essential to
                achieving success for both our virtual assistants and the
                companies we work with.
              </p>
            </div>
          </div>

          <div className="row missiondiv rowreverse">
            <div className="col-md-6">
              <p className="missionheader mb-0">Innovation</p>
              <p className="missiondesc m-0">
                {" "}
                We are dedicated to the constant pursuit of new ideas and
                approaches to improve our services and adapt to a constantly
                changing business environment, driving innovation in all facets
                of our company.
              </p>
            </div>
            <div className="col-md-6 text-md-end">
              <div>
                <img src="/assets/img/inn.jpg" className="misimg" />
              </div>
            </div>
          </div>

          <div className="row missiondiv">
            <div className="col-md-6">
              <div>
                <img src="/assets/img/emp.png" className="misimg" />
              </div>
            </div>
            <div className="col-md-6">
              <p className="missionheader mb-0">Empowerment</p>
              <p className="missiondesc m-0">
                {" "}
                We value and respect the diversity of skills and experiences of
                our virtual assistants, and empower them by providing the tools
                and support necessary to reach their full professional
                potential.{" "}
              </p>
            </div>
          </div>

          <div className="row missiondiv rowreverse">
            <div className="col-md-6">
              <p className="missionheader mb-0">Responsibility</p>
              <p className="missiondesc m-0">
                {" "}
                We are socially and environmentally responsible, committing to
                act sustainably and ethically in all our operations and business
                decisions.
              </p>
            </div>
            <div className="col-md-6 text-md-end">
              <div>
                <img src="/assets/img/res.png" className="misimg" />
              </div>
            </div>
          </div>

          <div className="row missiondiv">
            <div className="col-md-6">
              <div>
                <img src="/assets/img/flex.png" className="misimg" />
              </div>
            </div>
            <div className="col-md-6">
              <p className="missionheader mb-0">Flexibility</p>
              <p className="missiondesc m-0">
                {" "}
                We recognize the importance of flexibility in remote work and
                strive to offer adaptable solutions that meet the changing needs
                of our clients and virtual assistants.{" "}
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
