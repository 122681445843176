/*
 * @Author: Ghost 
 * @Email: Ghost@yopmail.com
 * @Date: 2024-03-21 11:15:47 
 * @Last Modified by:   Ghost 
 * @Last Modified time: 2024-03-21 11:15:47
 * @Description: file:///home/jc-21/Desktop/MyVirtualPal/marketplace-frontend/src/pages/ChangesAcceptinTIme_Schedule/TimeScheduleChange.js
 */

import { useEffect, useState } from 'react';
import Header from '../../components/global/header'
import ApiClient from '../../methods/api/apiClient';
import { useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom/cjs/react-router-dom.min';
import { toast } from 'react-toastify';
import loader from '../../methods/loader';
import environment from '../../environment';

export default function TimeScheduleChange({contractid,type=true}) {
    const history=useHistory()
    const user=useSelector(state=>state.user);
    const {id}=useParams();
    const [TimeTableSchedule,setTimeTableSchedule]=useState([]);
    const [EmployerData,setEmployerData]=useState({});
    const [VAData,setVAData]=useState({});
    const [ProjectData,setProjectData]=useState({});
    const [ContractData,setContractData]=useState({});
    const [WholeData,setWholeData]=useState({})

    const GetData=()=>{
        loader(true);
        ApiClient.get(`schedule/list?employerId=${user.id}&contractId=${id?id:contractid}`).then(res=>{
            if(res.success){
                const data=res.data&&res.data.length>0?res.data[0]:{};
                setWholeData(data)
                setTimeTableSchedule(data?.timeTable);
                setEmployerData(data?.employerDetail);
                setVAData(data?.virtualDetail)
                setContractData(data?.contractDetail)
                const ProjectId=data?.contractDetail?.projectId
                ApiClient.get(`project?id=${ProjectId}`).then(Res=>{
                    if(Res.success){
                        setProjectData(Res.data)
                    }
                })
            }
            loader(false);
        })
    }
    useEffect(()=>{
        GetData()
    },[])


    const HandleAcceptRejectStatus=(e,type="accepted")=>{
        loader(true);
        e.preventDefault();
        const ContractPayload={id:WholeData?.contractId,timeTable:TimeTableSchedule
          //  I AM COMMENTING THIS CODE BECAUSE AT THIS TIME WE NEED THAT ONLY VIRTUAL ASSISTANT CAN EDIT THE WORK SCHEDULE FOR ONE TIME ONLY --- IF WE NEED IT MULTIPLE TIME IN FUTURE JUST UNCOMMENT THIS CODE IT WORKS
          ,sentRequest:"never"
        }
        if(type=="rejected"){
            delete ContractPayload.timeTable
        }
       const  Statuspayload={
            "id":WholeData?._id,
            "status":type
        }

        ApiClient.put(`acceptReject/request`,Statuspayload).then(res=>{
            if(res.success){
                ApiClient.put(`contract`,ContractPayload).then(Res=>{ 
                        toast.success("Working time schedule is updated successfully");
                    history.push("/contracts"); 
                })
            }
            loader(false)
        })
    }
    const [Tab,setTab]=useState('Ch')
    useEffect(()=>{
document.getElementById("previoustab").click()
    },[])
    const [StartTimeSchedule,setStartTimeSchdule]=useState(environment.StarterSchedule);
    const [EndTimeSchedule,setEndTimeSchedule]=useState(environment.EnderSchedule);
   
    const UpdaterFunction = (selectedStartSchedule) => {
     const startIndex = EndTimeSchedule.indexOf(selectedStartSchedule);
   
     // Create a new array for the updated end schedule
     const newEndSchedule = [...EndTimeSchedule.slice(startIndex), 
       ...EndTimeSchedule.slice(0, startIndex)
     ];
   
     // Update the state with the new options for End Schedule
     setEndTimeSchedule(newEndSchedule);
   };
   const ChangesDetector=(data,key,index)=>{
    const newdata= ContractData?.timeTable?.find(item=>item.day==index)[key];
    if(data==newdata){
      return false
    }else{
      return true;
    }
    
   }
  return (
    <div>
      {type!=false?<Header/>:null}
      <div className=""> 
  <ul className="nav nav-tabs main_tabs my-5">
    <li  className='mr-3' onClick={e=>setTab('Pr')} ><a className={`text-${Tab=="Pr"?"":"dark"} text_box`} data-toggle="tab"  href="#home">Previous</a></li>
    <li  onClick={e=>setTab('Ch')}><a className={`text-${Tab=="Ch"?"":"dark"} text_greens`} data-toggle="tab" id="previoustab" href="#menu1">New Changes</a></li>

  </ul>


</div>
      <div className='container'>
      {type!=false?<div className='row'>
<div className='col-md-6'>
<label>Virtual Assistant Name</label>
<p>{VAData?.fullName}</p>
</div>
<div className='col-md-6'>
<label>Contract Name</label> 
<p>{ProjectData?.name}</p>
</div>
      </div>:null}
      <div className="tab-content">
    <div id="home" className="tab-pane fade in active">
    <h4 className='text-center mb-4'>Previous Working Time Schedule</h4>
   
  <div className='tbsectionsd mai_text'>
  <div className='table-responsive'>
    <table className="table table-hover  table-bordered">
      <thead className='table-primary' >
        <tr>
          <th>Day</th>
          {ContractData?.timeTable?.map((entry,index) => (
            <th key={entry.day}>{entry.day}</th>
          ))}
        </tr>
      </thead>
      <tbody>
        <tr>
          <td>Start Time</td>
          {ContractData?.timeTable?.map((entry,index) => (
            <td key={entry.day}>{entry.startTime}</td>
          ))}
        </tr>
        <tr>
          <td>Lunch Break</td>
          {ContractData?.timeTable?.map((entry,index) => (
            <td key={entry.day}>
              {entry.lunchBreak}
            </td>
          ))}
        </tr>
        <tr>
          <td>End Time</td>   
          {ContractData?.timeTable?.map((entry,index) => (
            <td key={entry.day}>
           {entry.endTime}
            </td>
          ))}
        </tr>
      </tbody>
    </table>
    </div>
  </div>
    </div>
    <div id="menu1" className="tab-pane fade">
           <h4 className='text-center mt-5 mb-4'>Changes in Working Time Schedule</h4>
           <div className='tbsectionsd mai_text'>
           <div className='table-responsive'>
      <table className="table table-hover table-bordered text-center ">
      <thead className='table-primary' >
        <tr>
          <th  >Day</th>
          {TimeTableSchedule?.map((entry,index) => (
            <th  key={entry.day}>{entry.day}</th>
          ))}
        </tr>
      </thead>
      <tbody>
        <tr>
          <td>Start Time</td>
          {TimeTableSchedule?.map((entry,index) => (
            <td style={{ backgroundColor: ChangesDetector(entry.startTime, 'startTime', entry.day) ? "#c3210c36" : "", color: ChangesDetector(entry.startTime, 'startTime', entry.day) ? "#000000c7" : "", borderRadius: "50px" }} key={entry.day}>{entry.startTime}</td>
          ))}
        </tr>
        <tr>
          <td>Lunch Break</td>
          {TimeTableSchedule?.map((entry,index) => (
            <td style={{ backgroundColor: ChangesDetector(entry.lunchBreak, 'lunchBreak', entry.day) ? "red" : "", color: ChangesDetector(entry.lunchBreak, 'lunchBreak', entry.day) ? "white" : "", borderRadius: "50px" }} key={entry.day}>
              {entry.lunchBreak}
            </td>
          ))}
        </tr>
        <tr>
          <td>End Time</td>
          {TimeTableSchedule?.map((entry,index) => (
            <td style={{ backgroundColor: ChangesDetector(entry.endTime, 'endTime', entry.day) ? "red" : "", color: ChangesDetector(entry.endTime, 'endTime', entry.day) ? "white" : "", borderRadius:"50px" }}  key={entry.day}>
           {entry.endTime}
            </td>
          ))}
        </tr>
      </tbody> 
    </table>
    </div>
    </div>
    </div>

  </div>

 {Tab=="Ch"?  <div className=''>
<div style={{float:"right"}} className=''>
    <button className='btn btn-danger mr-3' onClick={e=>HandleAcceptRejectStatus(e,"rejected")}>Reject</button>
    <button className='btn btn-primary' onClick={e=>HandleAcceptRejectStatus(e,"accepted")}>Accept</button>
</div>
    </div>:null}
    </div>
            <div>

      </div>
    </div>
  )
}
