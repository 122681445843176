import { useState } from "react";
import Header from "../../components/global/header";
import Footer from "../Home/Footer";
import ApiClient from "../../methods/api/apiClient";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { toast } from "react-toastify";

export default function ContactUs() {
 const history=useHistory()
  const [form,setForm]=useState({
    firstName:"",
    lastName:"",
    email:"",
    message:""
  })
   let payload={...form}
  const AddeditFormdata=(e)=>{
    e.preventDefault()
   
    ApiClient.post("add/ContactUs",payload).then((res)=>{
      if(res.success){
        setForm({
          firstName: "",
          lastName: "",
          email: "",
          message: ""
        })
        toast.success(res.message)
      }
    })
  }

  const Handleaddchange=(e)=>{
    const value=e.target.value;
    setForm({...form,[e.target.name]:value})
  }
  return (
    <>
    <Header/>
    <div className="container mt-5 mb-5">
    <div className="row">
          <div className="col-md-8 col-10 m-auto">
            <form onSubmit={e=>AddeditFormdata(e)} className="row contact_card">
              <div className="col-12 text-center mb-4">
                <h3>Contact Us </h3>
              </div>
              <div className="col-md-6 mb-4">
                  <label>Name <span className="text-danger">*</span></label>
                <input type="text" placeholder="Name" className="form-control"
                name="firstName" value={form.firstName}  onChange={e=>Handleaddchange(e)} 
                required
                />
              </div>
              <div className="col-md-6 mb-4">
                {" "}
                  <label>Last Name<span className="text-danger">*</span></label>
                <input
                  type="text"
                  placeholder="Last Name"
                  className="form-control"
                  name="lastName"
               required
                  value={form.lastName}
                  onChange={e=>Handleaddchange(e)}
                />
              </div>
              <div className="col-md-12 mb-4">
                  <label>Email<span className="text-danger">*</span></label>
                <input type="email"  placeholder="Email" className="form-control"
                name="email" value={form.email} onChange={e=>Handleaddchange(e)}
                required
                />
              </div>
              <div className="col-md-12 mb-5">
                  <label>Objectives<span className="text-danger">*</span></label>
                <div className="form-floating">
                  <textarea
                    className="form-control "
                    placeholder="Leave a comment here"
                   name="message"
                   value={form.message}
                   onChange={e=>Handleaddchange(e)}
                   required
                  />
                </div>
              </div>
              <div className="col-12 text-right">
                <button className="btn btn-primary">Submit</button>
              </div>
            </form>
          </div>
        </div>
    </div>
    <Footer/>
    </>
  );
}
