/*
 * @Author: Ghost 
 * @Email: Ghost@yopmail.com
 * @Date: 2024-02-13 11:30:23 
 * @Last Modified by: Someone
 * Email: anilgodara4239@gmail.com
 * @Last Modified time: 2024-02-26 12:23:41
 * */
import ReactDOM from 'react-dom';
import Main from './main';
import * as serviceWorker from './serviceWorker';
import { Provider } from 'react-redux';
import configureStore from './config';
import { createBrowserHistory } from 'history';
import { PersistGate } from 'redux-persist/es/integration/react';
import { loadStripe } from '@stripe/stripe-js';
import environment from './environment';
import { Elements } from '@stripe/react-stripe-js';

export const history = createBrowserHistory();
const stripePromise = loadStripe(environment.stripe_publish_key);

const { persistor, store } = configureStore(history);
ReactDOM.render(   <Provider store={store}>   
<Elements
        stripe={stripePromise}
>
    <PersistGate loading={'loading ...'} persistor={persistor}><Main /></PersistGate>
    </Elements>
    </Provider>, document.getElementById('root'));
// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();

 
 
