import Pagination from "react-pagination-js";
import "./style.scss";
import { Link } from "react-router-dom";
import Header from "../../components/global/header";
import { useState } from "react";
import CardDelterandPrimaryModal from "./CardDelterandPrimaryModal";
import CommonDelete from "../CommonDeleteActiveModal/CommonDelete";

const Html = ({
  tab, 
  statusChange,
  pageChange,
  addCol,
  deleteItem,
  exportCsv,
  uTableCols,
  removeCol,
  filters,
  tableCols,
  loaging,
  data,
  types,
  total,
  ShowPrimaryModal,
  setShowPrimaryModal,
  ShowDeleteModal,
  setShowDeleteModal,
}) => {
  const [PrimaryCardid, setPrimaryCardid] = useState("");
  const [ImageUrl, setImageUrl] = useState("/assets/img/visa.png");
  const [DeleteCardId, setDeleteCardId] = useState("");

  const ConfirmFunctionPrimary = () => {
    statusChange(PrimaryCardid);
  };
  const ConfirmFunctionDelete = () => {
    deleteItem(DeleteCardId);
  };
  return (
    <>
      <Header />
      <CommonDelete
        confirm={ConfirmFunctionDelete}
        setShow={setShowDeleteModal}
        show={ShowDeleteModal}
        key="Deleting the Card"
      />
      <CardDelterandPrimaryModal
        confirm={ConfirmFunctionPrimary}
        imgurl={ImageUrl}
        setShow={setShowPrimaryModal}
        show={ShowPrimaryModal}
      />
      <div className="container w-md-50">
        <div className=" d-flex justify-content-between align-items-center mb-3 mt-5">
          <h3 className="hedding">Payment</h3>
        </div>
        {tab == "grid" ? (
          <></>
        ) : (
          <>
            <div className="card bg-white mb-3">
              <div className="card-header bg-white font-weight-bold">
                Payment methods
              </div>
              <div className="card-body">
                {data &&
                  data.map((item) => {
                    return (
                      
                        <div className="row border-bottom py-3">
                        <div className="col-md-6 d-flex gap-3">  <img
                            src={
                              item.brand == "Visa"
                                ? `/assets/img/visa.png`
                                : "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSD5bzxju2tDukq4cs8OdLb93qweBo852s3JjxXaJI1uA&s"
                            }
                            className="visa"
                            alt=""
                          />
                             <div c> <span className="visaText">{item.last4}</span>
                              <p className="card-text">
                              Ending in {item.exp_year}
                            </p></div>
                             </div>
                          <div className=" col-md-6 text-end">
                            <h5 className="card-title mb-0 margit">
                          
                              {item?.isDefault ? (
                                <span className="btn btn-secondary ml-2">
                                  Primary
                                </span>
                              ) : (
                                <button
                                  className="btn btn-secondary ml-2"
                                  onClick={() => {
                                    setPrimaryCardid(item.card_id);
                                    setShowPrimaryModal("block");
                                    setImageUrl(
                                      item.brand == "Visa"
                                        ? `/assets/img/visa.png`
                                        : "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSD5bzxju2tDukq4cs8OdLb93qweBo852s3JjxXaJI1uA&s"
                                    );
                                  }}
                                >
                                  Set Primary
                                </button>
                              )}
                              {item?.isDefault ? (
                                <></>
                              ) : (
                                
                            <button  className="btn bg-danger text-white ml-2"> Delete <i
                            className="material-icons delete pointer text-white del_pay_card "
                            title="Delete"
                            onClick={() => {
                              setDeleteCardId(item.card_id);
                              setShowDeleteModal("block");
                            }}
                          >
                            {" "}
                            delete
                          </i></button>
                              )}
                            </h5>
                            
                          </div>
                      </div>
                    );
                  })}
                <Link  to="/card/add">
                  <button className="btn btn-primary mt-3">  Add  Credit/Debit Card</button>
                
                </Link>
                <div style={{float:"right"}} className="mt-4">
                <Link to="/banks">Do you want to Add ACH Method ?</Link>
                </div>
              </div>
            </div>
          </>
        )}
        {!data?.length || data.length == 0 ? (
          <div className="py-3 text-center">No Data</div>
        ) : (
          <></>
        )}
        {!loaging && total > filters.count ? (
          <div className="paginationWrapper">
            <span>
              Show {filters.count} from {total} Cards
            </span>
            <Pagination
              currentPage={filters.page}
              totalSize={total}
              sizePerPage={filters.count}
              changeCurrentPage={pageChange}
            />
          </div>
        ) : (
          <></>
        )}
      </div>
    </>
  );
};

export default Html;
