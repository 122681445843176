import { useEffect, useState } from 'react';
import loader from '../../../methods/loader';
import ApiClient from '../../../methods/api/apiClient';
import { useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { useDispatch, useSelector } from 'react-redux';
import environment from '../../../environment';
import Select from "react-select";
import Multiselect from 'multiselect-react-dropdown';
import { login_success } from '../../../actions/user';

export default function AddEditSkills({ skillsEdit, CloseSkillModal }) {
    const dispatch = useDispatch();
    const user = useSelector(state => state.user)
    //  ############ Skills Types########
    const { id } = useParams();
    const [form, setform] = useState({ id: "", name: "", status: "active", otherskills: "", skillType: [], hardSkills: null, softSkills: null })
    const [SkillTypes, setSkillTypes] = useState([])
    const [hardSkills, setHardSkills] = useState([])
    const [softSkills, setSoftSkills] = useState([])

    useEffect(() => {
        if (id) {
            ApiClient.get(`skill?id=${id}`).then(result => {
                if (result.success) {
                    const newdata = result.data;
                    setform({
                        name: newdata?.name,
                        status: newdata?.status,
                        skillType: newdata?.skillType?.map((item) => ({ id: item?.id, name: item?.name })),
                        hardSkills: newdata?.hardSkills?.map((item) => ({ value: item?.id, label: item?.name })),
                        softSkills: newdata?.softSkills?.map((item) => ({ value: item?.id, label: item?.name })),
                    })
                }
            })
        }
        SkillType()
        getHardSkills()
        getSoftSkills()
    }, [])

    useEffect(() => {
        if (skillsEdit) {
            loader(true)
            ApiClient.get(`user/details?id=${user?.id || user?._id}`).then(res => {
                if (res.success) {
                    const data = res?.data
                    setform({
                        id: user?.id || user?._id,
                        skillType: data?.skillType?.map((item) => ({ id: item?.id, name: item?.name })),
                        softSkills: data?.softSkills?.map((item) => ({ value: item?.id, label: item?.name })),
                        hardSkills: data?.hardSkills?.map((item) => ({ value: item?.id, label: item?.name })),
                    })
                }
                loader(false)
            }).catch((err)=>{
                loader(false)
            })
        }
    }, [skillsEdit])

    const SkillType = () => {
        loader(true);
        ApiClient.get(`skill/types?status=active`).then(res => {
            if (res.success) {
                let array = [];
                res?.data?.map((itm) => {
                    array.push({ id: itm?.id || itm?._id, name: itm?.name });
                });
                setSkillTypes(array)
            }
            loader(false);
        })
    }
    const getHardSkills = (professions = []) => {
        ApiClient.get(`skill/types?status=active&id=${String(professions)}`).then((res) => {
            if (res.success) {
                setHardSkills(res?.data?.map((item) => {
                    return ({
                        label: item?.name, options: item?.hardSkillDetails?.map((itm) => {
                            return ({ value: itm?.id || itm?._id, label: itm?.name })
                        })
                    })
                }))
            }
        })
    }
    const getSoftSkills = () => {
        ApiClient.get(`skills?status=active`).then(result => {
            if (result.success) {
                setSoftSkills(result?.data?.map((item) => {
                    return ({ value: item?.id || item?._id, label: item?.name || item?.title })
                }))
            }
        })
    }

    const handleTechnicalSkills = (e) => {
        setform({ ...form, skillType: e, hardSkills: null })
        const ids = e?.map((item) => item?.id)
        getHardSkills(ids)
    }

    const handleSubmit = (e) => {
        e.preventDefault();
        loader(true)
        const payload = {
            id: user?.id,
            skillType: form?.skillType?.map((itm) => itm?.id.toString()),
            hardSkills: form?.hardSkills?.map((item) => item?.value),
            softSkills: form?.softSkills?.map((item) => item?.value),
        }
        ApiClient.put("edit/profile", payload).then(result => {
            if (result.success) {
                dispatch(login_success({ ...user, hardSkills: form?.hardSkills, skillType: form?.skillType, softSkills: form?.softSkills }))
                CloseSkillModal();
                toast.success("Skills updated Successfully")
            }
            loader(false);
        })
    }

    const isAllow = (key = '') => {
        let permissions = user.role?.permissions
        let value = permissions?.[key]
        if (user.role.id == environment.adminRoleId) value = true
        return value
    }

    return (
        <>
            <form onSubmit={e => handleSubmit(e)}>
                <div className="skills-modal">
                    <div className="form-row">
                        <div className="col-md-12 mb-3">
                            <label>
                                Profession<span className="star">*</span>
                            </label>
                            <div className='profess_ions'>
                            <Multiselect
                                options={SkillTypes}
                                selectedValues={form?.skillType}
                                onSelect={(e) => handleTechnicalSkills(e)}
                                onRemove={(e) => handleTechnicalSkills(e)}
                                placeholder={`${form?.skillType == "" ? "Select Professions" : ""}`}
                                displayValue="name"
                            />
                            </div>
                            
                        </div>
                        <div className="col-md-12 mb-3">
                            <label>Hard Skills<span className="star">*</span></label>
                            <div className='skill-set'>
                                <Select
                                    placeholder="Select Hard Skills"
                                    className="text-capitalize dddskills w-100"
                                    value={form?.hardSkills}
                                    onChange={e => setform({ ...form, hardSkills: e })}
                                    options={hardSkills}
                                    isClearable
                                    closeMenuOnSelect={false}
                                    required
                                    isMulti
                                />
                            </div>
                        </div>
                        <div className="col-md-12 mb-3">
                            <label>Soft Skills <span className='star'>*</span></label>
                            <div className='skill-set'>
                                <Select
                                    options={softSkills}
                                    placeholder="Select Soft Skills"
                                    className='text-capitalize dddskills w-100 text-capitalize'
                                    value={form?.softSkills}
                                    onChange={e => setform({ ...form, softSkills: e })}
                                    isSearchable={true}
                                    closeMenuOnSelect={false}
                                    required
                                    isMulti
                                />
                            </div>
                        </div>
                    </div>
                    <div className="text-right mt-3">
                        <button type="button" className="btn btn-secondary discard mr-2" onClick={e => CloseSkillModal()}>Back</button>
                        {!id || (id && isAllow('editSkills')) ? <button type="submit" className="btn btn-primary">Save</button> : null}
                    </div>
                </div>
            </form>
        </>
    )
}
