import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom/cjs/react-router-dom.min'
import loader from '../../methods/loader';
import ApiClient from '../../methods/api/apiClient';
import parse from 'html-react-parser'
import methodModel from '../../methods/methods';
import environment from '../../environment';
import Header from '../../components/global/header';

export default function TermCondition() {
  const { slug } = useParams();
  const [Data, setData] = useState({})
  const GetData = () => {
    loader(true);
    if (slug == 'term-conditions' && methodModel.getPrams('role')) {
      ApiClient.get(`content?slug=${environment.vaSlug}`).then(res => {
        if (res.success) {
          setData(res.data);
        }
        loader(false)
      })
    } else {
      ApiClient.get(`content?slug=${slug}`).then(res => {
        if (res.success) {
          setData(res.data);
        }
        loader(false)
      })
    }
  }
  useEffect(() => {
    GetData()
  }, [])
  return (
    <div>
      <Header />
      <h4 className={`text-capitalize mt-3  text-center mt-5 ${Data.title ? "colorthis" : ""}`}>{Data.title}</h4>

      <div className='container mt-4'>
        {Data?.description ?
          <p>{parse(`${Data.description}`)}</p>
          :
          <p className='text-capitalize mt-3  text-center mt-5'>No Data Found</p>
        }
      </div>

    </div>
  )
}
