import './style.scss';
import Html from './Html';
import { Layout } from 'antd';


const Project = p => {
   
  return (
    <>
    <Layout>
     <Html    
     />
     </Layout>
    </>
  );
};

export default Project;
