/*
 * @Author: Ghost
 * @Email: Ghost@yopmail.com
 * @Date: 2024-02-13 11:30:23
 * @Last Modified by: Someone
 * Email: anilgodara4239@gmail.com
 * @Last Modified time: 2024-03-21 11:07:36
 * */
import { useState, useEffect } from "react";
import ApiClient from "../../methods/api/apiClient";
import loader from "../../methods/loader";
import "./home.scss";
import { useSelector } from "react-redux";
import methodModel from "../../methods/methods";
import Header from "../../components/global/header";
import {
  useHistory,
  useParams,
} from "react-router-dom/cjs/react-router-dom.min";
import { toast } from "react-toastify";
import CreateContract from "../CreateContractPages";
import platformFeeConvertor from "../CommonMethods/platformFeeConvertor";
import { ConnectSocket } from "../../components/global/SocketNetwork/ConnectSocket";
import Select from "react-select";

const VirtualDetail = () => {
  const { id } = useParams();
  const params = new URLSearchParams(window.location.search);
  const PlatformData = useSelector((state) => state.PlatformfeeReducer);
  const Projectid = params.get("projectid");
  useEffect(() => {
    if (Projectid) {
      setSelectedProject(`${Projectid}`);
      setMakeitDisable(true);
    }
  }, [Projectid]);
  const Navigate = useHistory();
  const user = useSelector((state) => state.user);
  const [UserData, setUserdata] = useState({});
  console.log(UserData, "=UserData");
  const [Descriptiondata, setDescriptiondata] = useState("");
  const GetDetails = () => {
    loader(true);
    ApiClient.get(`user/details?id=${id}`).then((res) => {
      if (res.success) {
        setUserdata(res.data);
      }
      loader(false);
    });
  };
  useEffect(() => {
    GetDetails();
  }, []);

  const [ProjectData, setProjectData] = useState([]);
  console.log(ProjectData, "==ProjectData");

  const [IsInvitedFirst, setIsInvitedFirst] = useState(false);
  const [EveryJobInvited, setEveryJobInvited] = useState(false);
  const GetProjects = () => {
    loader(true);
    ApiClient.get(`projects?userId=${user.id}&InviteFreelancerId=${id}&hireMore=true&isFrontend=true`).then(
      (res) => {
        if (res.success) {
          const newdata = res.data;
          const data = [];
          res.data?.map((item) => {
            if (!item?.isJobInvited)
              data.push({
                value: item?.id || item?._id,
                label: item?.name || item?.title,
              });
          });
          setProjectData(data);
          const answer = newdata.some((item) => item.isJobInvited == true);
          setIsInvitedFirst(answer);
          const everyinvited = newdata.every(
            (item) => item.isJobInvited == true
          );
          setEveryJobInvited(everyinvited);
        }
        loader(false);
      }
    );
  };

  useEffect(() => {
    if (user?.id) {
      GetProjects();
    }
  }, []);

  const [SelectedProject, setSelectedProject] = useState(null);
  const [MakeitDisable, setMakeitDisable] = useState(false);

  const HandleSubmit = (e) => {
    e.preventDefault();
    loader(true);
    ApiClient.post("invite", {
      freelancerId: id,
      projectId: SelectedProject?.value,
      description: Descriptiondata,
    }).then((res) => {
      if (res.success) {
        document.getElementById("closeinviteModal").click();
        setSelectedProject("");
        setDescriptiondata("");
        GetProjects();
        ConnectSocket.emit(`unread-noti-count`, { user_id: id });
        toast.success("Virtual Assistant Invited Successfully");
      }
      loader(false);
    });
  };

  const HandleCloseModal = () => {
    document.getElementById("closecont").click();
  };

  const [AnalyticsData, setAnalyticsData] = useState({});
  const GetAnalystics = () => {
    ApiClient.get(`userAnalytic?id=${id}`).then((res) => {
      if (res.success) {
        setAnalyticsData(res.data);
      }
    });
  };
  useEffect(() => {
    GetAnalystics();
  }, []);

  return (
    <>
      <Header />
      <div className="container">
        <div className="row">
          <div className="col-md-12 mt-4 mb-4">
            <div className="row col-md-reverse">
              <div className="d-flex align-items-start col-md-6">
                <img
                  src={`${methodModel.userImg(UserData.image)} `}
                  className="virtual_img shadow"
                />
                <div className="ml-4">
                  <h2 className="name_virtual text-capitalize">
                    {UserData.fullName}
                  </h2>
                  <p>
                    <p>
                      {methodModel.generateStarRating(
                        Number(UserData.totalRating)
                      )}
                    </p>
                    <span className="material-icons location">location_on</span>{" "}
                    {/* {UserData.address} - */}
                    {UserData.state}, {UserData.country}
                    {/* {UserData.timeZone && UserData.timeZone.label} */}
                  </p>
                  {/* <p className='mt-3'>${UserData.hourlyRate}</p> */}
                  {UserData.hardSkills &&
                    UserData.hardSkills.map((item) => (
                      <span className="mx-1 text-capitalize">{item?.name}</span>
                    ))}
                </div>
              </div>
              <div className="mb-2 col-lg-6 text-sm-right">
                <button
                  className="back btn btn-secondary mr-2 mb-2 phTop"
                  onClick={(e) => Navigate.goBack()}
                >
                  Back
                </button>
                {user?.id ? (
                  <>
                    <button
                      type="button"
                      className="btn btn-primary mb-2 mr-2 phTop"
                      data-toggle="modal"
                      data-target="#opencont"
                    >
                      Hire
                    </button>
                    <button
                      type="button"
                      disabled={IsInvitedFirst}
                      className="btn btn-success mb-2 phTop"
                      onClick={(e) => {
                        Projectid ? "" : setSelectedProject("");
                      }}
                      data-toggle="modal"
                      data-target="#openmodal"
                    >
                      {IsInvitedFirst ? "Invited" : "Invite"}
                    </button>
                    {IsInvitedFirst && !EveryJobInvited ? (
                      <button
                        type="button"
                        className="btn btn_info mb-2 ml-2 phTop"
                        onClick={(e) => {
                          setMakeitDisable(false);
                          Projectid ? "" : setSelectedProject("");
                        }}
                        data-toggle="modal"
                        data-target="#openmodal"
                      >
                        Another Invite
                      </button>
                    ) : null}
                  </>
                ) : null}
              </div>
            </div>
          </div>

          <div className="col-lg-4 ">
            {/* <div className='p-3'>
            <b>About</b>
            <p className='mt-2'>{UserData.description}</p>
            </div> */}
            {/* <hr /> */}
            <div className="border_total_sale mt-3">
              {UserData.softSkills?.length > 0 ? (
                <>
                  <div className="p-3">
                    <h6>Other Skills</h6>
                    {UserData.softSkills?.map((item) => (
                      <span className="mx-1 text-capitalize">{item?.name}</span>
                    ))}
                  </div>
                  <hr />
                </>
              ) : null}
              <div className="p-3">
                <div className="d-flex justify-content-between">
                  <div className="total_data">
                    <h5>
                      {methodModel.formatNumbersinPremium(
                        parseInt(AnalyticsData?.totalEarning)
                      )}
                      + USD
                    </h5>
                    <p>Total Earnings</p>
                  </div>
                  <div className="total_data">
                    <h5>{AnalyticsData?.totalContract}</h5>
                    <p>Total jobs</p>
                  </div>
                  <div className="total_data">
                    <h5>
                      {parseFloat(AnalyticsData?.totalbilling).toFixed(2)}
                    </h5>
                    <p>Total hours</p>
                  </div>
                </div>
              </div>

              <hr />
              <div className="p-3">
                <h6 className="font-weight-bold mb-2">Hourly Charge</h6>
                <p className="mb-0">
                  {/* More then{" "} */}
                  {parseFloat(
                    methodModel.CalculatePercentAmount(
                      UserData.hourlyRate,
                      PlatformData.platformFee
                    ) + parseInt(UserData.hourlyRate)
                  ).toFixed(2)}{" "}
                  USD / Hrs
                </p>
                <p> 24 hrs response time</p>
              </div>
            </div>
          </div>
          <div className="col-lg-8 ">
            <div className="border-top border-bottom p-3 border-right border-left mb-5 mt-3">
              <div className="d-flex justify-content-between alignitems-center">
                <h4 className="font-weight-bold mr-4">
                  {UserData.skillType &&
                    UserData.skillType?.map((item) => {
                      return <span className="mx-1 text-capitalize">{item?.name}</span>;
                    })}
                </h4>
                <h5>
                  <b>{platformFeeConvertor(UserData.hourlyRate)} USD /hr</b>
                </h5>
              </div>
              <p className="mt-5 desc_font">{UserData.description} </p>
            </div>
          </div>
          {/* <div className='border_box p-3'>
              <h2 className="name_virtual mb-3">Description</h2>
              <p className='desc_virtual'>"But I must explain to you how all this mistaken idea of denouncing pleasure and praising pain was born and I will give you a complete account of the system, and expound the actual teachings of the great explorer of the truth, the master-builder of human happiness. No one rejects, dislikes, or avoids pleasure itself, because it is pleasure, but because those who do not know how to pursue pleasure rationally encounter consequences that are extremely painful. Nor again is there anyone who loves or pursues or desires to obtain pain of itself, because it is pain, but because occasionally circumstances occur in which toil and pain can procure him some great pleasure. To take a trivial example, which of us ever undertakes laborious physical exercise, except to obtain some advantage from it? But who has any right to find fault with a man who chooses to enjoy a pleasure that has no annoying consequences, or one who avoids a pain that produces no resultant pleasure?"

                Section 1.10.33 of "de Finibus Bonorum et Malorum", written by Cicero in 45 BC
                "At vero eos et accusamus et iusto odio dignissimos ducimus qui blanditiis praesentium voluptatum deleniti atque corrupti quos dolores et quas molestias excepturi sint occaecati cupiditate non provident, similique sunt in culpa qui officia deserunt mollitia animi, id est laborum et dolorum fuga. Et harum quidem rerum facilis est et expedita distinctio. Nam libero tempore, cum soluta nobis est eligendi optio cumque nihil impedit quo minus id quod maxime placeat facere possimus, omnis voluptas assumenda est, omnis dolor repellendus. Temporibus autem quibusdam et aut officiis debitis aut rerum necessitatibus saepe eveniet ut et voluptates repudiandae sint et molestiae non recusandae. Itaque earum rerum hic tenetur a sapiente delectus, ut aut reiciendis voluptatibus maiores alias consequatur aut perferendis doloribus asperiores repellat."</p>
            </div>  */}

          <div
            className="modal  fade"
            id="openmodal"
            tabIndex="-1"
            role="dialog"
            aria-labelledby="exampleModalLabel"
            aria-hidden="true"
          >
            <div className="modal-dialog" role="document">
              <div className="modal-content">
                <div className="modal-header border-bottom mb-3">
                  <h5 className="modal-title" id="exampleModalLabel">
                    Select the Job Post{" "}
                  </h5>
                  <button
                    type="button"
                    id="closeinviteModal"
                    className="close"
                    data-dismiss="modal"
                    aria-label="Close"
                  >
                    <span aria-hidden="true">&times;</span>
                  </button>
                </div>
                <form onSubmit={(e) => HandleSubmit(e)}>
                  {" "}
                  <div className="modal-body">
                    <div className="form_group">
                      {ProjectData.length > 0 ? (
                        <>
                          <div className="select_itsmx">
                            {/* <select
                              value={SelectedProject}
                              disabled={MakeitDisable}
                              className="form-control myselect"
                              required
                              onChange={(e) => setSelectedProject(e.target.value)}
                            >
                              <option value="">Select a Job Post </option>
                              {ProjectData.map((item, index) => (
                                <option
                                  disabled={item.isJobInvited}
                                  value={item.id}
                                >
                                  {item?.name || item?.title}
                                  {item.isJobInvited ? " (Already Invited)" : ""}
                                </option>
                              ))}
                            </select> */}
                            <Select
                              placeholder="Invite"
                              className="text-capitalize dddskills w-100"
                              value={SelectedProject}
                              onChange={(e) => setSelectedProject(e)}
                              options={ProjectData}
                              isClearable
                              required
                            />
                          </div>

                          <div className="mt-3">
                            <label>Description</label>
                            <textarea
                              className="form-control"
                              value={Descriptiondata}
                              placeholder="Enter the Description here "
                              onChange={(e) =>
                                setDescriptiondata(e.target.value)
                              }
                            ></textarea>
                          </div>
                        </>
                      ) : (
                        <div className="text-center">
                          <button
                            className="btn btn-primary"
                            onClick={(e) => {
                              document
                                .getElementById("closeinviteModal")
                                .click();
                              Navigate.push(
                                `/addeditproject?comethrough=${id}`
                              );
                            }}
                          >
                            Add Job Post
                          </button>
                        </div>
                      )}
                    </div>
                  </div>
                  <div className="modal-footer mt-3">
                    <button
                      type="button"
                      className="btn btn-secondary"
                      data-dismiss="modal"
                    >
                      Close
                    </button>
                    <button type="submit" className="btn btn-primary">
                      Invite
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>

      <button
        type="button"
        className="btn btn-primary d-none"
        data-toggle="modal"
        data-target="#opencont"
      >
        Launch demo modal
      </button>

      <div
        className="modal fade"
        id="opencont"
        tabindex="-1"
        role="dialog"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog" role="document">
          <div className="modal-content">
            <div className="modal-header ">
              <h3 className="modal-title">Create Offer</h3>

              {/* <h5 className="modal-title" id="exampleModalLabel">Modal title</h5> */}
              <button
                type="button"
                className="close"
                data-dismiss="modal"
                id="closecont"
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body">
              {user?.id ? (
                <CreateContract
                  isTrue={false}
                  userid={id}
                  update={id}
                  HandleClose={HandleCloseModal}
                  chatwithId={id}
                  ComeFrom="mainhire"
                  hourlyRate={UserData.hourlyRate}
                />
              ) : null}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default VirtualDetail;
