import { useSelector } from "react-redux";
import environment from "../../environment";
import { Link } from "react-router-dom/cjs/react-router-dom.min";
import Header from "../../components/global/header";

export default function StaffAdd() {
  const user = useSelector((state) => state.user);
  const EmployerAddHelper = (rolid, type = "") => {
    if (type == "") {
      if (rolid == environment.DirectManagerId) {
        return !user.directManager_created ||
          user.directManager_created == false
          ? true
          : false;
      }
      //  Here is the Accounting Manager Id
      else if (rolid == environment.AccountManagerId) {
        return !user.accountManager_created ||
          user.accountManager_created == false
          ? true
          : false;
      }
    }
    //  Here we check
    else {
      return !user.accountManager_created ||
        user.accountManager_created == false ||
        !user.directManager_created ||
        user.directManager_created == false
        ? true
        : false;
    }
  };
  return (
    <div>
      <Header />
     
    <div className="container">
    <div className="mt-5 text-center ">
      <div className="manger-title">
          <h4>Add Managers</h4>
      </div>
      <div className=" d-flex flex-wrap gap-3 justify-content-center">
      <Link
          to={`/helper/${environment.DirectManagerId}`}
          className=" bx-border"
        >
          <img src="/assets/img/direct-manager.png" />
          <button type="button" className="btn btn-primary  ml-2">
            Add Direct Manager
          </button>
        </Link>
        <Link
          to={`/helper/${environment.AccountManagerId}`}
          className="ml-2 bx-border"
        >
          {" "}
          <img src="/assets/img/acc-manager.png" />
          <button className="btn btn-primary "> Add Account Manager</button>
        </Link>
      </div>
        
      </div>
    </div>
      {/* :null} */}
    </div>
  );
}
