/*
 * @Author: Ghost 
 * @Email: Ghost@yopmail.com
 * @Date: 2024-02-01 15:52:41 
 * @Last Modified by: Someone
 * @Last Modified time: 2024-07-02 14:48:26
 * @Description: file:///home/jc-21/Desktop/MyVirtualPal/marketplace-frontend/src/pages/NoticeDetailer/AddEditNotice.js
 */
import { useEffect, useState } from "react";
import { Editor } from "@tinymce/tinymce-react";
import { useSelector } from "react-redux";
import loader from "../../methods/loader";
import ApiClient from "../../methods/api/apiClient";
import { toast } from "react-toastify";
import { ConnectSocket } from "../../components/global/SocketNetwork/ConnectSocket";
import environment from "../../environment";
import methodModel from "../../methods/methods";

const AddEditNotice = ({
  data,
  setdata,
  id,
  view,
  ModalCloserRef,
  GetData,
  Loader,
}) => {
  const [selectedContract, setSelectedContract] = useState(null);
  const [form, setform] = useState({
    title: "",
    description: "",
    contract_id: "",
    project_id: "",
    employer_id: "",
    virtualid: "",
    terminateNow: "",
    notice_days: "",
  });
  const user = useSelector((state) => state.user);
  const [description, setdescription] = useState("");
  const [filters, setfilters] = useState({
    search: "",
    status: "accepted",
    addedBy: user.id,
  });

  const handleSubmit = async (e) => {
    e.preventDefault();
    let method = "post";
    let url = "notice";
    const payload = {
      title: form.title,
      description: description,
      employerId: form.employer_id,
      virtualId: form.virtualid,
      contractId: form.contract_id,
      projectId: form.project_id,
      type:
        user.role && user.role.id == environment.VirtualId
          ? "virtual"
          : "employer",
      notice_days: form.notice_days,
      terminateNow: form.terminateNow,
    };

    ApiClient.post(url, payload).then((res) => {
      if (res.success) {
        if (payload.terminateNow == "true") {
          // ApiClient.delete(`cancel/subscription?contractId=${payload.contractId}`).then(res=>{
          // if(res.success){
          ApiClient.put(`contract`, {
            id: payload.contractId,
            status: "terminated",
          });
          // }
          // })
        }
        toast.success(`Notice Submitted Successfully`);
        ConnectSocket.emit(`unread-noti-count`, { user_id: form.employer_id });
        setdata({});
        GetData();
        GetDataContract();
        setform({
          title: "",
          description: "",
          contract_id: "",
          project_id: "",
          employer_id: "",
          virtualid: "",
          terminateNow: "",
          notice_days: "",
        });
        ModalCloserRef.current.click();
      }
    });
  };

  useEffect(() => {
    setdescription("");
    setform({ title: "", contract_id: "", notice_days: "", terminateNow: "" });
    setform({});
  }, [Loader]);

  useEffect(() => {
    if (id) {
      setdescription(data.description);
      setform({
        title: data.title,
        contract_id: data.contract_id,
        notice_days: data?.notice_days || 0,
        terminateNow: data?.terminateNow || "",
      });
    }
  }, [data, id]);

  useEffect(() => {
    ContractData.map((item) => {
      if (item.id == form.contract_id) {
        const projectid = item.project_details && item?.project_details?._id;
        const employerid =
          item.contractFrom_details && item?.contractFrom_details?._id;
        const virtualid =
          item.contractTo_details && item?.contractTo_details?._id;

        setform({
          ...form,
          project_id: projectid,
          employer_id: employerid,
          virtualid: virtualid,
        });
      }
    });
  }, [form.contract_id]);

  // useEffect(() => {
  //   const selectedContract = ContractData.find(item => item._id === form.contract_id);
  //   if (selectedContract) {
  //     const projectid = selectedContract.project_details?.id;
  //     const employerid = selectedContract.contractFrom_details?.id;
  //     const virtualid = selectedContract.contractTo_details?.id;
  //     setform(prevForm => ({...prevForm,project_id: projectid,employer_id: employerid,
  //       virtualid: virtualid
  //     }));
  //   }
  // }, [form.contract_id]);

  const [ContractData, setContractData] = useState([]);
  const GetDataContract = () => {
    loader(true);
    ApiClient.get(`contracts`, filters).then((res) => {
      if (res.success) {
        setContractData(res.data);
        // Get contractId and notice type from params check if get then set in form state
        const contractId = methodModel.getPrams('contract')
        if (contractId) {
          setform((prev) => ({ ...prev, contract_id: contractId, terminateNow: methodModel.getPrams("type") === "terminate" ? "true": "false" }))
        }
      }
      loader(false);
    });
  };

  useEffect(() => {
    GetDataContract();
  }, []);

  const HandleChange = (e, name = "") => {
    setform({ ...form, [name]: e.target.value });
  };

  return (
    <div>
      <form onSubmit={handleSubmit} className="">
        <div className="mb-3">
          <label>Select Contract:</label>
          <select
            onChange={(e) => {
              HandleChange(e, "contract_id");
            }}
            name="contract_id"
            value={form.contract_id}
            required
            disabled={view}
            className="form-control"
          >
            <option value="">Select your Contract</option>
            {ContractData.map((item, index) => (
              <option value={item.id}>
                {item.project_details && item.project_details.name}(
                {user.role && user.role.id == environment.EmployerId
                  ? item?.contractTo_details?.fullName
                  : item?.contractFrom_details?.fullName}
                )
              </option>
            ))}
          </select>
        </div>
        <div className="mb-3">
          <label>
            Subject<span className="text-danger">*</span>
          </label>
          <input
            type="text"
            name="title"
            value={form.title}
            className="form-control"
            disabled={view}
            onChange={(e) => HandleChange(e, "title")}
            required
          />
        </div>
        {methodModel.getPrams("type") ? null :
          <>
            {user.role.id == environment.EmployerId ? (
              <div className="mb-3">
                <label>
                  Notice Type <span className="text-danger">*</span>
                </label>
                <select
                  required
                  value={form.terminateNow}
                  onChange={(e) => HandleChange(e, "terminateNow")}
                  name="terminateNow"
                  className="form-control"
                >
                  <option value="">Select Notice Type</option>
                  <option value={false}>Notice Period</option>
                  <option value={true}>Terminate Now</option>
                </select>
              </div>
            ) : null}
          </>}
        {form.terminateNow == "false" ||
          user.role.id == environment.VirtualId ? (
          <div className="mb-3">
            <label>
              Notice Days <span className="text-danger">*</span>
            </label>
            <input
              type="number"
              min={0}
              max={50}
              required
              value={form.notice_days}
              onChange={(e) => HandleChange(e, "notice_days")}
              name="notice_days"
              className="form-control"
            />
          </div>
        ) : null}
        <div>
          <label>Message</label>
          <Editor
            apiKey={environment.tinyKey}
            value={description}
            onEditorChange={(newValue, editor) => {
              setdescription(newValue);
            }}
            className="form-control"
            disabled={view}
          />
        </div>
        <div>
          {!view ? (
            <div style={{ float: "right" }} className="mr-2 mt-3">
              <button type="submit" className="btn btn-primary">
                Submit
              </button>
            </div>
          ) : null}
        </div>
      </form>
    </div>
  );
};

export default AddEditNotice;
