import React, { useState } from "react";
import methodModel from "../../methods/methods";
import { useHistory } from 'react-router-dom';
import ApiClient from "../../methods/api/apiClient";
import loader from "../../methods/loader";
import { toast } from "react-toastify";

const Setpassword = () => {
    const [eyes, setEyes] = useState({ newPassword: false, confirmPassword: false });
    const [form, setForm] = useState({ newPassword: '', confirmPassword: '' })
    const [submitted, setSubmitted] = useState(false)
    const history = useHistory()

    const formValidation = [
        { key: 'confirmPassword', minLength: 8, confirmMatch: ['confirmPassword', 'newPassword'] },
        { key: 'newPassword', minLength: 8 },
    ]

    const getError = (key) => {
        return methodModel.getError(key, form, formValidation)
    }

    const handleSubmit = (e) => {
        e.preventDefault();
        setSubmitted(true)
        if (form?.newPassword != form?.confirmPassword || !form?.newPassword || !form?.confirmPassword) return false
        loader(true)
        ApiClient.put('set-password', form).then(res => {
            if (res.success) {
                loader(false)
                setTimeout(() => {
                    history.push('/profile');
                    toast.success("Password Created Successfully ")
                }, 500);

            }
        })
    };

    return <>
        <>
            <div className="main_signup">
            <div className="row align-items-center justify-content-center h-100">
            <div className="col-xl-4 col-lg-6 col-md-8">
                        <div className='top_section'>
                            <div className='right_side pt-4'>

                                <div className='logo_image mb-0'>
                                    <img src='/assets/img/Logo_new.png' loading='lazy' width={'100%'} height={`100%`} className='logo_name mb-2' />
                                </div>

                                <div className="text-center mb-2">
                                    <h3 className="text-cenetr lgtext">Create Password</h3>
                                </div>

                                <form
                                    className="centerLogin mt-3"
                                    onSubmit={handleSubmit}
                                >





                                    <label className="ml-1">Create Password<span className="red">*</span></label>
                                    <div className="mb-3">
                                        <div className="inputWrapper">
                                            <input
                                                type={eyes.newPassword ? 'text' : 'password'}
                                                className="form-control mb-0 bginput changes"
                                                value={form.newPassword}
                                                onChange={e => setForm({ ...form, newPassword: e.target.value })}
                                                placeholder="Create Password"
                                                required
                                            />
                                            <i className={eyes.newPassword ? 'fa fa-eye' : 'fa fa-eye-slash'} onClick={() => setEyes({ ...eyes, newPassword: !eyes.password })}></i>
                                        </div>
                                        {submitted && form?.newPassword.length < 8 ? <div className="invalid-feedback d-block">Min Length must be 8 characters long</div> : <></>}

                                    </div>


                                    <label className="ml-1">Confirm Password   <span className="red">*</span></label>
                                    <div className="mb-3">
                                        <div className="inputWrapper">
                                            <input
                                                type={eyes.confirmPassword ? 'text' : 'password'}
                                                className="form-control mb-0 bginput changes"
                                                value={form?.confirmPassword}
                                                onChange={e => setForm({ ...form, confirmPassword: e.target.value })}
                                                placeholder="Confirm Password"
                                                required
                                            />
                                            <i className={eyes.confirmPassword ? 'fa fa-eye' : 'fa fa-eye-slash'} onClick={() => setEyes({ ...eyes, confirmPassword: !eyes.confirmPassword })}></i>
                                        </div>
                                        {submitted && form?.password != form?.confirmPassword ? <>

                                            {/* {getError('confirmPassword').err.minLength ? <div>Min Length must be 8 characters long</div> : <></>} */}
                                            {getError('confirmPassword').err.confirmMatch ? <div className="invalid-feedback d-block">Confirm Password is not matched with New Password</div> : <></>}

                                        </> : <></>}
                                    </div>





                                    <div className="mt-0">
                                        <button type="submit" className="btn btn-primary loginclass mt-2">
                                            Save
                                        </button>
                                    </div>

                                </form>

                            </div>
                        </div>

                    </div>


                </div>
            </div>



        </>



    </>
}

export default Setpassword;