import { useEffect, useState } from "react";
import Header from "../../../components/global/header";
import loader from "../../../methods/loader";
import ApiClient from "../../../methods/api/apiClient";
import { useSelector } from "react-redux";
import environment from "../../../environment";
import { useHistory } from "react-router-dom";
import { toast } from "react-toastify";

export default function StafList() {
  const [StaffList, setStaffList] = useState([]);
  const Navigate = useHistory();
  const user = useSelector((state) => state.user);
  const GetStaffList = () => {
    loader(true);
    const userid = user.id ? user.id : localStorage.getItem("userid");
    ApiClient.get(`staff/list?userId=${userid}`).then((res) => {
      if (res.success) {
        setStaffList(res.data);
      }
      loader(false);
    });
  };
  useEffect(() => {
    GetStaffList();
  }, []);

  const ReInvite = (e, id) => {
    e.preventDefault();
    loader(true);
    ApiClient.post(`send/credntials`, { id: id }).then((res) => {
      if (res.success) {
        toast.success(res.message);
      }
      loader(false);
    });
  };
  return (
    <div>
      <Header />
      <div className="mt-5 container">
        <div className="flow_flex">
          {" "}
          <h4 className="">Your Staff</h4>
          <div>
            <button
              className="btn btn-primary"
              onClick={(e) => Navigate.push("/staff/add")}
            >
              Add Staff
            </button>
          </div>
        </div>
        <div className="policies-page">
        <div className="table-responsive">
          {" "}
          <table className="table  mt-4">
            <thead>
              <th>Name</th>
              <th>Email</th>
              <th>Role</th>
              <th>Actions</th>
            </thead>
            <tbody>
              {StaffList.map((item) => (
                <tr>
                  <td>{item.fullName}</td>
                  <td>{item.email}</td>
                  <td>
                    {item.role && item.role._id == environment.AccountManagerId
                      ? "Accounting Contact"
                      : "Direct Manager"}
                  </td>
                  <td>
                    <button
                      className="btn btn-primary"
                      onClick={(e) => ReInvite(e, item?.id)}
                    >
                      Reinvite
                    </button>
                    <i
                      className="fa fa-edit text-info ml-3"
                      onClick={(e) =>
                        Navigate.push(
                          `/helper/${item.role && item.role._id}?editid=${
                            item.id
                          }`
                        )
                      }
                    ></i>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
        {StaffList.length == 0 ? (
          <div className="text-center text-danger">No Data</div>
        ) : null}
        </div>
       
      </div>
    </div>
  );
}
