import { useEffect, useState } from "react";
import Header from "../../components/global/header";
import loader from "../../methods/loader";
import ApiClient from "../../methods/api/apiClient";
import { useSelector } from "react-redux";
import methodModel from "../../methods/methods";

export default function Virtualdashboard() {
  const user = useSelector((state) => state.user);
  const [Data, setData] = useState({});

  const Getvirtualdata = () => {
    ApiClient.get(`va-referal?userId=${user?.id}`).then((res) => {
      setData(res);
    });
  };
  useEffect(() => {
    Getvirtualdata();
  }, []);

  return (
    <div>
      <Header />
      <div className="container mt-5">
        <div className="row">
          <div className="col-md-12">
            <div className="mb-3">
              <h2> Dashboard</h2>
            </div>
          </div>
          <div className="col-sm-6 col-md-4 col-lg-3 mb-3">
            <div className="Sales_row card">
              <h4>Total Invited</h4>
              <div className="">
                <h2>{Data?.invitedCount}</h2>
                <p className="mb-0"></p>
              </div>
            </div>
          </div>
          <div className="col-sm-6 col-md-4 col-lg-3 mb-3">
            <div className="Sales_row card">
            <h4>Registered Virtual Assistant</h4>
              <div className="">
                <h2>{Data.registeredUser}</h2>
                <p className="mb-0"></p>
              </div>
            </div>
          </div>

          <div className="col-sm-6 col-md-4 col-lg-3 mb-3">
            <div className="Sales_row card">
            <h4>Onboard Virtual Assistant</h4>
              <div className="">
                <h2>{Data.onboardUsers}</h2>
                <p className="mb-0"></p>
              </div>
            </div>
          </div>

          <div className="col-sm-6 col-md-4 col-lg-3 mb-3">
            <div className="Sales_row card">
              <h4>Total Commission</h4>
              <div className="">
                <h2>
                  {Data?.commissionAmount ? Data?.commissionAmount : 0} USD{" "}
                </h2>
                <p className="mb-0"></p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
