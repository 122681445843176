import React, { useEffect, useRef, useState } from 'react';
import FullCalendar from '@fullcalendar/react';
import dayGridPlugin from '@fullcalendar/daygrid';
import timeGridPlugin from '@fullcalendar/timegrid';
import interactionPlugin from '@fullcalendar/interaction';
import { useParams } from 'react-router-dom';
import ApiClient from '../../methods/api/apiClient';
import Header from "../../components/global/header";
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import { DatePicker } from 'antd';
import moment from 'moment';
import loader from "../../methods/loader";
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import methodModel from '../../methods/methods';
import datepipeModel from '../../models/datepipemodel';
import { useSelector } from 'react-redux';
import environment from '../../environment';
const { RangePicker } = DatePicker;

const Submithours = () => {
    const calendarRef = useRef();
    const user = useSelector(state => state.user);
    const EventsRef = useRef([])
    const history = useHistory();
    const { id } = useParams();
    const [data, setData] = useState([]);
    const [loaders, setloader] = useState(false)
    const [selectedDateRange, setSelectedDateRange] = useState([]);
    const [filters, setFilters] = useState({ start: '', end: '' });
    const [totalHours, setTotalHours] = useState({ hours: '', minutes: '' });
    const [contractDate, setContractDate] = useState(null)
    const [weeklyBillingData, setWeeklyBillingData] = useState("")

    const MapAllData = (data2) => {
        return data2.map(event => ({
            id: event?.id || event?._id,
            status: event.billingStatus,
            title: `${event.projectDetail?.title},\n\n\n  ${event?.totalDurationOfTime ? event?.totalDurationOfTime : "0.00"} hrs,\n\n\n`,
            status: `${event?.billingStatus === "notSubmitted" ? "Not Submitted" : event?.billingStatus || ""}`,
            start: datepipeModel.ReverseTimetoObj(event.startTime),
            end: datepipeModel.ReverseTimetoObj(event.endTime),
        }));
    };

    const renderEventContent = (eventInfo) => {
        return (
            <div>
                <div>{eventInfo?.timeText}</div>
                <div>{eventInfo.event._def.title}
                    {eventInfo.event._def.duration} </div>
                <strong className='text-capitalize mt-1 block'>
                    {eventInfo.event._def.extendedProps.status}
                </strong>
            </div>
        );
    };

    const SubmitBilling = (e) => {
        e.preventDefault();
        let fltr = weeklyBillingData?.data?.filter((itm) => itm?.billingStatus != 'submitted')
        const payload = fltr.map(itm => itm.id);
        if (payload?.length === 0) {
            return toast.error("Hours already submitted")
        }
        loader(true)
        ApiClient.put(`billing/submit`, { data: payload })
            .then((res) => {
                if (res.success) {
                    loader(false)
                    toast.success(res.message);
                    document.getElementById("closemodal").click()
                    history.push("/trans/virtual");
                } else {
                    console.log('error');
                }
            })
    };


    const GetAllData = (p = {}) => {
        loader(true)
        let filt = { ...filters, ...p, contractId: id }
        ApiClient.get(`${environment.VirtualId == user?.role?.id ? 'billing-list/virtual' : 'billing/list'}`, filt).then((res) => {
            if (res.success) {
                loader(false)
                const contractStartDate = res?.data?.find((item) => item?.contractDetail?.startDate)?.contractDetail?.startDate
                setContractDate(contractStartDate)
            }
        })
    }

    useEffect(() => {
        GetAllData()
    }, []);

    useEffect(() => {
        if (contractDate) {
            getWeeklyBilling()
        }
    }, [contractDate])


    const handleDateChange = (dates, dateStrings) => {
        setSelectedDateRange(dates);
        let startDate = dateStrings[0] ? moment(dateStrings[0]).format('YYYY-MM-DD') : '';
        let endDate = dateStrings[1] ? moment(dateStrings[1]).format('YYYY-MM-DD') : '';
        setFilters({
            ...filters,
            start: startDate,
            end: endDate,
            contractId: id,
        });
        GetAllData({
            start: startDate,
            end: endDate,
            contractId: id,
        });
        if (calendarRef.current && dateStrings[0]) {
            calendarRef.current.getApi().gotoDate(moment(dateStrings[0]).format('YYYY-MM-DD'));
        }
    };

    const handleDatesSet = (arg) => {
        const newStartDate = arg.view.currentStart;
        setContractDate(moment(newStartDate).format('YYYY-MM-DD'));
    };

    const getWeeklyBilling = () => {
        if (!contractDate) {
            return
        }
        setData(null)
        const startDate = moment(contractDate).format("YYYY-MM-DD")
        const endDate = moment(contractDate).add(6, 'days').format("YYYY-MM-DD")
        loader(true)
        setloader(true)
        ApiClient.get(`${environment.VirtualId == user?.role?.id ? 'billing-list/virtual' : 'billing/list'}?start=${startDate}&end=${endDate}&contractId=${id}`).then(res => {
            if (res.success) {
                const FinalData = MapAllData(res.data);
                EventsRef.current = FinalData
                setData([...FinalData]);
                let hours = res?.total_billingHours;
                let minutes = res?.total_billingMinutes;
                let remainder = minutes / 60;
                let quotient = minutes % 60;
                hours += parseInt(remainder);
                minutes = quotient;
                setWeeklyBillingData({ ...res, total_billingHours: hours || "00", total_billingMinutes: minutes || "00" })
            }
            loader(false)
            setloader(false)
        })
    }


    return (
        <>
            <Header />
            <div className="container mt-4">
                {/* <div className='col-md-4 mb-3'>
                    <RangePicker
                        className="dateRangePicker"
                        placeholder={["Start Date", "End Date"]}
                        format="MM-DD-YYYY"
                        onChange={handleDateChange}
                        value={selectedDateRange}
                    />
                </div> */}


                {/* {/ {/ {data.length === 0 ? <h4 className='text-center'>No Data</h4> : /} /} */}
                {loaders ? <div>Loading.......,</div> :

                    <FullCalendar

                        rerenderDelay={0}
                        key={Math.random(1000)} // Use key to re-render calendar
                        plugins={[dayGridPlugin, timeGridPlugin, interactionPlugin]}
                        initialView="timeGridWeek"
                        initialDate={contractDate ? moment(datepipeModel.ReverseTimetoObj(contractDate)).format("YYYY-MM-DD") : moment(new Date()).format('YYYY-MM-DD')}
                        firstDay={contractDate ? moment(datepipeModel.ReverseTimetoObj(contractDate)).day() : 12}
                        events={loaders ? null : EventsRef.current}
                        ref={calendarRef}
                        weekends={true}
                        datesSet={handleDatesSet}
                        headerToolbar={{
                            left: 'prev,next today',
                            right: 'title',
                        }}
                        eventContent={renderEventContent}
                    />
                }
                {/* // {/ {/ } /} /} */}

                <div className='float-right mt-3'>
                    {data?.length !== 0 &&
                        <button className='btn btn-primary' onClick={e => document.getElementById("SubmitHoursModal").click()}>Review</button>
                    }
                    {/* {/ <button className='btn btn-secondary ml-3' onClick={e => history.push("/trans/virtual")}>Back</button> /} */}
                </div>
            </div>

            <button type="button" className="btn btn-primary d-none" id='SubmitHoursModal' data-bs-toggle="modal" data-bs-target="#exampleModal">
                Launch demo modal
            </button>

            <div className="modal fade" id="exampleModal" tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                <div className="modal-dialog">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h1 className="modal-title fs-5" id="exampleModalLabel">Summary</h1>
                        </div>
                        <div className="modal-body">
                            <h4 className="col-md-12 text-center mb-3 text-capitalize">
                                {filters.start &&
                                    <>{moment(filters.start).format("MMM DD")} - {moment(filters.end).format("DD, YYYY")}</>
                                }
                            </h4>
                            <div className="row">
                                <div className="col-md-6 mb-3">
                                    <label className="weightlabel">Date :</label>
                                </div>
                                <div className="col-md-6 mb-3 text-capitalize">
                                    {moment(contractDate).format('MM/DD/YYYY')} - {moment(contractDate).add(6, 'days').format('MM/DD/YYYY')}
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-md-6 mb-3">
                                    <label className="weightlabel">Total Hours :</label>
                                </div>
                                <div className="col-md-6 mb-3 text-capitalize">
                                    {/* {/ {totalHours.hours}:{totalHours.minutes} hrs /} */}
                                    {weeklyBillingData?.total_billingHours}:{weeklyBillingData?.total_billingMinutes} hrs
                                </div>
                            </div>
                        </div>
                        <div className="modal-footer">
                            <button type="button" className="btn btn-secondary" id='closemodal' data-bs-dismiss="modal">Cancel</button>
                            <button type="button" className="btn btn-primary" onClick={e => SubmitBilling(e)}>Submit</button>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default Submithours;