import { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import ApiClient from '../../methods/api/apiClient';
import loader from '../../methods/loader';
import methodModel from '../../methods/methods';
import './style.scss';
import { useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';
import environment from '../../environment';

const JobDetails = ({ setMyForm, setCount, MyForm }) => {
  const PlatformData = useSelector(state => state.PlatformfeeReducer);
  const [GetProjectid, setProjectid] = useState("");
  const Navigate = useHistory();
  const user = useSelector(state => state.user);
  const [Allskills, setAllSkills] = useState([])
  console.log(MyForm, "myform,")
  const HandleSubmit = (e) => {
    const newarray = [];
    Allskills.map((item, index) => {
      if (MyForm.skills.includes(item.id)) {
        newarray.push({ name: item.name, id: item.id });
      }
    })
    loader(true);
    e.preventDefault();
    let method = "post";
    let payload = { comethrough: MyForm?.comethrough, description: MyForm?.description, documents: MyForm?.documents, hourlyrateEnd: MyForm?.hourlyrateEnd, hourlyRate: MyForm?.hourlyRate, skills: newarray, name: MyForm.title, skillType: MyForm?.skillType?.map((itm) => itm?.id.toString()) };
    if (MyForm.id != "") {
      method = "put"
    }
    else {
      delete payload.id;
    }
    console.log(payload, "finalpayload")
    // return
    ApiClient.allApi("project", payload, method).then(Res => {
      if (Res.success) {
        setProjectid(Res.data && Res.data.id);
        if (MyForm.comethrough && MyForm.comethrough != "") {
          Navigate.push(`/virtualdetails/${MyForm.comethrough}?projectid=${Res.data && Res.data.id}`);
          toast.success(`Job  ${method == "post" ? "posted" : "updated"} successfully`)
        } else {
          document.getElementById("congratulationmodal").click();
        }
      }
      loader(false);
    })
  }

  const skills = () => {
    ApiClient.get(`skills?status=active`).then(res => {
      if (res.success) {
        setAllSkills(res.data);
      }
    })
  }
  useEffect(() => {
    skills()
  }, [])

  const [ReleveantData, setReveleantData] = useState([]);

  const GetVirtualAssistantSkill = () => {
    loader(true);
    ApiClient.get(`virtual/assistants`, { skills: MyForm.skills.toString(), employerId: user.id }).then(res => {
      if (res.success) {
        setReveleantData(res.data);
      }
      loader(false);
    })
  }

  useEffect(() => {
    GetVirtualAssistantSkill();
  }, [])


  //  For Handling the Shortlist Action 
  const HandleShortlist = (e, id) => {
    e.preventDefault();
    loader(true);
    const payload = { virtualAssisstantId: id, type: "virtualAssisstant" }
    ApiClient.post(`add_sortlist`, payload).then(res => {
      if (res.success) {
        GetVirtualAssistantSkill();
      }
      loader(false);
    })
  }

  const HandleRemoveShortlist = (e, id) => {
    loader(true);
    e.preventDefault();
    const payload = { virtualAssisstantId: id };
    ApiClient.delete(`sortlist`, payload).then(res => {
      if (res.success) {
        GetVirtualAssistantSkill();
      }
      loader(false);
    })
  }

  return (
    <>
      <div className="bg-white pt-4">
        <div className="container pl-5 pr-5 mt-5">
          <div className="row ">
            <div className='d-flex justify-content-between flex-lg-col'>
              <h2 className='mb-2'>Job Details</h2>
              <button className='btn btn-primary mb-2' onClick={e => HandleSubmit(e)}>{MyForm.id == "" ? "Post" : "Update"} this job</button>
            </div>
            <div className='card p-0 radius_card mt-3'>
              <div className='d-flex justify-content-between p-3'>
                <h5 className='font-weight-bold'>{MyForm.title}</h5>

                <a className='edit_job_post'><span className="material-icons" onClick={e => setCount(0)} >mode_edit</span></a>
              </div>
              <hr className='m-0' />
              <div className='p-3'>
                <a className='edit_job_post' style={{ float: "right" }} ><span className="material-icons" onClick={e => setCount(3)} >mode_edit</span></a>
                <p className='mb-1 fw-600'>Project Description:</p>
                <p dangerouslySetInnerHTML={{ __html: MyForm.description }}></p>
                <p className='fw-600'>Attachment</p>
                <div className='d-flex'>
                  {MyForm.documents && MyForm.documents.map((item, index) => (
                    <div className='d-flex me-2'>
                      <img
                        style={{ cursor: "pointer" }}
                        width={40}
                        className="document_image"
                        src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcR5kekkj6iYr_ZSKfB7GRWEJ4q-7_2sruMm5Hbc2haxduVzPhmc1kS-7OKKJDI1FjTEVAg&usqp=CAU"
                        onClick={(e) =>
                          window.open(
                            `${environment.api}images/document/${item}`
                          )
                        }
                      />
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <button type="button" className="btn btn-primary d-none" id='openreveleant' data-toggle="modal" data-target="#OpenModalOf">
        Launch demo modal
      </button>
      <div className="modal fade" data-keyboard="false" data-backdrop="static" id="OpenModalOf" tabIndex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
        <div className="modal-dialog modal-lg modal-dialog-centered" role="document">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="exampleModalLongTitle">Relevant Virtual Assistant</h5>
              {/* <button type="button"  data-dismiss="modal" } >Skip</button> */}

              <button type="button" className=" btn btn-secondary" data-dismiss="modal" onClick={e => Navigate.push("/virtual")} aria-label="Close">
                Skip
              </button>
            </div>
            <div className="modal-body">
              {ReleveantData.map((item) => (
                <div>
                  <div className='d-flex justify-content-between mb-3'>
                    <div className='d-flex align-items-center'>
                      <img src={methodModel.userImg(item.image)} className='reveleant_user' />
                      <div className='ml-3'>
                        <a className='font-weight-bold text-capitalize'>{item.fullName}</a>
                        {/* <p className='mb-1'>{item.description}</p> */}
                        <p className='mt-2 mb-0'>
                          <b>{item.country}</b>
                        </p>
                      </div>
                    </div>
                    <div className='d-flex align-items-center'>
                      <a onClick={e => { item.isShortListed ? HandleRemoveShortlist(e, item.id) : HandleShortlist(e, item.id) }} className='fav_icon_revealeant'><span className={`material-icons `}>{item.isShortListed ? "favorite" : "favorite_border"}</span></a>
                      <button className='btn btn-primary ml-3' data-dismiss="modal" aria-label="Close" onClick={e => Navigate.push(`/virtualdetails/${item.id}?projectid=${GetProjectid}`)}>View Details</button>
                    </div>
                  </div>
                  <div className='d-flex justify-content-between'>
                    <p>{parseFloat(methodModel.CalculatePercentAmount(item.hourlyRate, PlatformData?.platformFee) + item.hourlyRate).toFixed(2)} USD/hr</p>
                    <p>8k+ USD earned</p>
                    <p className='font-weight-bold'><span className='material-icons shield_icon'>shield</span>{item.skillType && item.skillType.name}</p>
                  </div>
                  <p>{item.description}</p>
                  <p className='border-top py-2 mt-4'></p>
                  <b> Skills</b>
                  <div className='d-flex flex-wrap skills-set'>
                    {item.skills && item.skills.map((itm, index) => (<span className='gray_badges'>{itm.label}</span>))}
                  </div>

                  {/* <b>Brand Strategy Deliverables</b>
    <div className='d-flex'>
      <span className='gray_badges'>  
      Marketing</span>
    </div> */}
                  <hr />
                </div>

              ))}
              {ReleveantData.length == 0 ? <div className='text-danger text-center mt-4 mb-4'>No Relevant Vistual Assistant</div> : null}
            </div>

          </div>
        </div>
      </div>



      <button type="button" id='congratulationmodal' class="btn btn-primary d-none" data-toggle="modal" data-target="#congratulationsModal">
        Open Congratulations Modal
      </button>

      <div class="modal fade" data-keyboard="false" data-backdrop="static" id="congratulationsModal" tabindex="-1" role="dialog" aria-labelledby="congratulationsModalLabel" aria-hidden="true">
        <div class="modal-dialog modal-dialog-centered" role="document">
          <div class="modal-content">
            <div class="modal-header">

              <button type="button" class="close" data-dismiss="modal" onClick={e => Navigate.push('/virtual')} aria-label="Close">
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div class="modal-body">
              <div className='text-center congo-modal'>
                <img src="/assets/img/tick-grey.png" />
                <h5 class="modal-title pt-3 pb-0" id="congratulationsModalLabel">Congratulations!</h5>
                <p className='mb-0 mt-1'> Your job has been successfully posted.</p>
              </div>
            </div>
            <div class=" text-center mx-auto mb-5 mt-3">
              <button type="button" class="btn btn-secondary" data-dismiss="modal" onClick={e => document.getElementById("openreveleant").click()}>Explore relevant Virtual Assistant</button>
            </div>
          </div>
        </div>
      </div>

    </>
  );
};

export default JobDetails;