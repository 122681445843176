import { useEffect, useState } from 'react';

export default function CommonActiveModal({show,confirm,setShow,status}) {
    const [ShowModal,setShowModal]=useState("none")
    useEffect(()=>{
    setShowModal(show);
    },[show])
  return (
    <div> 
  
<div className={`modal  d-${show}`}  tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
  <div  className="modal-dialog">
    <div  className="modal-content p-5">
      {/* <div  className="modal-header">
        <h5  className="modal-title" id="exampleModalLabel">{status}</h5>
        <button type="button"  className="close" onClick={e=>setShow("none")} data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div> */}
      <div  className="modal-body text-center de-modal"> 
      <img src = "/assets/img/question.png" />
      <h5 className='mb-3'>Are you sure you want to {status=="active"?"Active":status=="deactivate"?"Deactivate":status} this?</h5>
      
      </div>
      <div className='text-center'>
      <button type="button" onClick={e=>setShow("none")}  className="btn btn-secondary mr-3" data-dismiss="modal">Cancel</button>
        <button type="button" onClick={e=>confirm()}  className="btn btn-primary">Confirm</button>
      </div>
      
    </div>
  </div>
</div>
    </div>
  )
}
