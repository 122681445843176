import { useState } from "react";
import methodModel from "../../../methods/methods";
import { Link } from "react-router-dom";
import "./style.scss";
import ApiClient from "../../../methods/api/apiClient";
import { useSelector } from "react-redux";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import GooglePlaceAutoComplete from "../../common/GooglePlaceAutoComplete";
import addressModel from "../../../models/address.model";
import TimezoneSelect from 'react-timezone-select'
import Header from '../../../../src/components/global/header';
import environment from "../../../environment";


const Html = ({
  handleSubmit,
  setForm,
  form,
  getError,
  uploadImage,
  submitted,
  setProtofolioError,
  images,
  setimages,
  ProtofolioError,
  setAddressSellected,
  addressSellected,
}) => {

  const [DoumentUploadLoading, setDocumentUploadLoading] = useState(false);
  const [documents, setdocuments] = useState([]);
  const user = useSelector((state) => state.user);

  const mobilenoformat = (el) => {
    if (el) {
      let numberWithoutDialCode = el.toString().slice(form?.dialCode?.length)
      return form?.dialCode + " " + numberWithoutDialCode;
    }
  }

  const imageResult = (e) => {
    if (e.target.files.length > 0) {
      setDocumentUploadLoading(true);
      const files = e.target.files;
      const newdata = new FormData();
      let newarray = images;
      let imgarrays = [];
      let i = 0;
      let original = [];
      for (let items of files) {
        imgarrays.push(items);
      }
      for (let item of imgarrays) {
        let file = files.item(i);
        ApiClient.multiImageUpload("single/documents?modelName=document", {
          data: file,
        }).then((res) => {
          if (res.success) {
            const path = res?.data?.imagePath;
            newarray.push(path);
            original.push(path);
            setDocumentUploadLoading(false);
          } else {
            setDocumentUploadLoading(false);
            // toast.error({ message: "Some error Occurred ! Try Again!" });
          }
        });

        i++;
      }
      setProtofolioError(false);
      setdocuments(newarray);
      setimages(newarray);
    } else {
      // toast.error({ message: "Please Upload the Documents" });
    }
  };
  //  For Deleting the Document
  const HanldDocumentDelete = (e, index) => {
    e.preventDefault();
    const array = [...images];
    array.splice(index, 1);
    setimages(array);
  };
  const addressResult = async (e) => {
    let address = {}
    if (e.place) {
      address = addressModel.getAddress(e.place)
      setAddressSellected(true)
    } else {
      setAddressSellected(false)
    }
    setForm(prev => ({
      ...prev,
      address: e.value,
      country: address.country || '',
      city: address.city || '',
      state: address.state || '',
      zipcode: address.zipcode || '',
      lat: address.lat || '',
      lng: address.lng || ''
    }))
    if (e.place) {
      // setTimezoneLoader(true)
      const apires = await addressModel.gettimeZone(e.place)
      // setTimezoneLoader(false)
      setForm(prev => ({
        ...prev,
        address: e.value,
        country: address.country || '',
        city: address.city || '',
        state: address.state || '',
        zipcode: address.zipcode || '',
        lat: address.lat || '',
        lng: address.lng || '',
        // companytimezone: apires?.data?.timeZoneId || ''
      }))
    }
  }


  return (
    <>
      <Header />
      <>
        <div className="profile-page pprofile1 pt-5">
          <div className="container  ">
            <div className="d-flex justify-content-between align-items-center mb-3">
              <h3 className="ViewUser">Basic Information</h3>
            </div>
            <form className="form-row" onSubmit={handleSubmit}>
              <div className="main_profile_page right_profile">
                <div className="row">
                  <div className="col-md-12">
                    <div className="profile_img_side">
                      <img
                        src={methodModel.userImg(form && form.image)}
                        className="profileImage"
                      />

                      <div>
                        <label className="new_images edit ml-3">
                          <input
                            id="bannerImage"
                            type="file"
                            className="d-none "
                            accept="image/*"
                            value={form.baseImg ? form.baseImg : ""}
                            onChange={(e) => {
                              uploadImage(e);
                            }}
                          />
                          <span className="uploader" title="Upload">
                            <i className="material-icons">camera_alt</i>
                          </span>
                        </label>
                      </div>
                      <div className="changes_image">
                        <div>
                          {form.image ? (
                            <label
                              className="deleticon  delete "
                              onClick={(e) => setForm({ ...form, image: "" })}
                            >

                            </label>
                          ) : (
                            <></>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-12">
                    <div className="right_profile">
                      <div className="row">
                        <div className="col-md-6 mb-4">
                          <label className="label_profile">Name<span className='text-danger'>*</span></label>
                          <div>
                            <input
                              type="text"
                              className="form-control"
                              placeholder="Enter Name"
                              value={form.fullName ? form.fullName : ""}
                              onChange={(e) =>
                                setForm({ ...form, fullName: e.target.value })
                              }
                              required
                            />
                          </div>
                        </div>

                        <div className="col-md-6 mb-4">
                          <label className="label_profile">Email<span className='text-danger'>*</span></label>
                          <div>
                            <input
                              type="email"
                              className="form-control"
                              placeholder="Enter Email"
                              value={form.email ? form.email : ""}
                              disabled
                            />
                          </div>
                        </div>
                        <div className="col-md-6 mb-4">
                          <label className="label_profile">TimeZone<span className='text-danger'>*</span></label>
                          <div>
                            <TimezoneSelect
                              value={form.timeZone}
                              onChange={e => setForm({ ...form, timeZone: e })}
                              required
                            />
                          </div>
                        </div>
                        <div className="col-md-6 mb-4">
                          <label className="label_profile">Address<span className='text-danger'>*</span></label>
                          <div className="m_address" >
                            <GooglePlaceAutoComplete
                              value={form.address}
                              result={addressResult}
                              id="address"
                              placeholder=""
                            />
                            {form.address == "" && submitted ? <div className="invalid-feedback d-block">Please Select Location Suggestion</div> : <></>}
                          </div>
                        </div>
                        <div className="col-md-6 mb-4">
                          <label className="label_profile">Mobile No <span className='text-danger'>*</span></label>
                          <div className="phoneInput_cls d-flex input_main">

                            <PhoneInput
                              value={mobilenoformat(form.mobileNo)}
                              country={'us'}
                              countryCodeEditable={false}
                              enableSearch={true}
                              placeholder=""
                              onChange={(phone, country) => {
                                setForm({ ...form, dialCode: country.dialCode })
                              }}
                              required
                            />

                            <input
                              type="text"
                              className="form-control pssd"
                              placeholder="Mobile No."
                              value={mobilenoformat(form && form.mobileNo || '')}
                              maxLength={13}
                              onChange={(e) =>
                                setForm({
                                  ...form,
                                  mobileNo: methodModel.isNumber(e),
                                })
                              }
                              required
                            />


                          </div>
                        </div>
                        <div className="col-md-6 mb-4">
                          <label className="label_profile">Role<span className='text-danger'>*</span></label>
                          <div>
                            <input
                              type="text"
                              className="form-control"
                              placeholder="Enter Role"
                              value={form.role}
                              disabled
                            />
                          </div>
                        </div>

                        {/* new */}
                        {user.role && user.role.id == environment.VirtualId ? <div className="col-md-6 mb-4">
                          <label className="label_profile">
                            Hourly Rate{" "}<span className='text-danger'>*</span>
                          </label>
                          <span className="dolaricon">USD</span>
                          <div className="d-flex">


                            <div className="input-group mb-3">
                              <div className="input-group-prepend">
                                <span className="input-group-text">USD</span>
                              </div>
                              <input
                                type="text"
                                placeholder="Hourly Rate"
                                className="form-control"
                                aria-label="Amount (to the nearest dollar)"
                                value={form.hourlyRate}
                                onKeyPress={e => methodModel.InputNumber(e)}
                                onChange={(e) =>
                                  setForm({
                                    ...form,
                                    hourlyRate: e.target.value,
                                  })
                                }
                                required={
                                  user.role &&
                                  user.role.id == environment.VirtualId
                                }
                              />
                              <div className="input-group-append">
                                <span className="input-group-text">/hr</span>
                              </div>
                            </div>
                          </div>
                        </div> : null}
                        {user.role &&
                          user.role.id == environment.VirtualId ? (
                          <>
                            <div className="col-md-6 mb-4">
                              <label className="label_profile">Experience<span className='text-danger'>*</span></label>
                              <div>
                                <select className="form-control" value={form?.experience} onChange={e => setForm({ ...form, experience: e.target.value })} required>
                                  <option value="">Please Select</option>
                                  <option value="intermediate">Intermediate</option>
                                  <option value="advance">Advance</option>
                                  <option value="expert">Expert</option>
                                  <option value="entry">Entry</option>


                                </select>
                              </div>
                            </div>
                            {" "}
                            <div className="col-md-12 mb-2">
                              <label className="label_profile">Description<span className="text-danger">*</span></label>
                              <textarea
                                className="form-control"
                                value={form.description}
                                rows={4}
                                onChange={e => setForm({ ...form, description: e.target.value })}
                                required={user.role && user.role.id == environment.VirtualId}
                              >

                              </textarea>
                            </div>
                            {/* <div className="col-md-12 mt-3">
                            <h3 className="Portfolioheding">Portfolio</h3>
                            <hr />
                          </div>
                          <br />
                          <div className="col-md-12 mt-3">
                            <label>URL</label>
                            <input
                              className="form-control"
                              type="url"
                              name="portfolioUrl"
                              onChange={(e) =>
                                setForm({
                                  ...form,
                                  portfolioUrl: e.target.value,
                                })
                              }
                              value={form.portfolioUrl}
                            />
                          </div>
                          <div className="col-md-12 mt-3">
                            <label>Document</label>
                            <div
                              className={`profile_btn_portfolio `}
                            >
                              <label className="add_portfolio edit ml-3">
                                <input
                                  id="bannerImage"
                                  type="file"
                                  multiple={true}
                                  className="d-none"
                                  // accept="image/*"
                                  onChange={(e) => imageResult(e)}
                                />
                                <span className="add_portfolio">
                                  <i  className="material-icons add_port">add</i>
                                </span>
                              </label>
                            </div>
                          </div>
                          <div>
                            <br />
                            <div  className="imagesRow mt-3 mr-5 d-flex flex-wrap">
                              {DoumentUploadLoading == true ? (
                                <div className="text-success">
                                  Uploading...{" "}
                                  <i className="fa fa-spinner fa-spin"></i>
                                </div>
                              ) : (
                                images.map((item, index) => (
                                  <div>
                                    <p className="text-capitalize">
                                      <img
                                        style={{ cursor: "pointer" }}
                                        width={40}
                                        height={40}
                                        className="document_image"
                                        src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcR5kekkj6iYr_ZSKfB7GRWEJ4q-7_2sruMm5Hbc2haxduVzPhmc1kS-7OKKJDI1FjTEVAg&usqp=CAU"
                                        onClick={(e) =>
                                          window.open(
                                            `${environment.api}images/document/${item}`
                                          )
                                        }
                                      />
                                      <i
                                        className="fa fa-trash text-danger shadow-danger delet_icon"
                                        style={{ cursor: "pointer" }}
                                        onClick={(e) =>
                                          HanldDocumentDelete(e, index)
                                        }
                                      ></i>
                                    </p>
                                  </div>
                                ))
                              )}
                            </div>
                          </div>
                          {ProtofolioError ? (
                            <div className="text-danger text-center mt-3 ml-5">
                              Please enter Url or Upload Documents{" "}
                            </div>
                          ) : null} */}
                          </>
                        ) : null}

                        {/* end */}
                        {/* <div className='col-md-4 mb-4'>
  <label className='label_profile'>Hourly Rate </label>
            <div>
            <input
                  type="number"
                  className="form-control"
                  placeholder="Hourly Rate"
                  value={form.hourlyRate}
                  
                />
            </div>
  </div> */}

                        <div className="col-md-12 text-right">
                          <div className="mt-3">
                            <Link to="/profile" className="btn btn-primary reset">
                              Discard
                            </Link>
                            <button
                              type="submit"
                              className="btn btn-primary edit ml-3"
                            >
                              Update
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>

      </>
    </>
  );
};

export default Html;
