import { useEffect, useState } from "react";
import loader from "../../methods/loader";
import ApiClient from "../../methods/api/apiClient";
import { useHistory } from "react-router-dom";
import "./style.scss";
import Header from "../../components/global/header";
import Invite from "../InviteFolder/Invite";
import moment from "moment";
import { useSelector } from "react-redux";
import environment from "../../environment";

export default function PerposalList() {
  const user = useSelector((state) => state.user);
  const Navigate = useHistory();
  const [TotalActivePurposal, setTotalActivePurposal] = useState(0);
  const [ActivePurposal, setActivePurposal] = useState([]);
  const GetActivePuposals = () => {
    const filter = {
      employerId:
        user.role && user.role.id == "64e83a928599356bddc2fa00" ? "" : user.id,
      freelancerId:
        user.role && user.role.id == "64e83a928599356bddc2fa00" ? user.id : "",
      status: "accepted",
    };
    loader(true);
    ApiClient.get(`invite_ApplyJobList`, filter).then((res) => {
      if (res.success) {
        setActivePurposal(res.data);
        setTotalActivePurposal(res.total);
      }
      loader(false);
    });
  };

  useEffect(() => {
    GetActivePuposals();
  }, []);

  const GetPostedTime = (date) => {
    const result = moment(date).fromNow();
    // const data = new Date(date).getMilliseconds();
    // const result = moment.utc(new Date(date)).local().startOf("date").fromNow();
    if (result == "an hour ago") {
      return "1hr ago";
    } else {
      return result;
    }
  };

  const [ApplyData, setApplyData] = useState([]);
  const [ApplyTotal, setApplyTotal] = useState(0);
  const GetApplyJobs = () => {
    loader(true);
    const ApplyJobFilter = {
      freelancerId:
        user.role && user.role.id == "64e83a928599356bddc2fa00" ? user.id : "",
      employerId:
        user.role && user.role.id == "64e5cdfc5bb9a041a39046d5" ? user.id : "",
    };
    ApiClient.get(`applyJobs`, ApplyJobFilter).then((res) => {
      if (res.success) {
        setApplyData(res.data);
        setApplyTotal(res.total);
      }
      loader(false);
    });
  };
  useEffect(() => {
    GetApplyJobs();
  }, []);

  const [OffersData, setOffersData] = useState([]);
  const [OffersLength, setOffersLength] = useState(0);
  const GetOffers = () => {
    loader(true);
    ApiClient.get(`contracts?addedBy=${user.id}&status=pending`).then((res) => {
      if (res.success) {
        const data = res.data;
        setOffersData(res.data);
        setOffersLength(res.total);
      }
    });
  };
  useEffect(() => {
    GetOffers();
  }, []);
  return (
    <>
      <Header />
      <div className="container mt-4">
        <div className="row">
          <div className="p-3">
            <h4 className="mb-4 hedding_common">My Proposals</h4>
            <ul
              className="nav nav-pills mb-3  nav_barbs"
              id="pills-tab"
              role="tablist"
            >
              <li className="nav-item" role="presentation">
                <button
                  className="nav-link active"
                  id="pills-home-tab"
                  data-bs-toggle="pill"
                  data-bs-target="#pills-home"
                  type="button"
                  role="tab"
                  aria-controls="pills-home"
                  aria-selected="true"
                >
                  Active
                </button>
              </li>
              {/* <li className="nav-item" role="presentation">
                <button
                  className="nav-link"
                  id="pills-profile-tab"
                  data-bs-toggle="pill"
                  data-bs-target="#pills-profile"
                  type="button"
                  role="tab"
                  aria-controls="pills-profile"
                  aria-selected="false"
                >
                  Acrhived
                </button>
              </li> */}
            </ul>
          </div>
          <div className="tab-content" id="pills-tabContent">
            <div
              className="tab-pane fade show active"
              id="pills-home"
              role="tabpanel"
              aria-labelledby="pills-home-tab"
            >
              {/* {user.role&&user.role.id=="64e5cdfc5bb9a041a39046d5"? */}
              <div className="card p-md-3 p-1 mb-4">
                <h4 className="p-3 h3font">Offers ({OffersLength})</h4>
                {OffersData.map((item, index) => (
                  <div
                    key={item.id}
                    className="p-3 border-top d-flex align-items-center flex-wrap new-wrap"
                  >
                    <div className="request mr-5">
                      <p className="mb-0">
                        Created on{" "}
                        {moment(item.createdAt).format("MM/DD/YYYY")}
                      </p>
                      <small>{GetPostedTime(item.createdAt)}</small>
                    </div>
                    <p
                      className="bold_msg text-primary"
                      style={{ cursor: "pointer" }}
                      onClick={(e) => Navigate.push(`/offer/${item.id}`)}
                    >
                      {item?.project_details?.name || item?.project_details?.title}(
                      {user.role && user.role.id == "64e83a928599356bddc2fa00"
                        ? item.contractFrom_details &&
                        item.contractFrom_details.fullName
                        : item.contractTo_details &&
                        item.contractTo_details.fullName}
                      ){" "}
                    </p>
                  </div>
                ))}
                {/* <div className="p-3 border-top d-flex align-items-center flex-wrap new-wrap">
          <div className="request mr-5">
            <p className="mb-0">Received Aug 26,2023</p>
            <small>1 week ago</small>
          </div>
          <p className="bold_msg text-primary">Build screen-based eye tracker desktop application</p>
        </div> */}
              </div>
              {/* :null} */}
              <div className="card p-md-3 p-1 mb-4">
                <Invite />
                {/* <div className="p-3 border-top d-flex align-items-center flex-wrap new-wrap">
          <div className="request mr-5">
            <p className="mb-0">Received Aug 26,2023</p>
            <small>1 week ago</small>
          </div>
          <p className="bold_msg text-primary">Build screen-based eye tracker desktop application</p>
        </div>

        <div className="p-3 border-top d-flex align-items-center flex-wrap new-wrap">
          <div className="request mr-5">
            <p className="mb-0">Received Aug 26,2023</p>
            <small>1 week ago</small>
          </div>
          <p className="bold_msg text-primary">Build screen-based eye tracker desktop application</p>
        </div> */}
              </div>
              <div className="card p-md-3 p-1 mb-4">
                <h4 className="p-3 h3font">
                  Accepted Invitation ({TotalActivePurposal})
                </h4>
                {ActivePurposal.map((item, index) => (
                  <div
                    key={item.id}
                    className="p-3 border-top d-flex align-items-center flex-wrap new-wrap"
                  >
                    <div className="request mr-5">
                      <p className="mb-0">
                        Received {moment(item.createdAt).format("MM/DD/YYYY")}
                      </p>
                      <small>{GetPostedTime(item.createdAt)}</small>
                    </div>
                    <p
                      className="bold_msg text-primary"
                      style={{ cursor: "pointer" }}
                      onClick={(e) => {
                        item.type == "invite"
                          ? Navigate.push(
                            `/proposaldetail/${item.inviteFreelancer &&
                            item.inviteFreelancer._id
                            }`
                          )
                          : Navigate.push(
                            `/details/${item.applyJob && item.applyJob._id}`
                          );
                      }}
                    >
                      {item.type == "invite"
                        ? item.inviteFreelancer_project &&
                        (item?.inviteFreelancer_project?.name || item?.inviteFreelancer_project?.title)
                        : item.applyJob_project &&
                        (item?.applyJob_project?.name || item?.applyJob_project?.title)}{" "}
                      {user.role && user.role.id == "64e5cdfc5bb9a041a39046d5"
                        ? ` (${item.addedBy_details &&
                        item?.addedBy_details?.fullName
                        })`
                        : null}{" "}
                    </p>
                  </div>
                ))}

                {/* <div className="p-3 border-top d-flex align-items-center flex-wrap new-wrap">
          <div className="request mr-5">
            <p className="mb-0">Received Aug 26,2023</p>
            <small>1 week ago</small>
          </div>
          <p className="bold_msg text-primary">Build screen-based eye tracker desktop application</p>
        </div> */}
              </div>
              {user.role && user.role.id == "64e83a928599356bddc2fa00" ? (
                <div className="card p-md-3 p-1 mb-4">
                  <h4 className="p-3 h3font">Submitted proposals ({ApplyTotal})</h4>
                  {ApplyData.map((item, index) => (
                    <div
                      key={item.id}
                      className="p-3 border-top d-flex align-items-center flex-wrap new-wrap"
                    >
                      <div className="request mr-5">
                        <p className="mb-0">
                          Submitted on{" "}
                          {moment(item.createdAt).format("MM/DD/YYYY")}
                        </p>
                        <small>{GetPostedTime(item.createdAt)}</small>
                      </div>
                      <p
                        className="bold_msg text-primary"
                        style={{ cursor: "pointer" }}
                        onClick={(e) =>
                          Navigate.push(
                            `/details/${item.id}?comethrough=submit`
                          )
                        }
                      >
                        {item?.project_details?.name || item?.project_details?.title}
                      </p>
                    </div>
                  ))}
                </div>
              ) : null}
            </div>
            <div
              className="tab-pane fade"
              id="pills-profile"
              role="tabpanel"
              aria-labelledby="pills-profile-tab"
            >
              <div className="card p-md-3 p-1 mb-4">
                <h4 className="p-3 h3font">Acrhived (0)</h4>
                {/* <div className="p-3 border-top d-flex align-items-center flex-wrap new-wrap">
          <div className="request mr-5">
            <p className="mb-0">Received Aug 26,2023</p>
            <small>1 week ago</small>
          </div>
          <p className="bold_msg text-primary">Build screen-based eye tracker desktop application</p>
        </div>

        <div className="p-3 border-top d-flex align-items-center flex-wrap new-wrap">
          <div className="request mr-5">
            <p className="mb-0">Received Aug 26,2023</p>
            <small>1 week ago</small>
          </div>
          <p className="bold_msg text-primary">Build screen-based eye tracker desktop application</p>
        </div> */}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
