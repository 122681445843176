// ScheduleTable.js
/*
 * @Author: Ghost
 * @Email: Ghost@yopmail.com
 * @Date: 2024-02-13 11:30:23
 * @Last Modified by: Someone
 * Email: anilgodara4239@gmail.com
 * @Last Modified time: 2024-02-13 11:32:39
 * */
import { useEffect, useState } from "react";
import "./style.scss";
import { useDispatch, useSelector } from "react-redux";
import ApiClient from "../../methods/api/apiClient";
import { login_success } from "../../actions/user";
import environment from "../../environment";
import Header from "../../components/global/header";
import { toast } from "react-toastify";
import TimezoneSelect from "react-timezone-select";

const ScheduleTable = () => {
  const dispatch = useDispatch();
  const user = useSelector((state) => state.user);
  const [schedule, setSchedule] = useState(environment.defaultSchedule);

  const handleChange = (day, field, value) => {
    if (field == "startTime") {
      UpdaterFunction(value);
    }
    if (field == "startTime" || field == "endTime") {
      if (value == "Off") {
        setSchedule((prevSchedule) =>
          prevSchedule.map((entry) =>
            entry.day === day
              ? {
                  ...entry,
                  startTime: value,
                  endTime: value,
                  lunchBreak: value,
                }
              : entry
          )
        );
        return false;
      }
    }
    setSchedule((prevSchedule) =>
      prevSchedule.map((entry) =>
        entry.day === day ? { ...entry, [field]: value } : entry
      )
    );
  };

  function changeStartingDay(newStartingDay) {
    // Find the index of the new starting day in the array
    const startingDayIndex = schedule.findIndex(
      (day) => day.day === newStartingDay
    );

    if (startingDayIndex !== -1) {
      const rotatedSchedule = [
        ...schedule.slice(startingDayIndex),
        ...schedule.slice(0, startingDayIndex),
      ];

      setSchedule([...rotatedSchedule]);
    } else {
      // Handle the case where the new starting day is not found in the array
      console.error(`Invalid starting day: ${newStartingDay}`);
    }
  }

  useEffect(() => {
    if (user.timeTable && user.timeTable?.length > 0) {
      setSchedule(user.timeTable);
    }
  }, [user.timeTable]);

  const [TimeZone, setTimeZone] = useState(
    user.globallyTimeZone
      ? user.globallyTimeZone
      : user.timeZone
      ? user.timeZone
      : ""
  );
  const HandleTimeTableSubmit = (e) => {
    e.preventDefault();
    ApiClient.put("edit/profile", {
      id: user.id,
      timeTable: schedule,
      globallyTimeZone: TimeZone,
    }).then((res) => {
      if (res.success) {
        const updateddata = {
          ...user,
          timeTable: schedule,
          globallyTimeZone: TimeZone,
        };
        toast.success("Time Schedule Updated Successfully");
        dispatch(login_success(updateddata));
      }
    });
  };

  const [StartTimeSchedule, setStartTimeSchdule] = useState(
    environment.StarterSchedule
  );
  const [EndTimeSchedule, setEndTimeSchedule] = useState(
    environment.EnderSchedule
  );

  const UpdaterFunction = (selectedStartSchedule) => {
    const startIndex = EndTimeSchedule.indexOf(selectedStartSchedule);

    // Create a new array for the updated end schedule
    const newEndSchedule = [
      ...EndTimeSchedule.slice(startIndex),
      ...EndTimeSchedule.slice(0, startIndex),
    ];

    // Update the state with the new options for End Schedule
    setEndTimeSchedule(newEndSchedule);
  };

  return (
    <>
      <Header />
      <div className=" container mt-5">
        <div className="row">
          <div className="col-md-6 mb-md-0 mb-3">
            <label>Week Start From </label>
            <select
              value={schedule[0]?.day}
              className="form-control"
              onChange={(e) => changeStartingDay(e.target.value)}
            >
              <option value="Sun">Sunday</option>
              <option value="Mon">Monday</option>
              <option value="Tue">Tuesday</option>
              <option value="Wed">Wednesday</option>
              <option value="Thu">Thursday</option>
              <option value="Fri">Friday</option>
              <option value="Sat">Saturday</option>
            </select>
          </div>

          <div className="col-md-6">
            <label>TimeZone</label>
            <TimezoneSelect value={TimeZone} onChange={(e) => setTimeZone(e)} />
          </div>
        </div>
        <div className="col-md-12">
        <div className="policies-page mt-5">
          <div className="table-responsive">
            {" "}
            <table className="table ">
              <thead>
                <tr>
                  <th>Day</th>
                  {schedule.map((entry) => (
                    <th key={entry.day}>{entry.day}</th>
                  ))}
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>Start Time</td>
                  {schedule.map((entry) => (
                    <td key={entry.day}>
                      <select
                        value={entry.startTime}
                        className="form-control widform"
                        onChange={(e) =>
                          handleChange(entry.day, "startTime", e.target.value)
                        }
                      >
                        <option value="Off">Off</option>
                        {StartTimeSchedule.map((item) => (
                          <option value={item}>{item}</option>
                        ))}
                      </select>
                    </td>
                  ))}
                </tr>
                <tr>
                  <td>Lunch Break</td>
                  {schedule.map((entry) => (
                    <td key={entry.day}>
                      <select
                        value={entry.lunchBreak}
                        className="form-control widform"
                        onChange={(e) =>
                          handleChange(entry.day, "lunchBreak", e.target.value)
                        }
                      >
                        <option value="Off">Off</option>
                        {environment.LunchSchedule.map((item) => (
                          <option value={item}>{item}</option>
                        ))}
                      </select>
                    </td>
                  ))}
                </tr>
                <tr>
                  <td>End Time</td>
                  {schedule.map((entry) => (
                    <td key={entry.day}>
                      <select
                        value={entry.endTime}
                        className="form-control widform"
                        onChange={(e) =>
                          handleChange(entry.day, "endTime", e.target.value)
                        }
                      >
                        <option value="Off">Off</option>
                        {EndTimeSchedule.map((item) => (
                          <option
                            disable={
                              new Date(entry.endTime) <=
                              new Date(entry.startTime)
                            }
                            value={item}
                          >
                            {item}
                          </option>
                        ))}
                      </select>
                    </td>
                  ))}
                </tr>
              </tbody>
            </table>
          </div>
        </div>
</div>
        <div className="col-md-12">
        <div className="d-flex justify-content-end mt-4">
        <button
            className="btn btn-primary "
            onClick={(e) => HandleTimeTableSubmit(e)}
            
          >
            Save
          </button>
        </div>
        </div>
        {/* <WeekdayDataTable data={schedule}/> */}
      </div>
    </>
  );
};

export default ScheduleTable;
