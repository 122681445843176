import { useEffect, useState } from 'react';
import parse from 'html-react-parser'

export default function ViewNotice({data,setdata,ModalCloserRef,id}) {
    const [Data,setData]=useState({});
    useEffect(()=>{
       setData(data);
    },[data,id])
  return (
    <div>
      
    
        <div className=" ">
   
            <div className="row mt-1">
              <div className="col-12 mb-3">
                <div className="">
                  <h5 className="mb-2 mod_view_h5">Contract</h5>
                  <div className="view_pop_input">{Data?.projectDetail&&Data?.projectDetail?.name}</div>
               
                </div>
              </div>
              <div className="col-12 mb-3">
                <div className="">
                  <h5 className="mb-2 mod_view_h5 ">Subject</h5>
                  <div className="view_pop_input">{Data?.title}</div>
                 
                </div>
              </div>
              <div className="col-12 mb-3">
                <div className="">
                  <h5 className="mb-2 mod_view_h5 ">Notice Period</h5>
                  <div className="view_pop_input">{Data?.terminateNow=='true'?"Immediately":Data?.notice_days||0}</div>
                </div>
              </div>
              <div className="col-12 mb-3">
                <div className="">
                  <h5 className="mb-2 mod_view_h5">Message</h5>
                
                  <div className="view_textarea">{parse(`${Data.description}`)}</div>
                </div>
              </div>
            </div>
         
        </div> 
    </div>
  )
}
