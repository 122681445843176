import { useEffect, useState } from 'react';
import ApiClient from '../../../methods/api/apiClient';
import loader from '../../../methods/loader';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import environment from '../../../environment';
import { login_success } from '../../../actions/user';

export default function VideoModal({ date }) {
  const user = useSelector(state => state.user);
  const dispatch = useDispatch();
  const [Video, setVideo] = useState(user.introVideo ? user.introVideo : "");
  const [Audio, setAudio] = useState(user.introAudio ? user.introAudio : "");
  const [VideoUrl, setVideoUrl] = useState(user.introLink ? user.introLink : "");
  const [UploadVideoError, setUploadVideoError] = useState(false);
  const [AudioError, setAudioError] = useState(false);
  const [SubmittedError, setSubmittedError] = useState(false);
  useEffect(() => {
    loader(true)
    setVideo(user.introVideo ? user.introVideo : "");
    setAudio(user.introAudio ? user.introAudio : "");
    setVideoUrl(user.introLink ? user.introLink : "")
    loader(false)
  }, [date])

  const VideoUpload = (e) => {
    let files = e.target.files
    let file = files.item(0)
    if (file) { loader(true) }
    ApiClient.postFormData('uploadvideos', { file: file }).then(res => {
      if (res.success) {
        let newimage = res.data
        const image = newimage?.imagePath[0]
        setVideo(image);
      } else {
      }
      loader(false)
    })
  }


  const AudioUpload = (e) => {
    let files = e.target.files
    let file = files.item(0)
    if (file) {
      loader(true)
    }
    ApiClient.postFormData('uploadaudios', { file: file }).then(res => {
      if (res.success) {
        if (res.data.imagePath) {
          let image = res?.data?.imagePath[0]
          setAudio(image);
        } else {
        }
      }
      loader(false)
    })
  }


  const HandleSubmit = (e) => {
    e.preventDefault();
    setSubmittedError(true);
    if (Video == "" && VideoUrl == "") { return false }
    if (!validateYouTubeUrl(VideoUrl) && !Video) { toast.error("Please enter a valid Youtube Video URL"); return false }
    ApiClient.put(`edit/profile`, { id: user.id, introVideo: Video, introLink: VideoUrl }).then(res => {
      if (res.success) {
        document.getElementById("closeVideomodal").click();
        const data = { ...user, introVideo: Video, introLink: VideoUrl }
        dispatch(login_success(data))
        toast.success(`Video Introduction ${user.introLink == "" && user.introVideo == "" ? "Added" : "Updated"} Successfully`)
      }
    })
  }


  function validateYouTubeUrl(url) {
    // var url = $('#youTubeUrl').val();
    if (url != undefined || url != '') {
      var regExp = /^.*(youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=|\&v=|\?v=)([^#\&\?]*).*/;
      var match = url.match(regExp);
      if (match && match[2].length == 11) {
        return true
      }
      else {
        return false
        // Do anything for not being valid
      }
    }
    else {
      return false
    }
  }
  const HandleAudioDelete = (e) => {
    e.preventDefault();
    setAudio("");
  }


  const handleDrop = (e) => {
    e.preventDefault();
    const files = e.dataTransfer.files;
    VideoUpload({ target: { files } });
  };

  // Prevent default behavior for drag-over events
  const handleDragOver = (e) => {
    e.preventDefault();
  };
  return (
    <div>
      <div className="modal-body ">
        <form onSubmit={e => HandleSubmit(e)}>
          <div className="row">
            <div className="col-md-12">
              <label className='mt-md-0 mt-3'>Link to your YouTube video</label>
              <input type='url' onChange={e => setVideoUrl(e.target.value)} value={VideoUrl} placeholder="" className="form-control" />
            </div>
            <div className="col-md-12 mt-3 videos">
              <label className="mb-0">Upload video</label>
              {Video == "" ? (
                <div className="profile_btn_portfolio d- " onDrop={handleDrop}
                  onDragOver={handleDragOver}>
                  <label className={`add_portfolio edit ml-3`}>
                    <input
                      id="bannerImage"
                      accept=".mp4"
                      type="file"
                      onChange={(e) => VideoUpload(e)}
                      className="d-none"
                    />
                    <span className="add_portfolio">
                      <i className="material-icons add_port">add</i>
                    </span>
                  </label>
                </div>
              ) : (
                <div className="position-relative">
                  <div className="text-end">
                    <i
                      className="fa fa-trash text-danger videoTrash"
                      onClick={(e) => setVideo("")}
                    ></i>
                  </div>
                  <video
                    width={300}
                    height={150}
                    src={`${environment.api + "images/videos/" + Video}`}
                    controls
                  />

                </div>
              )}
              {SubmittedError && Video == "" && VideoUrl == "" ? (
                <p className="text-danger">Upload Video or enter URL</p>
              ) : null}
            </div>

            <div className='modal-footer pb-0'>
              <button className='btn btn-secondary discard mr-2' data-dismiss="modal" type='button'>Back</button>
              <button type='submit' className='btn btn-primary'>Submit</button>
            </div>
          </div>
        </form>
      </div>
    </div>
  )
}
