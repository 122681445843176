import { useState } from "react";
import "./style.scss";
import ApiClient from "../../methods/api/apiClient";
import { useSelector } from "react-redux";
import environment from "../../environment";
import "react-phone-input-2/lib/style.css";

const Html = ({
  handleSubmit,
  setForm,
  form,
  getError,
  uploadImage,
  submitted,
  setProtofolioError,
  images,
  setimages,
  ProtofolioError,
}) => {
  const [DoumentUploadLoading, setDocumentUploadLoading] = useState(false);
  const [documents, setdocuments] = useState([]);
  const user = useSelector((state) => state.user);

  const imageResult = (e) => {
    if (e.target.files.length > 0) {

      const files = e.target.files;
      const newdata = new FormData();
      let newarray = images;
      let imgarrays = [];
      let i = 0;
      let original = [];
      for (let items of files) {
        imgarrays.push(items);
      }
      for (let item of imgarrays) {
        let file = files.item(i);
        setDocumentUploadLoading(true);
        ApiClient.multiImageUpload("single/documents?modelName=document", {
          file: file,
        }).then((res) => {
          if (res.success) {
            const path = res?.data?.imagePath;
            newarray.push(path);
            original.push(path);
            setdocuments(new Date())
            if (files.length == images.length) {
              setDocumentUploadLoading(false)
            }
          } else {
            setDocumentUploadLoading(false);
            // toast.error({ message: "Some error Occurred ! Try Again!" });
          }
        });

        i++;

      }
      setProtofolioError(false);
      setdocuments(newarray);
      setimages(newarray);
    } else {
      // toast.error({ message: "Please Upload the Documents" });
    }
  };
  //  For Deleting the Document
  const HanldDocumentDelete = (e, index) => {
    e.preventDefault();
    const array = [...images];
    array.splice(index, 1);
    setimages(array);
  };

  // Handle file selection through drag-and-drop
  const handleDrop = (e) => {
    e.preventDefault();
    const files = e.dataTransfer.files;
    imageResult({ target: { files } });
  };

  // Prevent default behavior for drag-over events
  const handleDragOver = (e) => {
    e.preventDefault();
  };
  return (
    <>
      <>
        <div className="pprofile1 all_portofolio  p-0">

          <form className="form-row mx-auto" onSubmit={handleSubmit}>
            <div className="main_profile_page">
              <div className="row">
                {/* new */}

                {user.role &&
                  user.role.id == environment.VirtualId ? (
                  <>
                    {" "}


                    <div className="col-md-12 ">
                      <label>URL</label>
                      <input
                        className="form-control mt-2 "
                        type="url"
                        name="portfolioUrl"
                        onChange={(e) =>
                          setForm({
                            ...form,
                            portfolioUrl: e.target.value,
                          })
                        }
                        value={form.portfolioUrl}
                      />
                    </div>
                    <div className="col-md-12 mt-3">
                      <label>Portfolio Document</label>
                      <div
                        className={`profile_btn_portfolio `}
                        onDrop={handleDrop}
                        onDragOver={handleDragOver}
                      >
                        <label className="add_portfolio edit ml-3">
                          <input
                            id="bannerImage"
                            type="file"
                            multiple={true}
                            className="d-none"
                            // accept="image/*"
                            onChange={(e) => imageResult(e)}
                          />
                          <span className="add_portfolio">
                            <i className="material-icons add_port">add</i>
                          </span>
                        </label>
                      </div>
                    </div>
                    <div>

                      <div className="imagesRow d-flex flex-wrap">
                        {DoumentUploadLoading == true ? (
                          <div className="text-success w-100 d-flex justify-content-end">
                            Uploading...{" "}
                            <i className="fa fa-spinner fa-spin"></i>
                          </div>
                        ) : (
                          images.map((item, index) => (
                            <div>
                              <p className="text-capitalize mb-0 position-relative ">
                                <img
                                  style={{ cursor: "pointer" }}
                                  width={40}
                                  className="document_image"
                                  src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcR5kekkj6iYr_ZSKfB7GRWEJ4q-7_2sruMm5Hbc2haxduVzPhmc1kS-7OKKJDI1FjTEVAg&usqp=CAU"
                                  onClick={(e) =>
                                    window.open(
                                      `${environment.api}images/document/${item}`
                                    )
                                  }
                                />
                                <i
                                  className="fa fa-times-circle  closeporto"
                                  style={{ cursor: "pointer" }}
                                  onClick={(e) =>
                                    HanldDocumentDelete(e, index)
                                  }
                                ></i>
                              </p>
                            </div>
                          ))
                        )}
                      </div>
                    </div>
                    {ProtofolioError ? (
                      <div className="text-danger text-center mt-3 ml-5">
                        Please enter Url or Upload Documents{" "}
                      </div>
                    ) : null}
                  </>
                ) : null}



                <div className="col-md-12 text-right border-top  mt-3 pt-3 " >
                  <div className="d-flex gap-2 align-items-center justify-content-end ">
                    <button className="btn btn-secondary discard" data-dismiss="modal">
                      Back
                    </button>
                    <button
                      type="submit"
                      className="btn btn-primary edit "
                    >
                      Update
                    </button>
                  </div>

                </div>
              </div>
            </div>
          </form>
        </div>
      </>
    </>
  );
};

export default Html;
