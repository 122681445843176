import { useEffect, useReducer, useRef, useState } from "react";
import { toast } from "react-toastify";
import ApiClient from "../../methods/api/apiClient";
import { useDispatch, useSelector } from "react-redux";
import "react-phone-input-2/lib/style.css";
import Header from "../../components/global/header";
import loader from "../../methods/loader";
import moment from "moment";
import Pagination from "react-js-pagination";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import DatePickers from "react-datepicker";
import methodModel from "../../methods/methods";
import environment from "../../environment";
import { ConnectSocket } from "../../components/global/SocketNetwork/ConnectSocket";
import { Formik } from "formik";
import {
  StartTimeTrackingAction,
  StopTimeTrackingAction,
} from "../../actions/TimeTracking";
import datepipeModel from "../../models/datepipemodel";
import { FaCheck, FaToggleOff, FaToggleOn } from "react-icons/fa";



const Contract = () => {
  const [DisabledSaveButton, setDisabledSaveButton] = useState(false);
  const user = useSelector((state) => state.user);
  const searchParams = new URLSearchParams(window.location.search);
  const ManageRole = searchParams.get(`manageRole`);
  const Navigate = useHistory();
  const [SearchData, setSearchData] = useState("");
  const [Preview, setPreview] = useState(false);
  const [filters, setfilters] = useState({
    page: 1,
    count: 50,
    search: "",
    status: "accepted",
    addedBy: ManageRole == "direct" ? "" : user.id,
    directmanagerId: ManageRole == "direct" ? user.id : "",
  });
  useEffect(() => {
    if (user?.id || user?._id) {
      ApiClient.get(`billing/ongoing?userId=${user?._id || user?.id}`).then(res => {
        if (res.success) {
          let FindData = res?.data?.find((item) => !item?.endTime);
          if (FindData) {
            localStorage.setItem('trackingid', FindData?.contractId)
            localStorage.setItem('startTrackingTime', datepipeModel.ReverseTimetoObj((FindData?.startTime).toString()));
            localStorage.setItem('ActualTrackingid', FindData?.id);
            localStorage.setItem('memo', FindData?.memo)
          } else {
            localStorage.removeItem('trackingid');
            localStorage.removeItem('startTrackingTime');
            localStorage.removeItem('ActualTrackingid');
            localStorage.removeItem('memo')
          }
        }
      })
    }
  }, [])
  const [total, settotal] = useState(0);
  const [RatingNumber, setRatingNumber] = useState(0);
  const [SelectedContractId, setSelectedContractId] = useState(null);

  const [clockData, setClockdata] = useState({});
  const TimeTraks = useSelector((state) => state.TimeTrackingReducer);
  let TimeTrackInterval = useRef(null);
  const TrackerStatus = localStorage.getItem(`trackingid`);
  const [TrackStatus, setTrackStatus] = useState(
    localStorage.getItem(`trackingid`) ? "true" : "false"
  );
  const [TimerStatus, setTimerStatus] = useState("");
  const [TotalBillingTime, setTotalBillingTime] = useState(0);
  const dispatch = useDispatch();
  const ProjectDataRef = useRef();

  useEffect(() => {
    ConnectSocket.on(`add-billing`, (data) => {
      const newdata = data.data;
      // return
      if (
        ProjectDataRef?.current?.find(
          (itm) => itm?._id == newdata.contractId
        ) &&
        newdata?.addedBy == user?.id
      ) {
        localStorage.setItem("ActualTrackingid", newdata?._id);
        localStorage.setItem("memo", newdata?.memo);
      }
    });

    ConnectSocket.on(`get-totalBilling`, (data) => {
      const newdata = !data.data?.length ? [] : data?.data;
      let TotalTime = "0";
      let Id = "";
      const IdArrays = ProjectDataRef?.current?.map((item) => item?._id);
      newdata.map((item, index) => {
        if (IdArrays?.includes(item?._id)) {
          TotalTime = item.totalDurationInSeconds;
          Id = item?._id;
          const PreviousData = ProjectDataRef?.current;
          const FindIndex = ProjectDataRef.current?.findIndex(
            (itemo) => item?._id == itemo?._id
          );
          PreviousData[FindIndex]["totalDurationInSeconds"] = TotalTime;
          PreviousData[FindIndex]["billingStart"] = true;

          ProjectDataRef.current = PreviousData;
          setProjectsData([...PreviousData]);
        }
      });
    });
  }, []);

  useEffect(() => {
    if (localStorage.getItem('trackingid')) {
      StartTimeTrack()
    }
  }, [localStorage.getItem('trackingid')])
  const getAddress = (address) => {
    let lat = "0";
    let lng = "0";
    let aArray = address.address_components;

    const getCountry = () => {
      let value = "";

      aArray.map((item) => {
        if (item.types[0] == "country") {
          value = item.long_name;
        }
      });
      return value;
    };

    const getCity = () => {
      let value = "";
      aArray.map((item) => {
        if (item.types[0] == "locality") {
          value = item.long_name;
        }
      });
      return value;
    };

    const getLocality = () => {
      let value = "";
      aArray.map((item) => {
        if (item.types[0] == "sublocality_level_2") {
          value = item.long_name;
        }
      });
      return value ? value : getSubLocality();
    };

    const getSubLocality = () => {
      let value = "";
      aArray.map((item) => {
        if (item.types[0] == "locality") {
          value = item.long_name;
        }
      });
      return value;
    };

    const getState = () => {
      let value = "";
      aArray.map((item) => {
        if (item.types[0] == "administrative_area_level_1") {
          value = item.long_name;
        }
      });
      return value;
    };

    const getPostalCode = () => {
      let value = "";
      aArray.map((item) => {
        if (item.types[0] == "postal_code") {
          value = item.long_name;
        }
      });
      return value;
    };
    let aaddress = {
      lat,
      lng,
      address: address.formatted_address,
      country: getCountry(),
      state: getState(),
      city: getCity(),
      zipcode: getPostalCode(),
      locality: getLocality(),
    };
    return aaddress;
  };

  const fetchData = (lat, long) => {
    // Make a GET request to your Express API
    return new Promise((resolve, reject) => {
      // let url = `http://${window.location.hostname}:8040/geocode`;
      let url = `https://virtualpal.jcsoftwaresolution.in/geocode`;
      if (window.location.href.includes("https://")) {
        url = `https://${window.location.hostname}/geocode`;
      }
      fetch(`${url}?lat=${lat}&long=${long}`).then((response) => {
        // Assuming your Express API is running on the same host as your React app
        // Check if the response is successful
        if (response.ok) {
          try {
            response.json().then((data) => {
              const newdata = data;
              const extractedData = getAddress(newdata);
              // Update the state with the API response data
              const payload = {
                country: extractedData.country || "",
                city: extractedData.city || "",
                state: extractedData.state || "",
                pincode: extractedData.zipcode || "",
                lat: lat || "",
                lng: long || "",
              };
              resolve(payload);
            });
          } catch {
            const payload = {

              lat: lat || "",
              lng: long || "",
            };
            resolve(payload);
          }
        } else {
          const payload = {

            lat: lat || "",
            lng: long || "",
          };
          resolve(payload);
        }
      });
    });
  };

  const StartTrackingFunc = () => {
    const GetAlreadyTrackedStatus = localStorage.getItem("trackingid");

    if (
      GetAlreadyTrackedStatus &&
      GetAlreadyTrackedStatus != "" &&
      GetAlreadyTrackedStatus != clockData?._id
    ) {
      toast.error("");
    } else {
      localStorage.setItem("startTrackingTime", new Date());
      localStorage.setItem("trackingid", `${clockData?._id}`);
      setTrackStatus("true");
      StartTimeTrack();
    }
  };

  const GetTimeDifference = () => {
    const date2 = new Date();
    const date = localStorage.getItem("startTrackingTime") || new Date()
    const timeDifference = date2 - new Date(date);
    // Calculate hours, minutes, and seconds
    const hours = Math.floor(timeDifference / (1000 * 60 * 60));
    const minutes = Math.floor(
      (timeDifference % (1000 * 60 * 60)) / (1000 * 60)
    );
    const seconds = Math.floor((timeDifference % (1000 * 60)) / 1000);
    const newhours = parseInt(hours) < 10 ? "0" + hours : hours;
    const newminutes = parseInt(minutes) < 10 ? "0" + minutes : minutes;
    const newseconds = parseInt(seconds) < 10 ? "0" + seconds : seconds;
    const data = newhours + " : " + newminutes + "m : " + newseconds + " s";
    setTimerStatus(data);
    return data;
  };

  const StartTimeTrack = () => {
    // if (document.hasFocus()) {
    TimeTrackInterval.current = setInterval(() => {
      dispatch(StartTimeTrackingAction(GetTimeDifference()));
    }, 1000);
    // }
  };

  const ClearTimeInterval = () => {
    clearInterval(TimeTrackInterval.current);
    dispatch(StopTimeTrackingAction("1"));
  };
  const [ClockOutForm, setClockOutForm] = useState({ startTime: '', endTime: '' })
  const StopTrackerFunction = (e, type = 'initial') => {
    e.preventDefault();
    if (type == 'final') {
      if (!navigator.onLine) {
        toast.error('Internet Connection is Not available');
        return
      }
      loader(true);
      setTrackStatus("false");
      localStorage.removeItem("timetrack");
      ClearTimeInterval();
      localStorage.removeItem("startTrackingTime");
      setTimerStatus("");
      const EndTimePayload = {
        id: localStorage.getItem("ActualTrackingid"),
        endTime: datepipeModel.TimeConvertToTimezone((new Date(ClockOutForm?.endTime)).toString()),
      };
      console.log(EndTimePayload, "This is the Final payload");
      ConnectSocket.emit(`update-endTime`, EndTimePayload);
      ConnectSocket.emit(`get-totalBilling`, {
        contractId: localStorage.getItem("trackingid"),
      });
      setTimeout(() => {
        localStorage.removeItem("trackingid");
        localStorage.removeItem("ActualTrackingid");
        localStorage.removeItem("memo")
      }, 1000);
      GetAllProjects();
      document.getElementById('closeClockOutmodal3').click()
      loader(false)

    } else {
      setClockOutForm({ startTime: new Date(localStorage.getItem('startTrackingTime')), endTime: new Date() })
      document.getElementById('OpenClockOutModal2').click()
    }
  };

  const [ProjectsData, setProjectsData] = useState([]);

  const GetAllProjects = (p = {}) => {
    const newfilters = { ...filters, ...p };
    loader(true);
    ApiClient.get(`contracts`, newfilters).then((res) => {
      if (res.success) {
        setProjectsData(res.data);
        ProjectDataRef.current = res?.data;
        settotal(res.total);
      }
      loader(false);
    });
  };
  useEffect(() => {
    GetAllProjects();
  }, []);

  const HandleProjectDelete = (id) => {
    if (window.confirm("Do you want to Delete this?")) {
      loader(true);
      ApiClient.delete(`invites?id=${id}`).then((result) => {
        if (result.success) {
          GetAllProjects();
        }
        loader(false);
      });
    }
  };
  const pageChange = (e) => {
    setfilters({ ...filters, page: e });
    GetAllProjects({ page: e });
  };
  const HandleSearchSubmit = (e) => {
    e.preventDefault();
    if (SearchData == "") {
    } else {
      setfilters({ ...filters, search: SearchData });
      GetAllProjects({ search: SearchData });
    }
  };

  const HandleEmptySearch = (e) => {
    e.preventDefault();
    setSearchData("");
    setfilters({ ...filters, search: "" });
    GetAllProjects({ search: "" });
  };

  const HandleAcceptRejectInvite = (e, id, status) => {
    e.preventDefault();
    loader(true);
    const payload = { id: id, status: status };
    ApiClient.put(`accept/reject/invite`, payload).then((res) => {
      if (res.success) {
        GetAllProjects();
        toast.success(res.message);
      }
    });
  };
  const GetPostedTime = (date) => {
    const result = moment(date).fromNow();
    if (result == "an hour ago") {
      return "1hr ago";
    } else {
      return result;
    }
  };

  const StatusListHandler = (e, status) => {
    e.preventDefault();
    setfilters({ ...filters, status: status });
    GetAllProjects({ status: status });
  };
  const ReviewWriteRef = useRef();
  const ReviewHandler = (e) => {
    e.preventDefault();

    const payload = {
      userId: user.id,
      contractId: SelectedContractId,
      review: ReviewWriteRef.current.value,
      rating: RatingNumber,
    };
    if (payload.rating == 0) {
      toast.error("You need to give rating ");
      return;
    }
    ApiClient.post(`rating`, payload).then((res) => {
      if (res.success) {
        document.getElementById("closemodal1").click();
        toast.success("Rating Submitted Successfully");
      }
    });
  };

  const Getcalculculateminute = (res) => {
    let hours = parseInt(res?.totalDurationOfTime_hour);
    let minutes = parseInt(res?.totalDurationOfTime_min);
    let remainder = minutes / 60;
    let quotient = minutes % 60;
    hours += parseInt(remainder);
    minutes = quotient;
    return `${hours || 0} hrs : ${minutes < 10 ? "0" + minutes : minutes || 0
      } mn`;
  };

  const handleNotice = (item, type) => {
    Navigate.push(`/notice?contract=${item?.id || item?._id}&type=${type}`);
  };

  const hanldeStatus = (item) => {
    const status = item?.status === "accepted" ? "paused" : "accepted";
    ApiClient.put(
      `change/status?model=contracts&id=${item?.id || item?._id
      }&status=${status}`
    ).then((res) => {
      if (res.success) {
        GetAllProjects();
      }
    });
  };

  const getTodayTotalTime = (item) => {
    return `${item?.todayTotalTime_hour || "00"}:${item?.todayTotalTime_min || "00"}`
  }

  return (
    <>
      <Header />
      <div className="container mt-5">
        <div className="card p-3">
          <div className="row position-relative">
            <div className="d-flex align-items-center justify-content-between p-4  flex-diret-col">
              <div className="text-left">
                <h3 className="font-weight-bold mb-3 contractsheader">
                  Contracts
                </h3>
              </div>

              <div className="d-flex align-items-center flex-col-md">
                <div className="d-flex align-items-center">
                  <form
                    className="w-100"
                    onSubmit={(e) => HandleSearchSubmit(e)}
                  >
                    <div className="input-group">
                      <input
                        type="search"
                        className="form-control"
                        placeholder="Search here"
                        value={SearchData}
                        onChange={(e) => {
                          e.target.value == ""
                            ? HandleEmptySearch(e)
                            : setSearchData(e.target.value);
                        }}
                      />
                      <div className="input-group-append">
                        <button
                          type="submit"
                          className="btn btn-primary pl-3 pr-3"
                        >
                          <i className="fas fa-search"></i>
                        </button>
                      </div>
                    </div>
                  </form>
                </div>
                <div className="ms-2 tabs-btn tabs_changes ">
                  <button
                    className={`btn btn-${filters.status == "accepted" ? "primary" : "secondary"
                      }`}
                    onClick={(e) => StatusListHandler(e, "accepted")}
                  >
                    Active
                  </button>{" "}
                  <button
                    className={`btn btn-${filters.status == "paused" ? "primary" : "secondary"
                      }`}
                    onClick={(e) => StatusListHandler(e, "paused")}
                  >
                    Pause
                  </button>
                  <button
                    className={`btn btn-${filters.status == "completed" ? "primary" : "secondary"
                      }`}
                    onClick={(e) => StatusListHandler(e, "completed")}
                  >
                    Completed
                  </button>
                </div>
              </div>
            </div>
            <div className="col-md-12  mt-md-4">
              <div className="row">
                {ProjectsData.map((item, index) => (
                  <div className="col-lg-6">
                    <div className="contract_box">
                      <div className="contract-title">
                        <h5
                          onClick={(e) => Navigate.push(`/offer/${item._id}`)}
                          style={{ cursor: "pointer" }}
                          className="text-info"
                        >
                          {item?.contractName || "---"}
                        </h5>
                        <div className="d-flex align-items-center">
                          {filters.status != "accepted" ||
                            methodModel.isDateDifferenceGreaterThanThreeMonths(
                              item?.createdAt
                            ) ? (
                            <span
                              style={{ cursor: "pointer" }}
                              className="active active-span"
                              onClick={(e) =>
                                setSelectedContractId(item?.id || item?._id)
                              }
                              data-toggle="modal"
                              data-target="#form"
                            >
                              Provide Rating
                            </span>

                          ) : (

                            <FaCheck className="active-icons" />

                          )}
                          <span className="actice-text ml-1">Active</span>
                        </div>

                      </div>

                      <p className="mb-1">
                        <span className="head-sub">
                          {" "}
                          <i
                            class="fa fa-user-circle me-2"
                            aria-hidden="true"
                          ></i>
                          Staffed by:{" "}
                        </span>
                        <span className="text-primary">
                          {item.contractTo_details &&
                            item.contractTo_details.fullName}{" "}
                        </span>
                      </p>

                      <p className="mb-1">
                        <span className="head-sub">
                          <i
                            class="fa fa-user-plus me-2"
                            aria-hidden="true"
                          ></i>
                          Hired by:{" "}
                        </span>
                        <span className="text-primary">
                          {item.contractFrom_details &&
                            item.contractFrom_details.fullName}
                        </span>
                      </p>
                      <p className="mb-1">
                        <span className="head-sub">
                          <i
                            class="fa fa-calendar-o me-2"
                            aria-hidden="true"
                          ></i>
                          Billing Status:{" "}
                        </span>
                        <span className="set-on-off">


                          {item?.billingStart === true || item?.billingStart == 'on' ? <span className="bg-button" >ON</span> : <span className="off-button" >OFF</span>}


                        </span>
                      </p>

                      <p className="mb-1">
                        <span className="head-sub">
                          <i class="fa fa-clock-o me-2" aria-hidden="true"></i>
                          Start Date:{" "}
                        </span>
                        <span className="text-primary">
                          {methodModel.convertDate(item.startDate)}{" "}
                        </span>
                      </p>
                      <p className="mb-1">
                        <span className="head-sub">
                          <i class="fa fa-clock-o me-2" aria-hidden="true"></i>
                          Contract Status:{" "}
                        </span>
                        <span className="text-primary">
                          {item?.status === "accepted" ? "Active" : "Paused"}{" "}
                        </span>
                      </p>
                      <div className="tracker_div mt-4">
                        <div className="">
                          <div className="form-check form-switch switch-btn main_switch">
                            {/* <div>
                              {item.status == "accepted" &&
                                user.role &&
                                user.role.id == environment.VirtualId ? (
                                TrackStatus == "false" ? (
                                  <>
                                    <div className="d-flex  justify-content-between  align-items-center flex-wrap gap-3 pt-3  clock-width">
                                      <label className="clock-div my-0">
                                        Clock In
                                      </label>
                                      <input
                                        className="form-check-input tracker_switch"
                                        type="checkbox"
                                        role="switch"
                                        checked={false}
                                        data-toggle="modal"
                                        data-target="#exampleModalTracker"
                                        id="flexSwitchCheckDefault"
                                        onChange={e => setClockdata(item)}
                                      />

                                    </div>
                                  </>
                                ) : (
                                  <>
                                    <div className="d-flex  justify-content-between  align-items-center flex-wrap gap-3 pt-3 border-top clock-width">
                                      <label className="clock-div my-0">
                                        Clock Out{" "}
                                      </label>
                                      <input
                                        className="form-check-input tracker_switch"
                                        type="checkbox"
                                        role="switch"
                                        checked={item?.id === TrackerStatus ? true : false}
                                        onClick={(e) => { item?.id === TrackerStatus ? StopTrackerFunction(e) : null }}
                                        id="flexSwitchCheckDefault"
                                      />
                                    </div>
                                  </>
                                )
                              ) : null}
                            </div> */}
                            {/* <div class="main_timer" >
                              {user?.role && user?.role?.id === environment?.VirtualId ? <div className="timer_div ">
                                {TrackStatus && item?.id === TrackerStatus ? TimeTraks.time : "00:00:00"}
                              </div> : ""}
                              {Getcalculculateminute(item)}
                              /{item?.weeklyLimit}hr
                            </div> */}
                            <small className="text-end mb-3">
                              {item.weeklyLimit} hrs weekly limit
                            </small>
                          </div>
                        </div>
                      </div>

                      <div className="d-flex justify-content-between align-items-center gap-3" >
                        {user?.role?.id === environment?.EmployerId ? <div className="allInpus d-flex align-items-center gap-2 ">
                          {item?.id && item?.status === "accepted" ? <input type="checkbox" onChange={(e) => hanldeStatus(item)} checked={item?.status !== "accepted"} className="set_ins" /> :
                            <input type="checkbox" onChange={(e) => hanldeStatus(item)} checked={item?.status !== "accepted"} className="set_ins" />}
                          {item?.status === "accepted" ? "Pause" : "Resume"}
                        </div> : ""}

                        <div className="d-flex justify-content-end align-items-center gap-3">
                          <button
                            type="button"
                            className="active btn btn-primary activespan "
                            onClick={(e) => handleNotice(item, "notice")}
                          >
                            Notice
                          </button>
                          {user?.role?.name === "Virtual Assistant" ? null : (
                            <>
                              <button
                                type="button"
                                className="active outline_pri activespan"
                                onClick={(e) => handleNotice(item, "terminate")}
                              >
                                Termination
                              </button>
                              {/* <button
                                type="button"
                                className="active btn btn-primary activespan "
                                onClick={(e) => hanldeStatus(item)}
                              >
                                {item?.status === "accepted" ? "Pause" : "Resume"}
                              </button>{" "} */}
                            </>
                          )}
                        </div>
                      </div>


                      {user?.role && user?.role?.id === environment?.EmployerId ? null : <div className="card_contract">
                        <div className="text-right"></div>
                        <div className="d-flex  set_cardbx gap-3  justify-content-between">
                          <div className="d-flex flex-column gap-3">
                            {item?._id === TrackerStatus ? <h5 className="card_titles">{localStorage.getItem("memo")}</h5> : ""}
                            <button type="button" className="light_btns">
                              {" "}
                              Memo
                            </button>
                          </div>

                          {/* <div> */}
                          <div className="">
                            <div className="form-check form-switch  main_switch">

                              {item.status == "accepted" &&
                                user.role &&
                                user.role.id == environment.VirtualId ? (
                                !localStorage.getItem(`trackingid`) ? (
                                  <div className="d-flex flex-column gap-2">
                                    <>
                                      <label className="clock-div my-0 text-white">
                                        Clock In
                                      </label>
                                      <div className="    position-relative set_postion">
                                        {/* <input
                                      className="form-check-input tracker_switch"
                                      type="checkbox"
                                      role="switch"
                                      checked={false}
                                      data-toggle="modal"
                                      data-target="#exampleModalTracker"
                                      id="flexSwitchCheckDefault"
                                      onChange={e => setClockdata(item)}
                                    /> */}

                                        <div className="allInpus">
                                          <input
                                            type="checkbox"
                                            role="switch"
                                            checked={false}
                                            data-toggle="modal"
                                            data-target="#exampleModalTracker"
                                            id="flexSwitchCheckDefault"
                                            onChange={(e) => setClockdata(item)}
                                          />
                                        </div>
                                      </div>
                                    </>
                                  </div>
                                ) : (
                                  <>
                                    <div className="d-flex flex-column gap-3 ">
                                      <label className="clock-div my-0 text-white">
                                        {item?._id === TrackerStatus ? " Clocked In" : "Clock In"}{" "}
                                      </label>
                                      <div className="    position-relative set_postion">
                                        {/* <input
                                      className="form-check-input tracker_switch"
                                      type="checkbox"
                                      role="switch"
                                      checked={item?.id === TrackerStatus ? true : false}
                                      onClick={(e) => { item?.id === TrackerStatus ? StopTrackerFunction(e) : null }}
                                      id="flexSwitchCheckDefault"
                                    /> */}

                                        <div className="allInpus">
                                          <input
                                            type="checkbox"
                                            role="switch"
                                            checked={
                                              item?._id === TrackerStatus
                                                ? true
                                                : false
                                            }
                                            onClick={(e) => {
                                              item?._id === TrackerStatus
                                                ? StopTrackerFunction(e)
                                                : null;
                                            }}
                                            id="flexSwitchCheckDefault"
                                          />
                                        </div>
                                      </div>
                                    </div>
                                  </>
                                )
                              ) : null}
                            </div>
                          </div>

                          {/* </div> */}
                        </div>
                        <div className="text-center timer_titlesbx">
                          <div className="timer_cards">
                            {user?.role &&
                              user?.role?.id === environment?.VirtualId ? (
                              <div className="timer_div ">
                                {localStorage.getItem(`trackingid`) && item?._id === TrackerStatus
                                  ? TimeTraks.time
                                  : "00:00:00"}
                              </div>
                            ) : (
                              ""
                            )}
                          </div>
                        </div>
                        {/* <p className="address_cards" > <i class="fa fa-map-marker card_maps" aria-hidden="true"></i>
Lorem Ipsum has been the industry's standard dummy </p> */}
                        {/* <div className="d-flex align-items-center card_hourstime gap-3 mt-3  justify-content-between">
                          <h5 className="card_titles">
                            Total working hours per week
                          </h5>
                          <div className="card_times_bx">20 hours/ 40 hrs</div>
                        </div>
                        <div className="d-flex align-items-center card_hourstime gap-3  justify-content-between">
                          <h5 className="card_titles">
                            Total working hours for today
                          </h5>
                          <div className="card_times_bx">03:03:56</div>
                        </div> */}

                        <div className="d-flex align-items-center card_hourstime gap-3 mt-3  justify-content-between">
                          <h5 className="card_times_bx">
                            {getTodayTotalTime(item)} hrs
                            <p>Today</p>
                          </h5>
                          <div className="card_times_bx">
                            {Getcalculculateminute(item)}/{item?.weeklyLimit}hr
                            <p>This Week</p>
                          </div>
                        </div>
                        {/* <div class="main_timer" >
                         
                          <hr   />
                          {Getcalculculateminute(item)}
                          /{item?.weeklyLimit}hr
                        </div> */}
                      </div>}
                    </div>
                  </div>
                ))}
              </div>
            </div>
            {ProjectsData?.length == 0 ? (
              <div className="text-center text-danger mb-5">
                <div>{filters.status == "accepted" ? 'No active contracts yet.' : 'No contracts yet.'}</div>
              </div>
            ) : null}
          </div>
        </div>
        {total > filters.count ? (
          <div className="mt-3" style={{ float: "right" }}>
            <Pagination
              activePage={filters.page}
              itemClassPrev="back_page"
              itemClassNext="next_page"
              itemsCountPerPage={filters.count}
              totalItemsCount={total}
              pageRangeDisplayed={5}
              onChange={pageChange}
            />
          </div>
        ) : null}
      </div>
      <div
        className="modal fade"
        id="form"
        tabindex="-1"
        role="dialog"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered" role="document">
          <div className="modal-content">
            <div
              className="text-right cross"
              id="closemodal1"
              data-dismiss="modal"
              aria-label="Close"
            >
              {" "}
              <i className="fa fa-times mr-2"></i>{" "}
            </div>
            <div className="card-body text-center">
              {" "}
              <img
                src=" https://i.imgur.com/d2dKtI7.png"
                height="100"
                width="100"
              />
              <div className="comment-box text-center">
                <form onSubmit={(e) => ReviewHandler(e)}>
                  <h4>Add a comment</h4>
                  <div className="rating">
                    {" "}
                    {[...new Array(5)].map((item, index) => (
                      <>
                        <input
                          type="radio"
                          name="rating"
                          onChange={(e) => {
                            e.target.checked
                              ? setRatingNumber(index + 1)
                              : setRatingNumber(0);
                          }}
                          checked={RatingNumber >= index + 1}
                          value={index}
                          id={index}
                        />
                        <label for={index}>☆</label>
                      </>
                    ))}
                  </div>
                  <div className="comment-area">
                    {" "}
                    <textarea
                      ref={ReviewWriteRef}
                      required
                      className="form-control"
                      placeholder="what is your view?"
                      rows="4"
                    ></textarea>{" "}
                  </div>

                  <div className="text-center mt-4">
                    {" "}
                    <button className="btn btn-success px-5" type="submit">
                      Send message
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div
        className="modal fade"
        id="exampleModalTracker"
        tabIndex="-1"
        role="dialog"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog" role="document">
          <div className="modal-content">
            <div className="">
              <h5 className="modal-title" id="exampleModalLabel"></h5>
              <button
                type="button"
                id="closeTimeTracker"
                className="close p-2"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body pt-0">
              {clockData?.isContractPayment == true &&
                clockData?.isWeeklyPayment == true ? (
                <Formik
                  initialValues={{
                    memo: "",
                  }}
                  onSubmit={(values, { resetForm }) => {
                    setDisabledSaveButton(true)
                    if (new Date(clockData?.startDate) >= new Date()) {
                      toast.error("Contract Not Started Yet!");
                      setDisabledSaveButton(false)
                      return;
                    }
                    if (
                      !clockData?.isContractPayment ||
                      !clockData?.isWeeklyPayment
                    ) {
                      setDisabledSaveButton(false)
                      toast.error(
                        `Please wait till the employer's payment is completed. It is currently in progress.`
                      );
                      return;
                    }

                    // const response = await fetch(
                    //         "https://maps.googleapis.com/maps/api/geocode/json?latlng=30.7167232,76.75904&key=AIzaSyDXcaa81DeBgUyniq30GpZ5pYI7oDneKlE"

                    //       );
                    //       const res = await response.json();


                    const payload = {
                      memo: values?.memo || "",
                      projectId:
                        clockData?.projectId && clockData?.project_details?._id,
                      contractId: clockData?._id,
                      addedBy: user?.id || user?._id,
                      startTime: datepipeModel.TimeConvertToTimezone((new Date()).toString()),
                      timeZone: user?.timeZone?.value
                    };
                    if ("geolocation" in navigator) {
                      navigator.geolocation.getCurrentPosition(
                        async function (position) {
                          loader(true);
                          fetchData(
                            position.coords.latitude,
                            position.coords.longitude
                          ).then((res) => {
                            const FinalPayload = { ...payload, ...res, systemTimeZone: methodModel.GetTimeZoneValue() };
                            ConnectSocket.emit("add-billing", FinalPayload);
                            GetAllProjects();
                            StartTrackingFunc();
                            document.getElementById("closeTimeTracker").click();
                            resetForm();
                            setDisabledSaveButton(false)
                            loader(false);
                          });
                        },
                        () => {
                          setDisabledSaveButton(false)
                          toast.error(
                            "Before start Billing Enable the Location Permissions"
                          );
                          return;
                        }
                      );
                    } else {
                      setDisabledSaveButton(false)
                    }
                  }}
                >
                  {({ values, handleBlur, handleChange, handleSubmit }) => (
                    <form onSubmit={handleSubmit}>
                      <div className="notes-img">
                        <img src="/assets/img/notes.png" />
                      </div>

                      <textarea
                        className="form-control"
                        value={values.memo}
                        name="memo"
                        onChange={handleChange}
                        cols={4}
                        required
                        placeholder="Add Memo"
                      ></textarea>

                      <div className="d-flex justify-content-center align-items-center mt-4">
                        <button type="submit" className="btn btn-primary" disabled={DisabledSaveButton}>
                          Save changes
                        </button>
                      </div>
                    </form>
                  )}
                </Formik>
              ) : (
                <div className="text-center text-danger">
                  <p>
                    Please wait till the employer's payment is completed. It is
                    currently in progress.
                  </p>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>

      {/*Modal for Add Manual time log */}
      <button type="button" id="OpenClockOutModal2" class="btn btn-primary d-none" data-bs-toggle="modal" data-bs-target="#OpenClockOutModal3">
        Launch demo modal
      </button>

      <div class="modal fade clocktimes" id="OpenClockOutModal3" tabindex="-1" aria-labelledby="OpenClockOutModal3" aria-hidden="true">
        <div class="modal-dialog ">
          <div class="modal-content">
            <div class="modal-header border-bottom">
              <h5 class="modal-title" id="exampleModalLabel">Time Log</h5>
              <button type="button" class="close" data-bs-dismiss="modal" aria-label="Close">
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <form onSubmit={e => StopTrackerFunction(e, 'final')}>
              <div class="modal-body">

                <div className="">
                  <div className="row">

                    <div className="col-md-12">
                      <div className="d-flex flex-column mb-4">
                        <label>
                          Start Time
                          <span className="star">*</span>
                        </label>

                        <DatePickers
                          className="form-control"
                          selected={ClockOutForm.startTime}
                          onChange={(date) => setClockOutForm({ ...ClockOutForm, startTime: date })}
                          showTimeSelect
                          dateFormat="MMMM d, yyyy h:mm aa"
                          disabled
                          placeholderText="Start Time"
                          required
                        />
                      </div>
                    </div>
                    <div className="col-md-12">
                      <div className="d-flex flex-column mb-4">
                        <label>
                          End Time
                          <span className="star">*</span>
                        </label>

                        <DatePickers
                          minDate={ClockOutForm?.startTime}
                          className="form-control"
                          selected={ClockOutForm.endTime}
                          onChange={(date) => setClockOutForm({ ...ClockOutForm, endTime: date })}
                          showTimeSelect
                          disabled
                          disabledDate={(current) => current && current < moment(new Date(ClockOutForm?.startTime)).startOf('day')}
                          dateFormat="MMMM d, yyyy h:mm aa"
                          placeholderText="End Time"
                          required
                        />
                      </div>
                    </div>
                    <div className="time-logged"><h6 className="text-sm"> Time Logged - {methodModel?.GetHourMinutesDates(ClockOutForm?.startTime, ClockOutForm?.endTime)}</h6>
                    </div>
                  </div></div>

              </div>
              <div class="modal-footer">
                <button type="button" class="btn d-none btn-secondary" id="closeClockOutmodal3" data-bs-dismiss="modal">Close</button>
                <button type="submit" class="btn btn-primary">Submit</button>
              </div>

            </form>
          </div>
        </div>
      </div>
    </>
  );
};
export default Contract;
