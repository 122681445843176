/*
 * @Author: Ghost
 * @Email: Ghost@yopmail.com
 * @Date: 2024-01-30 12:56:56
 * @Last Modified by: Someone
 * @Last Modified time: 2024-08-21 20:45:10
 * @Description: file:///home/jc-21/Desktop/MyVirtualPal/marketplace-frontend/src/pages/CommonTransactionTable/TransactionTable.js
 */

import { useEffect, useState } from "react";
import loader from "../../methods/loader";
import ApiClient from "../../methods/api/apiClient";
import Header from "../../components/global/header";
import { useSelector } from "react-redux";
import environment from "../../environment";
import moment from "moment";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { ConnectSocket } from "../../components/global/SocketNetwork/ConnectSocket.js";
import { toast } from "react-toastify";
import methodModel from "../../methods/methods.js";
import convertTimezone from "../../components/common/TimeZoneConvertor/TimeZoneConvert.js";
import { DatePicker } from "antd";
const { RangePicker } = DatePicker;
import dayjs from "dayjs";
import axios from "axios";

export default function TransactionTable() {
  const [SelectedVA, setSelectedVA] = useState("");
  const user = useSelector((state) => state.user);
  const Navigate = useHistory();
  const [VAList, setVAList] = useState([]);
  const [Data, setData] = useState({
    VARATe: "----",
    RETAINER: "----",
    RETAINERLIMIT: "----",
    virtualName: "---",
  });
  const [Filter, setFilter] = useState({
    page: 1,
    count: 50,
    search: "",
    addedBy:
      user?.role?.id == environment.EmployerId ? user.id || "" : user.addedBy,
    contractId: "",
  });
  const [VirtualId, setVirtualId] = useState("");
  const [ContractId, setContractId] = useState(false);
  const [paymentForm, setPaymentForm] = useState({
    document: "",
    payPrice: "",
  });
  const [submitted, setSubmitted] = useState(false);
  const [list, setlist] = useState([]);
  const [calculatedAmt, setCalulatedAmt] = useState(0);
  const [timeLogs, setTimeLogs] = useState([]);
  const referrer = document.referrer.toLowerCase();
  const { startDate, endDate } = methodModel.getCurrentWeekDates();
  const today = endDate; // Get today's date
  const tomorrow = startDate; // Get tomorrow's date
  const [BillingFilters, setBillingFilters] = useState({
    contractId: "",
    start: moment(tomorrow).format("MM-DD-YYYY"),
    end: moment(today).format("MM-DD-YYYY"),
  });
  const [SelectedDateRange, setSelectedDateRange] = useState([
    dayjs(tomorrow),
    dayjs(today),
  ]);
  const [ProjectDetails, setProjectDetails] = useState({});
  const [alignRecords, setAlignRecords] = useState([]);

  const GetVALIST = () => {
    loader(true);
    const userid =
      user.role && user.role.id == environment.EmployerId
        ? user.id
        : user.addedBy;
    ApiClient.get(`virtualListing?employerId=${userid}`).then(async (res) => {
      if (res.success) {
        const newdata = res.data;
        const updateddata = [];
        await newdata.map((item, index) => {
          if (item?.contractName || item?.title) {
            //  We Need to make the per Virtual Assistant id unique so we need to do this
            updateddata.push({
              id: item?.virtualDetail?._id,
              fullName: item?.contractName || item?.title,
              contract_Id: item.id,
              mainid: item?.virtualDetail?._id + `12${index}`,
              VAName: item?.virtualDetail?.fullName,
            });
          }
        });
        setVAList(updateddata);
        setSelectedVA(updateddata[0]?.mainid || "");
      }
      loader(false);
    });
  };
  useEffect(() => {
    GetVALIST();
    getTimeLogs();
    GetRefers();
  }, []);

  const [contractID, setcontractID] = useState("");

  useEffect(() => {
    if (SelectedVA != "") {
      const SelectedData = VAList.find((item) => item.mainid == SelectedVA);
      setVirtualId(SelectedData?.id);
      setData((prev) => ({ ...prev, virtualName: SelectedData?.VAName }));
      GetTransactionData({ contractId: SelectedData?.contract_Id });
      setFilter({ ...Filter, contractId: SelectedData?.contract_Id });
      getAmount(SelectedData?.contract_Id);
      GetRetainerData(SelectedData?.contract_Id);
      setcontractID(SelectedData?.contract_Id);
      setBillingFilters({
        ...BillingFilters,
        contractId: SelectedData?.contract_Id,
      });
      getTimeLogs({ contractId: SelectedData?.contract_Id });
      ApiClient.get(`contract?id=${SelectedData?.contract_Id}`).then((res) => {
        if (res.success) {
          setContractId(res?.data?.billingStart);
          setProjectDetails(res?.data);
        }
      });
    } else {
      setBillingFilters({ ...BillingFilters, contractId: "" });
      getTimeLogs({ contractId: "" });
      setFilter({ ...Filter, contractId: "" });
      GetTransactionData({ contractId: "" });
      setData({
        VARATe: "----",
        RETAINER: "----",
        RETAINERLIMIT: "----",
        virtualName: "---",
      });
    }
  }, [SelectedVA]);

  // --------------------------------
  // const location = async()=>
  // {
  //   // if(ContractId == true)
  //         // {
  //           // alert("hello")
  //     const response = await fetch(
  //       "https://maps.googleapis.com/maps/api/geocode/json?latlng=30.7167232,76.75904&key=AIzaSyDXcaa81DeBgUyniq30GpZ5pYI7oDneKlE"

  //     );
  //     const res = await response.json();
  //     let data = {

  //       address: {
  //         address: res?.results?.address_components?.formatted_address,
  //       },
  //       location: {
  //         type: "json",
  //         defaultsTo: [],
  //       },
  //       lat: {
  //         lat: res?.results[0]?.address_components[0]?.long_name,
  //       },
  //       long: {
  //         long: res?.results[0]?.address_components[0]?.long_name,
  //       },
  //       city: {
  //         city: res?.results[0]?.address_components[3]?.long_name,
  //       },
  //       state: {
  //         state: res?.results[0]?.address_components[4]?.long_name,
  //       },
  //       country: {
  //         country: res?.results[0]?.address_components[6]?.long_name,
  //       },
  //       pincode: {
  //         pincode: res?.results[0]?.address_components[7]?.long_name,
  //       },

  //     }
  //     ConnectSocket.emit("add-billing", data);
  //     // ConnectSocket.on(`add-billing`,(data)=>{
  //     //   console.warn(data,"This is the mian Billing Dataff ==================>")
  //     // })
  //         // }
  // }

  // ---------------------

  const getTimeLogs = (p = {}) => {
    const newfilters = { ...BillingFilters, ...p, sortBy: "startTime desc" };
    if (newfilters?.contractId) {
      ApiClient.get(`${environment.VirtualId == user?.role?.id ? 'billing-list/virtual' : 'billing/list'}`, newfilters).then((res) => {
        if (res.success) {
          setAlignRecords(
            res?.data?.map((item, index) => {
              return {
                date: methodModel.convertDate(item?.startTime),
                hours: item?.totalDurationOfTime_hour || 0,
                minutes: item?.totalDurationOfTime_min || 0,
                check: checkLastDate(
                  methodModel.convertDate(item?.startTime),
                  res?.data?.[index + 1]?.startTime
                    ? methodModel.convertDate(res?.data?.[index + 1]?.startTime)
                    : ""
                ),
              };
            })
          );
          setTimeLogs(res?.data);
        }
      });
    } else {
      setTimeLogs([]);
    }
  };

  const GetRetainerData = (id) => {
    const userid =
      user && user.role.id == environment.EmployerId ? user.id : user.addedBy;
    loader(true);
    ApiClient.get(`retainer/list?employerId=${userid}&contractId=${id}`).then(
      (res) => {
        if (res.success) {
          const data = res.data;
          setData((prev) => ({
            ...prev,
            VARATe: data[0]?.vaRate + " USD",
            RETAINER: data[0]?.retainerAmount + " USD",
            RETAINERLIMIT: data[0]?.retainerLimit + " USD",
          }));
        }
        loader(false);
      }
    );
  };

  const [TransactionData, setTransactionData] = useState([]);
  const GetTransactionData = (p = {}) => {
    const newfilters = { ...Filter, ...p };
    loader(true);
    ApiClient.get(`transactions`, newfilters).then((res) => {
      if (res.success) {
        setTransactionData(res.data);
      }
      loader(false);
    });
  };

  const [userData, setUserData] = useState(user)
  useEffect(() => {
    ApiClient.get(`profile`, { id: user.id }).then(res => {
      if (res.success) {
        setUserData(res.data)
      }
    })
  }, [])
  const getAmount = (id) => {
    ApiClient.get(
      `retainer/list?virtualId=&employerId=${user.id}&contractId=${id}`
    ).then((res) => {
      if (res.success) {
        setCalulatedAmt(res?.data[0]?.retainerAmount || 0);
      }
    });
  };

  const payNow = () => {
    let payload = {
      payPrice: Number(paymentForm.payPrice) - Number(UsedReferalPrice || 0),
      document: paymentForm.document,
      contractId: Filter.contractId,
      customer_id: user.customer_id,
      referalCredit: UsedReferalPrice,
    };

    setSubmitted(true);

    if (!paymentForm.payPrice) return;

    loader(true);
    ApiClient.post("card/chargepayment", payload).then((res) => {
      loader(false);
      if (res.success) {
        let el = document.getElementById("paymentModalOpen");
        if (el) el.click();
        toast.success("Payment Proccessed successfully");
        ApiClient.put("contract", {
          id: payload.contractId,
          isContractPayment: true,
          isWeeklyPayment: true,
        }).then((res) => {
          if (res.success) {
            try {
              if (referrer.includes("zoho")) {
                Navigate.push("/perposallist");
              } else {
                Navigate.goBack();
              }
            } catch {
              Navigate.goBack("/contracts");
            }
          }
        });
      }
    });
  };

  const pay = () => {
    let el = document.getElementById("paymentModalOpen");
    if (el) el.click();
    setPaymentForm({
      document: "",
      payPrice: calculatedAmt,
    });
  };

  const uploadDoc = (e) => {
    let files = e.target.files;
    let file = files.item(0);
    loader(true);
    ApiClient.postFormData("single/documents?modelName=transactions", {
      file: file,
    }).then((res) => {
      e.value = "";
      loader(false);
      if (res.success) {
        let imagePath = res.data.imagePath;
        setPaymentForm({ ...paymentForm, document: imagePath });
      }
    });
  };

  const [refers, setrefers] = useState([]);

  const GetRefers = () => {
    ApiClient.get("referalList").then((res) => {
      if (res.success) {
        const data = res.data;
        setrefers(data);
      }
    });
  };

  const OpenModal = () => {
    document.getElementById("OpenRefermodal").click();
    GetRefers();
  };

  const HandleApproveHoursbutton = () => {
    Navigate.push("/approve/hours/" + contractID);
  };

  const handleDateChange = (date, dateStrings) => {
    setSelectedDateRange(date);
    let dates = dateStrings;
    setBillingFilters({
      ...BillingFilters,
      start: dates[0] || "",
      end: dates[1] || "",
    });
    getTimeLogs({ start: dates[0] || "", end: dates[1] || "" });
    // You can perform additional filtering or other actions based on the selected date range
  };

  const exportPdf = async () => {
    loader(true);
    const token = await localStorage.getItem("token");
    const req = await axios({
      method: "get",
      url: `${environment.api}billing-pdf/frontend?contractId=${BillingFilters?.contractId
        }&projectId=${ProjectDetails?.projectId?.id || ""}&start=${BillingFilters?.start
        }&end=${BillingFilters?.end}`,
      responseType: "blob",
      body: { token: token },
    });
    var blob = new Blob([req.data], {
      type: req.headers["content-type"],
    });
    const blobUrl = URL.createObjectURL(blob);
    setTimeout(() => {
      fetch(blobUrl)
        .then((res) => res.json())
        .then(async (jsonData) => {
          const pdfUrl = jsonData.path;
          if (pdfUrl) {
            await fetch(`${environment.api}${pdfUrl}`)
              .then((res) => res.blob())
              .then((pdfBlob) => {
                const a = document.createElement("a");
                a.href = URL.createObjectURL(
                  new Blob([pdfBlob], { type: "application/pdf" })
                );
                a.download = "TimeLogs.pdf";
                a.click();
                loader(false);
              });
          } else {
            loader(false);
          }
        });
    }, 3000);
    loader(false);
  };

  const getTotalHoursForDate = (targetDate) => {
    const totals = { hours: 0, minutes: 0 };

    alignRecords.forEach((record) => {
      const { date, hours, minutes } = record;

      // Check if the record's date matches the target date
      if (date === targetDate) {
        // Add hours and minutes
        totals.hours += hours;
        totals.minutes += minutes;

        // Convert overflow minutes to hours
        if (totals.minutes >= 60) {
          const additionalHours = Math.floor(totals.minutes / 60);
          totals.hours += additionalHours;
          totals.minutes = totals.minutes % 60;
        }
      }
    });

    if (totals.hours === 0 && totals.minutes === 0) {
      return `0 hrs 0 mins`;
    }

    return `${totals?.hours} hrs ${totals?.minutes} mins`;
  };

  const checkLastDate = (startDate, endDate) => {
    if (endDate) {
      if (startDate === endDate) {
        return false;
      } else {
        return true;
      }
    } else {
      return true;
    }
  };

  const [UsedReferalPrice, setUsedReferalPrice] = useState(0);
  const ReferalCreditHanlder = (checked) => {
    if (checked) {
      if (Number(userData?.referalCredit) >= Number(paymentForm.payPrice)) {
        setUsedReferalPrice(paymentForm?.payPrice);
      } else {
        setUsedReferalPrice(userData?.referalCredit);
      }
    } else {
      setUsedReferalPrice(0);
    }
  };
  return (
    <>
      <Header />
      <div className="container mt-5">
        <div className="d-flex justify-content-end">
          {!SelectedVA ? null : (
            <button
              className="btn btn-primary mt-2"
              onClick={(e) => HandleApproveHoursbutton()}
            >
              Billing Hours
            </button>
          )}
          <button
            className="btn btn-primary  mt-2 ml-2"
            onClick={(e) => OpenModal()}
          >
            Referral Credit
          </button>
        </div>
      </div>

      {VAList.length == 0 ? (
        <>
          <></>
          <div className="container text-center">
            <img
              src="/assets/img/not-found.jpg"
              className="not_found_img img-fluid"
            />
          </div>
        </>
      ) : (
        <div className="container">
          <div className="maindiv_boder my-3 p-4">
            <div className="row mb-5 ">
              <div className="col-md-4 md-0 mb-3">
                <label>Contract</label>
                <br />
                <select
                  value={SelectedVA}
                  className="form-control"
                  onChange={(e) => {
                    setSelectedVA(e.target.value);
                  }}
                >
                  {VAList.map((item) => (
                    <option
                      key={item.mainid}
                      value={item.mainid}
                      className="text-capitalize"
                    >
                      {item.fullName}
                    </option>
                  ))}
                </select>
              </div>
              <div className="col-md-2">
                <label>V.A Name</label>
                <br />
                <p className="p-2">{Data.virtualName}</p>
              </div>
              <div className="col-md-2">
                <label>V.A Rate</label>
                <br />
                <p className="p-2">{Data.VARATe}</p>
              </div>
              <div className="col-md-2">
                <label>Amount</label>
                <br />
                <p className="p-2">{Data.RETAINER}</p>
              </div>
              <div className="col-md-2">
                <label>Retainer Limit </label>
                <br />
                <p className="p-2">{Data.RETAINERLIMIT}</p>
              </div>
            </div>
          </div>
          <div>
            <div>
              {ContractId ? (
                <p>
                  Billing Status: <b>ON</b>{" "}
                </p>
              ) : (
                <p>
                  Billing Status: <b>OFF</b>{" "}
                </p>
              )}
            </div>
            <ul className="nav nav-tabs" id="myTab" role="tablist">
              <li className="nav-item" role="presentation">
                <button
                  className="nav-link active"
                  id="home-tab"
                  data-bs-toggle="tab"
                  data-bs-target="#home"
                  type="button"
                  role="tab"
                  aria-controls="home"
                  aria-selected="true"
                >
                  Transactions
                </button>
              </li>
              <li className="nav-item" role="presentation">
                <button
                  className="nav-link"
                  id="profile-tab"
                  data-bs-toggle="tab"
                  data-bs-target="#profile"
                  type="button"
                  role="tab"
                  aria-controls="profile"
                  aria-selected="false"
                >
                  Time Log
                </button>
              </li>
            </ul>
            <div className="tab-content" id="myTabContent">
              <div
                className="tab-pane fade show active"
                id="home"
                role="tabpanel"
                aria-labelledby="home-tab"
              >
                {Filter.contractId &&
                  user?.role?.id == environment.EmployerId ? (
                  <button
                    onClick={(e) => pay()}
                    className="btn btn-primary float-end mb-2"
                  >
                    Add Desposit
                  </button>
                ) : null}
                <div className="table-responsive">
                  {" "}
                  <table className="table table-hover">
                    <thead>
                      <tr>
                        <th>Start Date</th>
                        <th>Description</th>
                        <th>Credit</th>
                        <th>Debit</th>
                        <th>Balance</th>
                      </tr>
                    </thead>
                    <tbody>
                      {TransactionData.map((item, index) => (
                        <tr key={index}>
                          <td>
                            {moment(item?.contract_details?.startDate).format(
                              "MM/DD/YYYY"
                            )}
                          </td>
                          <td>
                            {item?.debt == 0 ? (
                              item?.description || "---"
                            ) : (
                              <>
                                {item?.description}
                                <br />
                                {methodModel.convertDate(
                                  item?.billingStartTime
                                )}
                                -
                                {methodModel.convertDate(
                                  item?.billingStartTime
                                )}
                                <br />
                                {item?.totalHour + "hrs "}
                                {`${item?.totalMin || 0}mn `}
                                {`${item?.totalSec || 0}s ` +
                                  "@ USD " +
                                  parseFloat(
                                    item?.employerHourlyRate || 0
                                  ).toFixed(2) +
                                  "/hr"}
                              </>
                            )}
                          </td>
                          <td>
                            {item?.price == 0 || !item?.credit
                              ? "---"
                              : `${parseFloat(item?.credit).toFixed(2)} USD`}
                          </td>
                          <td>
                            {item?.debt == 0
                              ? "---"
                              : `${parseFloat(item?.debt).toFixed(2)} USD`}{" "}
                          </td>
                          <td>
                            {item?.balance
                              ? `${parseFloat(item?.balance).toFixed(2)} USD`
                              : "---"}
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
                {TransactionData.length == 0 ? (
                  <div className="text-center text-danger mt-2">
                    <h5 className="danger">No Transaction Data</h5>
                  </div>
                ) : null}
              </div>
              <div
                className="tab-pane fade"
                id="profile"
                role="tabpanel"
                aria-labelledby="profile-tab"
              >
                <div className="d-flex justify-content-end">
                  <RangePicker
                    placeholder={["Start Date", "End Date"]}
                    format="MM-DD-YYYY"
                    onChange={handleDateChange}
                    value={SelectedDateRange}
                    className="p-2"
                  />
                  <button
                    className="btn btn-primary ml-4"
                    onClick={(e) => exportPdf()}
                  >
                    Export PDF
                  </button>
                </div>
                <div className="table-responsive mt-4">
                  {" "}
                  <table className="table table-hover">
                    <thead>
                      <tr>
                        <th>Memo</th>
                        <th>Start Date & Time</th>
                        <th>End Date & Time</th>
                        <th>Total Hours</th>
                        <th>Total Hours Per Day</th>
                      </tr>
                    </thead>
                    <tbody>
                      {timeLogs.map((item, index) => (
                        <tr key={index}>
                          <td>{item?.memo}</td>
                          <td>
                            {methodModel.convertDate(
                              user?.role?.id == environment?.EmployerId
                                ? convertTimezone(
                                  item?.startTime,
                                  ProjectDetails?.timeZone?.value,
                                  user?.globallyTimeZone?.value
                                )
                                : item?.startTime
                            )}
                            {"  "}{" "}
                            {methodModel.ConvertTime(
                              user?.role?.id == environment?.EmployerId
                                ? convertTimezone(
                                  item?.startTime,
                                  ProjectDetails?.timeZone?.value,
                                  user?.globallyTimeZone?.value
                                )
                                : item?.startTime
                            )}
                          </td>
                          <td>
                            {methodModel.convertDate(
                              user.role?.id == environment?.EmployerId
                                ? convertTimezone(
                                  item?.endTime,
                                  ProjectDetails?.timeZone?.value,
                                  user?.globallyTimeZone?.value
                                )
                                : item?.endTime
                            )}
                            {"  "}{" "}
                            {methodModel.ConvertTime(
                              user.role?.id == environment?.EmployerId
                                ? convertTimezone(
                                  item?.endTime,
                                  ProjectDetails?.timeZone?.value,
                                  user?.globallyTimeZone?.value
                                )
                                : item?.endTime
                            )}
                          </td>
                          <td>{`${item?.totalDurationOfTime_hour || 0} hr ${item?.totalDurationOfTime_min || 0
                            } min`}</td>
                          <td>
                            {alignRecords?.[index]?.check === true ? (
                              <>
                                {getTotalHoursForDate(
                                  methodModel.convertDate(item?.startTime)
                                )}
                              </>
                            ) : null}
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
                {timeLogs.length == 0 ? (
                  <div className="text-center text-danger mt-2">
                    <h5 className="text-danger">No Time Logs Data</h5>
                  </div>
                ) : null}
              </div>
            </div>
          </div>
        </div>
      )}

      {/* <div className='responsive-map'> */}
      {/* <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d109782.87829309916!2d76.6149782394365!3d30.69829538838229!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x390fee906da6f81f%3A0x512998f16ce508d8!2sSahibzada%20Ajit%20Singh%20Nagar%2C%20Punjab!5e0!3m2!1sen!2sin!4v1713862810854!5m2!1sen!2sin" width="600" height="450"  allowFullScreen="" loading="lazy" referrerPolicy="no-referrer-when-downgrade"></iframe> */}
      {/* </div> */}

      <button
        type="button"
        className="btn btn-primary d-none"
        id="paymentModalOpen"
        data-bs-toggle="modal"
        data-bs-target="#paymentModal"
      ></button>
      <div
        className="modal fade pay-modal"
        id="paymentModal"
        data-bs-backdrop="static"
        data-bs-keyboard="false"
        tabindex="-1"
        aria-labelledby="staticBackdropLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header">
              <h1 className="modal-title fs-5" id="staticBackdropLabel">
                Payment
              </h1>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <form
              onSubmit={(e) => {
                e.preventDefault();
                payNow();
              }}
            >
              <div className="modal-body">
                <div className="mb-3">
                  <label>Amount</label>
                  <input
                    type="text"
                    className="form-control"
                    required
                    value={paymentForm.payPrice}
                    maxLength={12}
                    onChange={(e) =>
                      setPaymentForm({
                        ...paymentForm,
                        payPrice: methodModel.isNumber(e),
                      })
                    }
                  />
                </div>
                <div>
                  <div className="referral-credit">
                    {" "}
                    <div className="flex-property">
                      <label className="mb-0">Referal Credit</label>{" "}
                      <div className="refer-price amiunt ">
                        {" "}
                        {userData?.referalCredit
                          ? parseFloat(userData?.referalCredit - UsedReferalPrice)
                          : 0}{" "}
                        USD
                      </div>
                    </div>
                    <div className="d-flex align-items-center flex-amt">
                      <input
                        type="checkbox"
                        onChange={(e) => ReferalCreditHanlder(e.target.checked)}
                        checked={UsedReferalPrice > 0}
                        disabled={!userData?.referalCredit}
                        className="me-2 input-set"
                      />
                      Do you want to use Referal Credit{" "}
                    </div>
                    {/* <div> {userData?.referalCredit ? parseFloat(userData?.referalCredit - UsedReferalPrice) : 0} USD</div> */}
                  </div>
                  <div className="text-info mt-3">
                    {UsedReferalPrice ? <>
                      <label>Final Price</label>
                      <p className="form-control input-radius">
                        {" "}
                        {Number(paymentForm?.payPrice) - UsedReferalPrice}
                      </p>
                    </> : null}
                  </div>
                </div>
                <div className="mb-3">
                  <label>Document</label>
                  <div>
                    {paymentForm.document ? (
                      <>
                        <div className="">
                          <a
                            href={`${environment.api}images/transactions/${paymentForm.document}`}
                            target="_new"
                          >
                            <i className="fa fa-file"></i>{" "}
                            {paymentForm.document}
                          </a>{" "}
                          <i
                            className="fa fa-times pointer"
                            onClick={(e) =>
                              setPaymentForm({ ...paymentForm, document: "" })
                            }
                          ></i>
                        </div>
                      </>
                    ) : (
                      <>
                        <label className="btn btn-primary">
                          <input
                            type="file"
                            className="d-none"
                            onChange={(e) => uploadDoc(e)}
                          />
                          Upload Doc
                        </label>
                      </>
                    )}
                  </div>
                  {/* {submitted && !paymentForm.document ? <>
                    <div className="text-danger text-small">Document is Required</div>
                  </> : <></>} */}
                </div>
              </div>
              <div className="modal-footer">
                <button
                  type="button"
                  className="btn btn-secondary"
                  data-bs-dismiss="modal"
                >
                  Close
                </button>
                <button type="submit" className="btn btn-primary">
                  Submit
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>

      <button
        type="button"
        className="btn btn-primary d-none"
        id="OpenRefermodal"
        data-bs-toggle="modal"
        data-bs-target="#exampleModal"
      >
        Launch demo modal
      </button>
      <div
        className="modal fade"
        id="exampleModal"
        tabindex="-1"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="exampleModalLabel">
                {" "}
                Referrals
              </h5>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <div className="modal-body referral_tables">
              <div className="table-responsive">
                <table className="table trans-tb  table-bordered table-hover">
                  <thead>
                    <tr>
                      <th scope="col">Name</th>
                      <th scope="col">Email</th>
                      <th scope="col">Amount</th>
                      <th scope="col">Status</th>
                    </tr>
                  </thead>
                  <tbody>
                    {refers &&
                      refers.map((itm, i) => (
                        <tr>
                          <th scope="row">{itm?.fullName}</th>
                          <td>{itm?.email}</td>
                          <td>{itm?.referalCredit || 0} USD</td>
                          <td>
                            <div
                              className={`${itm?.approvedReferalCredit === true
                                ? "badge badge-primary"
                                : "badge badge-danger"
                                }`}
                            >
                              {itm?.approvedReferalCredit === true
                                ? "Approved"
                                : "Pending"}
                            </div>
                          </td>
                        </tr>
                      ))}
                  </tbody>
                </table>
              </div>
            </div>
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-secondary"
                data-bs-dismiss="modal"
              >
                Close
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
