/*
 * @Author: Ghost
 * @Email: Ghost@yopmail.com
 * @Date: 2024-02-13 11:30:23
 * @Last Modified by: Someone
 * Email: anilgodara4239@gmail.com
 * @Last Modified time: 2024-04-12 13:01:51
 * @Description: file:///home/jc-21/Desktop/MyVirtualPal/marketplace-frontend/src/pages/Signup/index.js
 */
import { Formik } from "formik";
import { useEffect, useState } from "react";
import loader from "../../methods/loader";
import ApiClient from "../../methods/api/apiClient";
import { useHistory } from "react-router-dom";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import "./style.scss";
import environment from "../../environment";
import methodModel from "../../methods/methods";
export default function SignUp() {
  const [EmailError, setEmailError] = useState(false);
  const params = new URLSearchParams(window.location.search);
  const showrole = params.get("role");
  const ReferalEmail = params.get("email");
  const [email, setemail] = useState("");
  const navigate = useHistory();
  const Virtual = environment.VirtualId;
  const employeerid = environment.EmployerId;
  const recruiterid = environment.RecruiterId;
  const [role, setrole] = useState(employeerid);
  useEffect(() => {
    if (showrole == "Virtual Assistant" || showrole == "virtual") {
      // document.getElementById("profile-tab").click();
      setrole(Virtual);
    } else if (showrole == "recruiter") {
      // document.getElementById("home-tab").click();
      setrole(recruiterid);
    } else {
      // document.getElementById("home-tab").click();
      setrole(employeerid);
    }
  }, []);

  const [eyes, setEyes] = useState({
    password: false,
    confirmPassword: false,
    currentPassword: false,
  });
  const [form, setForm] = useState({ id: "" });
  const [DoumentUploadLoading, setDocumentUploadLoading] = useState(false);
  const [images, setimages] = useState([]);
  const [ProtofolioError, setProtofolioError] = useState(false);
  // const [DoumentUploadLoading, setDocumentUploadLoading] = useState(false);
  const [documents, setdocuments] = useState([]);

  //  For Deleting the Document
  const HanldDocumentDelete = (e, index) => {
    e.preventDefault();
    const array = [...images];
    array.splice(index, 1);
    setimages(array);
  };

  const imageResult = (e) => {
    if (e.target.files.length > 0) {
      const files = e.target.files;
      const newdata = new FormData();
      let newarray = images;
      let imgarrays = [];
      let i = 0;
      let original = [];
      for (let items of files) {
        imgarrays.push(items);
      }
      for (let item of imgarrays) {
        let file = files.item(i);
        setDocumentUploadLoading(true);
        ApiClient.multiImageUpload("single/documents?modelName=document", {
          data: file,
        }).then((res) => {
          if (res.success) {
            const path = res?.data?.imagePath;
            newarray.push(path);
            original.push(path);
            setdocuments(new Date());
            if (files.length == images.length) {
              setDocumentUploadLoading(false);
            }
          } else {
            setDocumentUploadLoading(false);
          }
        });

        i++;
      }
      setProtofolioError(false);
      setdocuments(newarray);
      setimages(newarray);
    } else {
      // toast.error({ message: "Please Upload the Documents" });
    }
  };

  const HandleCLickhere = (e) => {
    e.preventDefault();
    loader(true);
    ApiClient.post(`resend/verification`, { email: email }).then((response) => {
      if (response.success) {
        toast.success(response.message);
      }
      loader(false);
    });
  };

  return (
    <div className="bg_img">
      <div className="main_signup">
        <div className="tab_clas ml-auto">
          <ul
            className="nav d-none nav-tabs tabs mb-2 mt-4 justify-content-end mr-4 d-flex signup_tab"
            id="myTab"
            role="tablist"
          >
            <li className="nav-item  d-none" role="presentation">
              <button
                className="nav-link signuptab active "
                id="home-tab"
                data-bs-toggle="tab"
                data-bs-target="#home"
                type="button"
                role="tab"
                aria-controls="home"
                aria-selected="true"
                onClick={(e) => {
                  setrole(employeerid);
                  document.getElementById("resetForm").click();
                }}
              >
                Employer
              </button>
            </li>
            <li className="nav-item d-none " role="presentation">
              <button
                className={`nav-link signup-tab_cls`}
                id="profile-tab"
                data-bs-toggle="tab"
                data-bs-target="#profile"
                type="button"
                role="tab"
                aria-controls="profile"
                aria-selected="false"
                onClick={(e) => {
                  setrole(Virtual);
                  document.getElementById("resetForm").click();
                }}
              >
                Virtual Assistant
              </button>
            </li>
          </ul>
        </div>
        <div className="row align-items-center justify-content-center h-100">
          <div className="col-xl-4 col-lg-6 col-md-8">
            <div className="top_section top-sect-new">
              <div className="right_side">
                <Link to="/">
                  <div className="logo_image mb-3">
                    <img src="/assets/img/Logo_new.png" className="logo_name" />
                  </div>
                </Link>

                <Formik
                  enableReinitialize
                  initialValues={{
                    email: ReferalEmail ? ReferalEmail : "",
                    firstname: "",
                    lastname: "",
                    password: "",
                    role: "",
                    check: false,
                  }}
                  onSubmit={(values, { resetForm }) => {
                    const payload = {
                      email: values.email,
                      password: values.password,
                      firstName: values.firstname,
                      lastName: values.lastname,
                      fullName: `${values.firstname} ${values.lastname}`,
                      role: role,
                      document: images,
                    };

                    if (values.check != true) {
                      toast.error(
                        "Please accept the terms of use and privacy policy"
                      );
                      return;
                    }

                    loader(true);
                    ApiClient.post(`register`, payload).then((res) => {
                      if (res.message) {
                        setemail(payload.email);
                        document.getElementById("openSigupmodal").click();
                        resetForm();
                        // navigate.push("/login?message=" + res.message);
                      }
                      loader(false);
                    });
                  }}
                >
                  {({
                    values,
                    handleChange,
                    handleBlur,
                    handleSubmit,
                    resetForm,
                  }) => (
                    <form className="centerLogin" onSubmit={handleSubmit}>
                      <div className="text-center mb-2">
                        {showrole == "Virtual Assistant" ||
                          showrole == "virtual" ? (
                          <h3 className="text-center lgtext">
                            Create your Virtual Assistant Account.
                          </h3>
                        ) : showrole == "recruiter" ? (
                          <h3 className="text-center lgtext">
                            Create a Recruiter Account
                          </h3>
                        ) : (
                          <h3 className="text-center lgtext">
                            Create your Employer account
                          </h3>
                        )}
                        <p className="accopunt text-center">
                          Already have an account?{" "}
                          <Link className="sign_up" to="/login">
                            {" "}
                            Sign In
                          </Link>
                        </p>
                      </div>

                      {/* tab */}

                      <div className="tab-content mt-3" id="myTabContent">
                        <div
                          className="tab-pane fade show active"
                          id="home"
                          role="tabpanel"
                          aria-labelledby="home-tab"
                        >
                          <div className="">
                            <label className="form-label ml-2">
                              Email<span className="text-danger">*</span>
                            </label>
                            <input
                              type="email"
                              value={values.email}
                              required
                              name="email"
                              pattern="[^@\s]+@[^@\s]+\.[^@\s]+"
                              onChange={(e) => {
                                handleChange(e);
                                setEmailError(
                                  methodModel.handleerrorcahnge(e.target.value)
                                );
                              }}
                              onBlur={handleBlur}
                              placeholder="Email Address"
                              className="form-control bginput chnages"
                            />
                            {EmailError ? (
                              <p className="text-center text-danger">
                                Please enter a valid Email
                              </p>
                            ) : null}

                            <div className="row ">
                              <div className="col-md-6 mt-3">
                                <label className="form-label ml-2">
                                  First Name
                                  <span className="text-danger">*</span>
                                </label>
                                <input
                                  type="text"
                                  value={values.firstname}
                                  required
                                  name="firstname"
                                  placeholder="First Name"
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                                  className="form-control bginput chnages"
                                />
                              </div>
                              <div className="col-md-6 mt-3">
                                <label className="form-label ml-2">
                                  Last Name
                                  <span className="text-danger">*</span>
                                </label>
                                <input
                                  type="text"
                                  value={values.lastname}
                                  required
                                  name="lastname"
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                                  placeholder="Last Name"
                                  className="form-control bginput chnages"
                                />
                              </div>
                            </div>

                            <div className="col-md-12 p-0 mt-3 inputWrapper">
                              <label className="form-label ml-2">
                                Password<span className="text-danger">*</span>
                              </label>
                              <input
                                className="form-control mb-0 bginput chnages"
                                type={!eyes.password ? "password" : "text"}
                                required
                                value={values.password}
                                name="password"
                                minLength="8"
                                onChange={(e) => {
                                  handleChange(e);
                                }}
                                placeholder="Password"
                                onBlur={handleBlur}
                              />
                              <i
                                className={
                                  eyes.password
                                    ? "fa fa-eye eyeicon"
                                    : "fa fa-eye-slash slashicon"
                                }
                                onClick={() =>
                                  setEyes({ ...eyes, password: !eyes.password })
                                }
                              ></i>
                            </div>

                            <div className="mt-4 d-flex  checkbox_size">
                              <input
                                type="checkbox"
                                className="checkbox mr-0"
                                name="check"
                                onChange={handleChange}
                              />
                              <label className=" ml-2 mb-0 prilab">
                                By clicking create account, I agree that I have
                                read and accepted the{" "}
                                <a
                                  className="text-info"
                                  href={`https://${window.location.host}/content/term-conditions`}
                                  target="_blank"
                                >
                                  terms of use{" "}
                                </a>
                                and{" "}
                                <a
                                  className="text-info"
                                  href={`${role == environment.VirtualId
                                    ? `https://${window.location.host}/content/VA-privacy-policy`
                                    : `https://${window.location.host}/content/privacy-policy`
                                    } `}
                                  target="_blank"
                                >
                                  privacy policy.
                                </a>
                              </label>
                            </div>
                            <div>
                              <button
                                type="submit"
                                className="btn btn-primary loginclass mt-3"
                              >
                                Sign Up
                              </button>
                            </div>

                            {/* <div className="borderCls mt-3">
                              <span className="or">or</span>
                            </div> */}
                            {/* <div className="text-center d-flex justify-content-center mt-3 mb-3">
                              <a className="btn btn-outline google_id mr-3 ">
                                <img src="/assets/img/gogle.png" />
                                <span className="google_heading"></span>
                              </a>
                              <a className="btn btn-outline google_id">
                                <img src="/assets/img/facebooklogo.png" />
                                <span className="google_heading"></span>
                              </a>
                            </div> */}
                            {/* <div className="text-center mt-2"><p className="text-primary sign_users" onClick={e=>  document.getElementById("profile-tab").click()}>Sign up as a Virtual Assistant</p></div> */}
                          </div>
                        </div>
                        <div
                          className="tab-pane fade"
                          id="profile"
                          role="tabpanel"
                          aria-labelledby="profile-tab"
                        >
                          <div className="">
                            <label className="form-label ml-2">
                              Email<span className="text-danger">*</span>
                            </label>
                            <input
                              type="email"
                              value={values.email}
                              required
                              disabled={ReferalEmail ? true : false}
                              pattern="[^@\s]+@[^@\s]+\.[^@\s]+"
                              name="email"
                              onChange={(e) => {
                                handleChange(e);
                                setEmailError(
                                  methodModel.handleerrorcahnge(e.target.value)
                                );
                              }}
                              onBlur={handleBlur}
                              placeholder="Email Address"
                              className="form-control bginput chnages"
                            />
                            <div style={{ height: "10px" }}>
                              {EmailError ? (
                                <p
                                  className="text-center text-danger"
                                  style={{ fontSize: "10px" }}
                                >
                                  Please enter a valid Email
                                </p>
                              ) : null}
                            </div>
                            <div className="row mt-3">
                              <div className="col-md-6">
                                <label className="form-label ml-2">
                                  First Name
                                  <span className="text-danger">*</span>
                                </label>
                                <input
                                  type="text"
                                  value={values.firstname}
                                  required
                                  name="firstname"
                                  placeholder="First Name"
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                                  className="form-control bginput chnages"
                                />
                              </div>
                              <div className="col-md-6">
                                <label className="form-label ml-2">
                                  Last Name
                                  <span className="text-danger">*</span>
                                </label>
                                <input
                                  type="text"
                                  value={values.lastname}
                                  required
                                  name="lastname"
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                                  placeholder="Last Name"
                                  className="form-control bginput chnages"
                                />
                              </div>
                            </div>

                            <div className="col-md-12 p-0 mt-2 inputWrapper">
                              <label className="form-label ml-2">
                                Password<span className="text-danger">*</span>
                              </label>
                              <input
                                className="form-control mb-0 bginput chnages"
                                type={!eyes.password ? "password" : "text"}
                                required
                                value={values.password}
                                name="password"
                                minLength="8"
                                onChange={handleChange}
                                placeholder="Password"
                                onBlur={handleBlur}
                              />
                              <i
                                className={
                                  eyes.password
                                    ? "fa fa-eye eyeicon"
                                    : "fa fa-eye-slash slashicon"
                                }
                                onClick={() =>
                                  setEyes({ ...eyes, password: !eyes.password })
                                }
                              ></i>
                            </div>

                            <div></div>
                            {ProtofolioError ? (
                              <div className="text-danger text-center mt-3 ml-5">
                                Please enter Url or Upload Documents{" "}
                              </div>
                            ) : null}
                            {/* </>
                      ) : null} */}
                            <div className="mt-4 d-flex  ">
                              <input
                                type="checkbox"
                                className="checkbox"
                                name="check"
                                onChange={handleChange}
                              />
                              <label className=" ml-2 mb-0">
                                By clicking create account, I agree that I have
                                read and accepted the{" "}
                                <a
                                  className="text-info"
                                  href="https://virtualpal.jcsoftwaresolution.in/content/term-conditions"
                                  target="_blank"
                                >
                                  terms of use{" "}
                                </a>
                                and{" "}
                                <a
                                  className="text-info"
                                  href={`${role == environment.VirtualId
                                    ? "https://virtualpal.jcsoftwaresolution.in/content/VA-privacy-policy"
                                    : "https://virtualpal.jcsoftwaresolution.in/content/privacy-policy"
                                    } `}
                                  target="_blank"
                                >
                                  privacy policy.
                                </a>
                              </label>
                            </div>
                            <div>
                              <button
                                type="submit"
                                className="btn btn-primary loginclass mt-3"
                              >
                                Sign Up
                              </button>
                            </div>

                            {/* <div className="borderCls mt-3">
                              <span className="or">or</span>
                            </div>
                            <div className="text-center d-flex justify-content-center mt-3 mb-3">
                              <a className="btn btn-outline google_id mr-3 ">
                                <img src="/assets/img/gogle.png" />
                                <span className="google_heading"></span>
                              </a>
                              <a className="btn btn-outline google_id">
                                <img src="/assets/img/facebooklogo.png" />
                                <span className="google_heading"></span>
                              </a>
                            </div> */}
                            {/* <div className="text-center mt-2"><p className="text-primary sign_users" onClick={e=>document.getElementById("home-tab").click()}>Sign up as a Employer</p></div> */}
                          </div>
                        </div>
                      </div>

                      {/* end tab */}
                    </form>
                  )}
                </Formik>
              </div>
            </div>
          </div>
        </div>
      </div>
      <button
        data-toggle="modal"
        className="d-none"
        data-target="#exampleModal"
        id="openSigupmodal"
      ></button>
      <div
        className="modal fade"
        id="exampleModal"
        tabIndex="-1"
        role="dialog"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog" role="document">
          <div className="modal-content">
            <div className="modal-body">
              <div className="text-center p-3">
                <img
                  src="assets/img/success.png"
                  alt="success"
                  className="iconSignup"
                />
                <div className="modal-body pb-3">
                  <h2 className="glad">Glad you'll be joining us!</h2>
                  <h2 className="glad mb-4">You're almost there,</h2>
                  <h4 className="glads ">
                    <b>Next Step:</b> Verify Your Email. We’ve sent you an
                    email. Click the link in the email to continue setting up
                    your account.
                  </h4>
                </div>
                <button
                  type="button"
                  className="btn btn-primary pl-5 pr-5 mb-3"
                  data-toggle="modal"
                  data-target="#exampleModal"
                  onClick={(e) => navigate.push("/home")}
                >
                  OK
                </button>
                <p className="mt-2">
                  Didn’t receive an email?{" "}
                  <a
                    className="text-primary"
                    onClick={(e) => HandleCLickhere(e)}
                  >
                    Click Here.
                  </a>
                </p>
                <p className="mt-2 mb-0">
                  Be sure to check your spam and promotions folders if you don’t
                  see your verification email shortly.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
