import { useEffect, useState } from "react";
import ApiClient from "../../methods/api/apiClient";
import methodModel from "../../methods/methods";
import TrackingTable from "./TrackingData/TrackingTable"
import loader from "../../methods/loader";
import moment from "moment";
import Header from "../../components/global/header";
import environment from "../../environment";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";

const TimeClockRequests = () => {
    const [TabSelected, setTabSelected] = useState(methodModel.getPrams('tab') ? 'payment' : 'tracking')
    const [FreelancerDetails, setFreelancerDetails] = useState({});
    const [EmployerDetails, setEmployerDetails] = useState({});
    const [ProjectDetails, setProjectDetails] = useState({});
    const user = useSelector(state => state.user);
    const [BillingData, setBillingData] = useState([]);
    const { startDate, endDate } = methodModel.getCurrentWeekDates();
    const today = endDate; // Get today's date
    const tomorrow = startDate; // Get tomorrow's date
    const [BillingFilters, setBillingFilters] = useState({
        addedBy: "",
        contractId: "",
        start: moment(tomorrow).format("MM-DD-YYYY"),
        end: moment(today).format("MM-DD-YYYY"),

    });

    const GetDetails = () => {
        loader(true);
        ApiClient.get(`contract`, { id: id || "" }).then((res) => {
            const data = res.data;
            setFreelancerDetails(data?.contractTo);
            setProjectDetails(data?.projectId);
            setEmployerDetails(data?.contractFrom);
            loader(false);
        });
    };

    const GetBillingData = (p = {}) => {
        loader(true);
        const newfilters = { ...BillingFilters, ...p };
        ApiClient.get(`${environment.VirtualId == user?.role?.id ? 'billing-list/virtual' : 'billing/list'}`, newfilters).then((res) => {
            if (res.success) {
                setBillingData(res.data);
            }
            loader(false);
        });
    };

    useEffect(() => {
        GetBillingData()
    }, [])



    //   OTHRE DATA
    const [data, setdata] = useState([])
    const navigate = useHistory()

    const GetAllBillings = () => {
        loader(true)
        ApiClient.get(`twoWeek/billings?contractId=&billingStatus=submitted&employerId=${user?._id || user?.id}`).then((res) => {
            if (res.success) {
                loader(false)
                setdata(res?.data)
            }
        })
    }

    useEffect(() => {
        GetAllBillings()
    }, [])

    const AproveBillingHours = (e, item) => {
        e.preventDefault();
        let fltr = item?.billingData?.filter((itm) => itm?.employerReview != "approved")
        const payload = fltr.map((itm) => itm?._id);
        console.log(payload, "8888888")
        if (payload?.length === 0) {
            return toast.error("Hours already Approved")
        }
        // return
        loader(true)
        ApiClient.put(`employer/billing-review`, { approvedBillingIds: payload })
            .then((res) => {
                if (res.success) {
                    loader(false)
                    toast.success(res.message);
                    navigate.push("/work-diary");
                } else {
                    console.log('Error');
                }
            })
    };

    const Getcalculculateminute = (res) => {
        let hours = parseInt(res?.totalDurationOfTime_hour1);
        let minutes = parseInt(res?.totalDurationOfTime_min1);
        let remainder = minutes / 60;
        let quotient = minutes % 60;
        hours += parseInt(remainder);
        minutes = quotient;
        return `${hours || "00"} : ${minutes < 10 ? "0" + minutes : minutes || "00"}`;
    };
    return (
        <>
            <Header />
            <div className="container">
                <div className="ms-2 tabs-btn tabs_changes   " style={{ float: 'right' }}>
                    <button
                        className={`btn btn-${TabSelected == "tracking" ? "primary" : "secondary"
                            }`}
                        onClick={(e) => setTabSelected('tracking')}
                    >
                        Time Request
                    </button>{" "}
                    <button
                        className={`btn btn-${TabSelected == "payment" ? "primary" : "secondary"
                            }`}
                        onClick={(e) => setTabSelected("payment")}
                    >
                        Payments
                    </button>

                </div>
                {TabSelected == 'tracking' ? <TrackingTable
                    id={""}
                    VAData={FreelancerDetails}
                    EmployerData={EmployerDetails}
                    contractData={ProjectDetails}
                    data={BillingData}
                    ConfirmFunction={GetDetails}
                /> : <div className='container mt-5 '>
                    <div className='flow_flex'>
                        <h3 className='mb-md-4'> Billings</h3>

                        {/* {/ <div> <button className='btn btn-primary' style={{ float: "right" }} onClick={e => DownloadEmployerReport(e)} >Export</button></div> /} */}
                    </div>

                    <div className="mt-4 main-table table-bordered pb-4">

                        <div className='cmc_table reports_responsive '>

                            <div className='row cms firstcms table-text'>
                                <div className='col-2 border-color'>
                                    <label>Contract Name</label>
                                </div>
                                <div className='col-2 border-color'>
                                    <label>VA Name</label>
                                </div>
                                <div className='col-1 border-color'>
                                    <label>VA Rate</label>
                                </div>
                                <div className='col-2 border-color'>
                                    <label>Time Period</label>
                                </div>
                                <div className='col-1 border-color'>
                                    <label>Total Hours</label>
                                </div>
                                <div className='col-2 border-color'>
                                    <label>Total Amount</label>
                                </div>
                                <div className='col-2 border-color'>
                                    <label>Status</label>
                                </div>

                            </div>

                            {data.map((item, index) => (
                                <div className='row cms'>
                                    <div className='col-2'>
                                        <p className='pms gms'  >{item?.contractDetail?.contractName || item?.billingData[0]?.projectDetail?.title || "---"}</p>
                                    </div>
                                    <div className='col-2'>
                                        <p className='pms gms' >{item?.billingData[0]?.addedByDetail?.fullName || "---"} </p>
                                    </div>

                                    <div className='col-1'>
                                        <p className='pms gms'>{item?.billingData[0]?.contractDetail?.hourlyRate}{" "}USD </p>
                                    </div>
                                    <div className='col-2'>
                                        <p className='pms gms'>{item?.startDate ? <>{moment(item?.startDate).format("DD MMM")} - {moment(item?.endDate).format("DD, YYYY")}</> : "---"}</p>
                                    </div>
                                    <div className='col-1'>
                                        <p className='pms gms' >{Getcalculculateminute(item)}</p>
                                    </div> <div className='col-2'>
                                        <p className='pms gms' >{parseFloat(item?.totalBillingAmount).toFixed(2) || "00"}{" "}USD</p>
                                    </div> 
                                    <div className='col-2  '>
                                        <p className='pms gms' >{item?.employerReview === "approved" ? <button disabled title="Approved" className="btn btn-primary">Approved</button> : <button className="btn btn-primary" onClick={e => AproveBillingHours(e, item)}>Approve</button>}</p>
                                    </div>
                                </div>
                            ))}

                        </div>
                        {data.length == 0 ? <div className='text-danger text-center'>No Billing Data</div> : null}

                    </div>
                </div>}
            </div>
        </>
    )
}

export default TimeClockRequests